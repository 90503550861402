import { OcppAuthorizationV1, OcppAuthorizationV2, OcppStatusNotificationV1, OcppStatusNotificationV2 } from "../data-backend/models";

// takes a string and decodes previously encoded parts of it
export const decodeString = (input: string): string => {
    const decodeMap = {
        'amp': '&',
        'lt': '<',
        'gt': '>',
        'quot': '"',
        '#x27': '\'',
        'apos': '\'',
        '#39': '\'',
        '#x2F': '/',
        '#47': '/',
        'grave': '`',
        'nbsp': ' '
    };
    const regex = /&([^;]+);/gm
    return input.replace(regex, (match, entity) => {
        return decodeMap[entity as keyof typeof decodeMap] || match
    })
}

// checks whether a mail string is a valid mail address
export const mailIsValid = (mail: string): boolean => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(mail);
}

// check out https://byby.dev/js-slugify-string
export const slugifyString = (input: string): string => {
    return String(input)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '_')
        .replace(/-+/g, '_');
}

/**
 * The `compareArrays` function checks if two arrays have the same elements, regardless of their order
 * or frequency.
 * @param {any[]} a - The parameter `a` is an array of any type.
 * @param {any[]} b - The parameter `b` is an array of any type.
 * @returns a boolean value. It returns true if the two arrays have the same elements with the same
 * frequencies, and false otherwise.
 */
export const compareArrays = (a: any[], b: any[]) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
    }
    return true;
}

/**
 * Takes a number of seconds as input and returns a formatted string representing the equivalent time 
 * in days, hours, minutes, and seconds.
 * @param {number} s - Seconds
 * @returns The `formatSeconds` function returns a formatted string representing the input number of
 * seconds in the format "d hh:mm:ss" where:
 * - "d" represents days if the input is greater than 0
 * - "hh" represents hours
 * - "mm" represents minutes
 * - "ss" represents seconds
 */
export const formatSeconds = (s: number): string => {
    const prefix = s < 0 ? '- ' : '';
    s = Math.abs(s);

    let seconds = Math.floor(s % 60),
        minutes = Math.floor((s / 60) % 60),
        hours = Math.floor((s / 60 / 60) % 24),
        days = Math.floor(s / 60 / 60 / 24),
        daysFormatted = days > 0 ? days + 'd ' : '',
        hoursFormatted = hours < 10 ? "0" + hours : hours,
        minutesFormatted = minutes < 10 ? "0" + minutes : minutes,
        secondsFormatted = seconds < 10 ? "0" + seconds : seconds;

    return `${prefix}${daysFormatted}${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
}

/**
 * Handles the creation of a query string for filters
 * The implementation differs a bit from the specs, thus we're handling many any types
 * @param activeFilters Array of active filters
 * @returns Array of objects with key-value pairs of active filters
 */
export const filterQueryBuilder = (activeFilters: any[]) => {
    const replaceComma = (input: string) => input.replace(/,/g, 'COMMA');
    const sanitize = (input: string | number | any[]) => {
        if (input instanceof Array) {
            return input.map((val) => typeof val === 'string' ? replaceComma(val) : val)
        } else if (typeof input === 'string') {
            return replaceComma(input)
        }

        return input
    }
    return activeFilters.reduce((acc, filter: any) => {
        if (filter.value !== undefined && filter.value !== null && filter.value.length > 0) {
            let filterObj: any = {};
            let sanitizedFilterValue = sanitize(filter.value);
            filterObj[filter.id] = sanitizedFilterValue;
            acc.push(filterObj);
        }
        return acc;
    }, []);
}

export function isOcppAuthV1(auth: unknown): auth is OcppAuthorizationV1 {
    return typeof auth === 'object' && (auth as OcppAuthorizationV1).idTag !== undefined
}

export function isOcppAuthV2(auth: unknown): auth is OcppAuthorizationV2 {
    return typeof auth === 'object' && (auth as OcppAuthorizationV2).idToken !== undefined
}

export function isOcppStatNotiV1(notification: unknown): notification is OcppStatusNotificationV1 {
    return typeof notification === 'object' && (notification as OcppStatusNotificationV1).status !== undefined
}

export function isOcppStatNotiV2(notification: unknown): notification is OcppStatusNotificationV2 {
    return typeof notification === 'object' && (notification as OcppStatusNotificationV2).connectorStatus !== undefined
}
