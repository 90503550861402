import { trigger, transition, style, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, effect, ElementRef, input, model, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, map, Observable } from 'rxjs';
import { StationRestartService, StationWithSchedules } from 'src/app/core/app-services/station-restart.service';
import { ChargingStation } from 'src/app/core/data-backend/models';

@Component({
    selector: 'app-restart-popup',
    template: `
        @if (vm$ | async; as vm) {
            @if (vm.open && vm.station) {
                <div 
                    class="restart-popup"
                    [class.text-muted]="!!vm.station.restartDisabledAt"
                    [@fadeInAnimation]
                    #restartPopup
                >
                    <div class="restart-title">{{ 'DETAILS_VIEW.RESTARTS.MANUAL_RESTARTS' | translate }}</div>
                    <div class="restart-body" [innerHTML]="'DETAILS_VIEW.RESTARTS.MANUAL_RESTARTS_INFO' | translate"></div>
                    <div class="flex-row no-wrap">
                        <button
                            evc-button
                            class="mr-16"
                            icon="sync"
                            [iconSpinOnLoading]="true"
                            [loading]="vm.restarting"
                            [disabled]="vm.loading || vm.restarting || !!vm.station.restartDisabledAt"
                            [tooltip]="'DETAILS_VIEW.RESTARTS.RESTART_NOW_TOOLTIP' | translate"
                            size="small"
                            (click)="restartStation(vm.station.stationId, false)"
                        >{{ 'DETAILS_VIEW.RESTARTS.RESTART_NOW' | translate }}</button>
                        <button
                            evc-button
                            icon="hourglass_bottom"
                            [loading]="vm.loading"
                            [color]="vm.station.onIdleActive ? 'primary' : 'secondary'"
                            [disabled]="vm.loading || vm.restarting || !!vm.station.restartDisabledAt"
                            [tooltip]="'DETAILS_VIEW.RESTARTS.RESTART_ON_IDLE_TOOLTIP' | translate"
                            size="small"
                            (click)="restartStation(vm.station.stationId, true, vm.station.onIdleActive)"
                        >{{ 'DETAILS_VIEW.RESTARTS.RESTART_ON_IDLE' | translate }}</button>
                    </div>

                    <br>
                    <div class="restart-title">
                        {{ 'DETAILS_VIEW.RESTARTS.SCHEDULED_RESTARTS' | translate }}
                        @if (vm.station.restartSchedule == 'Weekly restart') {
                            ({{ 'DETAILS_VIEW.RESTARTS.ENABLED_WEEKLY' | translate }})
                        } @else if (vm.station.restartSchedule == 'Daily restart') {
                            ({{ 'DETAILS_VIEW.RESTARTS.ENABLED_DAILY' | translate }})
                        }
                    </div>
                    <div class="restart-body" [innerHTML]="'DETAILS_VIEW.RESTARTS.SCHEDULED_RESTARTS_INFO' | translate"></div>
                    <div class="flex-row no-wrap">
                        <button
                            evc-button
                            class="mr-16"
                            icon="history"
                            [loading]="vm.loading"
                            [color]="vm.loading || vm.station.restartSchedule === 'Daily restart' ? 'primary' : 'secondary'"
                            [disabled]="!!vm.station.restartDisabledAt"
                            (click)="handleSchedule(vm.station.stationId, vm.station.restartSchedule, 'Daily restart')"
                        >{{ 'DETAILS_VIEW.RESTARTS.DAILY_RESTART' | translate }}</button>
                        <button
                            evc-button
                            icon="event_repeat"
                            [loading]="vm.loading"
                            [color]="vm.station.restartSchedule === 'Weekly restart' ? 'primary' : 'secondary'"
                            [disabled]="vm.loading || !!vm.station.restartDisabledAt"
                            (click)="handleSchedule(vm.station.stationId, vm.station.restartSchedule, 'Weekly restart')"
                        >{{ 'DETAILS_VIEW.RESTARTS.WEEKLY_RESTART' | translate }}</button>
                    </div>
                </div>
            }
        }
    `,
    styleUrl: './restart-popup.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(-10px)', opacity: 0 }),
                animate('.25s ease-out', 
                    style({ transform: 'translateY(0px)', opacity: 1 })
                )
            ]),
            transition(':leave', [
                style({ transform: 'translateY(0px)', opacity: 1 }),
                animate('.25s ease-in', 
                    style({ transform: 'translateY(-10px)', opacity: 0 })
                )
            ])
        ])
    ]
})
export class RestartPopupComponent implements OnDestroy {
    // controls popup visibility
    public open = model<boolean>();
    // align with parent
    public alignRef = input<HTMLElement>();
    // element ref to restart popup
    @ViewChild('restartPopup') restartPopup!: ElementRef;
    // helper for UI
    private _unlistenEvents: (() => void)[] = [];

    public vm$: Observable<{
        open: boolean,
        station: StationWithSchedules | null,
        restarting: boolean,
        loading: boolean
    }>;

    constructor(
        private _elRef: ElementRef,
        private _renderer: Renderer2,
        private _restartService: StationRestartService
    ) {
        // handle listeners
        effect(() => {
            const isOpen = this.open();

            if (isOpen) {
                this._setListeners();
                this._restartService.refetch();
                // update positioning
                const element = this.alignRef();
                if (!element) return;
                const boundingBox = element.getBoundingClientRect();
                const offsetRight = window.innerWidth - (boundingBox.x + boundingBox.width);
                this._renderer.setStyle(this._elRef.nativeElement, 'right', offsetRight + 'px');
            } else {
                this._removeListeners();
            }
        });

        this.vm$ = combineLatest({
            open: toObservable(this.open).pipe(map((v) => !!v)),
            station: this._restartService.stationWithSchedules$,
            restarting: this._restartService.isRestarting$,
            loading: this._restartService.isLoading$
        })
    }

    public restartStation(stationId: string, onIdle: boolean, onIdleActive?: boolean) {
        const callback = () => this._resetPopup();
        if (onIdle && onIdleActive) {
            this._restartService.deleteOnIdleRestart(stationId, callback, callback);
        } else {
            this._restartService.restartStation(stationId, onIdle, callback, callback);
        }
    }

    public handleSchedule(stationId: string, activeSchedule: ChargingStation['restartSchedule'], newSchedule: ChargingStation['restartSchedule']) {
        const callback = () => this._resetPopup();
        this._restartService.updateSchedule(stationId, activeSchedule, newSchedule, callback, callback);
    }

    // reset loading state and close popup
    private _resetPopup() {
        this.open.set(false);
    }

    private _setListeners() {
        if (this._unlistenEvents.length > 0) return;
        this._unlistenEvents.push(
            this._renderer.listen('document', 'mousedown', (event) => {
                if (
                    !this.restartPopup.nativeElement.contains(event.target) &&
                    !event.target.classList.contains('subnav-restart')
                ) {
                    this.open.set(false);
                };
            }),
            this._renderer.listen('document', 'keydown.esc', () => this.open.set(false)),
            this._renderer.listen('document', 'keydown.esc', () => this.open.set(false)),
        )
    }

    private _removeListeners() {
        this._unlistenEvents.forEach((fn) => fn());
        this._unlistenEvents = [];
    }

    ngOnDestroy(): void {
        this._removeListeners();
    }
}
