/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthBaseService } from '../auth-base-service';
import { AuthApiConfiguration } from '../auth-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Customer } from '../models/customer';
import { getAvailableCustomers } from '../fn/customer/get-available-customers';
import { GetAvailableCustomers$Params } from '../fn/customer/get-available-customers';


/**
 * Customer authorization
 */
@Injectable({ providedIn: 'root' })
export class CustomerService extends AuthBaseService {
  constructor(config: AuthApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAvailableCustomers()` */
  static readonly GetAvailableCustomersPath = '/customers/';

  /**
   * Get a list of available customers (identifiers) for the current user.
   *
   * /TODO fill me
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableCustomers$Response(params?: GetAvailableCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Customer>>> {
    return getAvailableCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a list of available customers (identifiers) for the current user.
   *
   * /TODO fill me
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableCustomers(params?: GetAvailableCustomers$Params, context?: HttpContext): Observable<Array<Customer>> {
    return this.getAvailableCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>): Array<Customer> => r.body)
    );
  }

}
