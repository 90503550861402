/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { IncidentCountTotal } from '../../models/incident-count-total';
import { ReasonCount } from '../../models/reason-count';
import { SeverityCount } from '../../models/severity-count';

export interface GetMetaData$Params {
}

export function getMetaData(http: HttpClient, rootUrl: string, params?: GetMetaData$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'listSeverityCount'?: Array<SeverityCount>;
'listReasonCount'?: Array<ReasonCount>;
'incidentCountTotal'?: IncidentCountTotal;
}>> {
  const rb = new RequestBuilder(rootUrl, getMetaData.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'listSeverityCount'?: Array<SeverityCount>;
      'listReasonCount'?: Array<ReasonCount>;
      'incidentCountTotal'?: IncidentCountTotal;
      }>;
    })
  );
}

getMetaData.PATH = '/incidents/getMetaData';
