import { Component, computed, inject, input, signal, Signal, TemplateRef, viewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { addDays, differenceInDays } from 'date-fns';
import { catchError, combineLatest, EMPTY, forkJoin, lastValueFrom, map, mergeMap, Observable, of, switchMap, take } from 'rxjs';
import { ExportService, NotificationService } from 'src/app/core/app-services';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { StationRestartService } from 'src/app/core/app-services/station-restart.service';
import { StationService } from 'src/app/core/data-backend/data-services';
import { ChargingStation, Connector } from 'src/app/core/data-backend/models';
import { ExtendedChargingStation } from 'src/app/core/helpers/transform-stations.helper';
import { filterQueryBuilder } from 'src/app/core/helpers/utils.helper';
import { overviewRepository } from 'src/app/core/stores/overview.repository';
import { ExtendedError } from 'src/app/details/error-history/error-history.component';
import { BulkSelectAction } from 'src/app/shared/table-bulk-panel/table-bulk-panel.component';

const LIMITS = {
    SELECT_ALL: 1000,
    CONFIGURE_RESTARTS: 100,
    RESTARTS: 30,
    HIDE: 100
} as const;

@Component({
    selector: 'evc-overview-bulk-actions',
    template: `
        <evc-table-bulk-panel
            *evcHasPermissions="'dashboard.table.bulkActions'"
            [selectedTextTemplate]="bulkSelectTextTemplate"
            [options]="bulkSelectActions()"
            [selectedIds]="overviewRepo.allbulkActionRowIDs$ | async"
            (selectedIdsChange)="closeBulkActions()"
            (onAction)="handleBulkAction($event)"
        />

        <ng-template #hideStationsTemplate>
            <div class="hide-template">
                <form 
                    name="hideChargersForm"
                    [formGroup]="hideChargersForm"
                    (submit)="toggleStationsVisibility()"
                >
                    @if (overviewRepo.showOnlyHidden$ | async; as showOnlyHidden) {
                        <h3>{{ 'DASHBOARD.BULK_ACTIONS.SHOW.TITLE' | translate }}</h3>
                        <p>{{ 'DASHBOARD.BULK_ACTIONS.SHOW.TEXT' | translate }}</p> <br>
                    } @else {
                        <h3>{{ 'DASHBOARD.BULK_ACTIONS.HIDE.DIALOG.TITLE' | translate }}</h3>
                        <div class="text">
                            <span>{{ 'DASHBOARD.BULK_ACTIONS.HIDE.DIALOG.BEFORE_INPUT' | translate }}</span>
                            <input 
                                min="1"
                                type="number" 
                                formControlName="duration"
                            >
                            <span>{{ 'DASHBOARD.BULK_ACTIONS.HIDE.DIALOG.AFTER_INPUT' | translate }}</span>
                        </div>
                        <evc-date-input
                            [size]="'small'"
                            [showTime]="false"
                            [min]="tomorrow"
                            [value]="hideChargersForm.get('untilDate')?.value ?? null"
                            (valueChange)="hideChargersForm.patchValue({untilDate: $event})"
                        />
                    }
                    <button type="submit">{{ 'DASHBOARD.BULK_ACTIONS.HIDE.DIALOG.BUTTON' | translate }}</button>
                </form>
            </div>
        </ng-template>

        <ng-template #restartStationsTemplate let-selectedIds>
            <div class="restarts-template">
                @if (restartStates$ | async; as vm) {
                    @if (overviewRepo.allBulkActionRestartDisabledIds$ | async; as restartDisabledIds) {
                        @if (restartDisabledIds?.length) {
                            <div class="restart-title">{{ 'DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.DISABLED_RESTARTS_TITLE' | translate }}</div>
                            <div class="restart-body">{{ 'DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.DISABLED_RESTARTS_INFO' | translate: {n: restartDisabledIds.length, m: selectedIds.length} }}</div>
                        }
                        <div class="restart-title">{{ 'DETAILS_VIEW.RESTARTS.MANUAL_RESTARTS' | translate }}</div>
                        <div class="restart-body" [innerHTML]="'DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.MANUAL_RESTARTS_INFO' | translate: {n: selectedIds.length, stationVar: bulkActionStationTitle()}"></div>
                        <div class="button-row">
                            <button
                                evc-button
                                class="mr-16"
                                icon="sync"
                                [iconSpinOnLoading]="true"
                                [loading]="vm.restarting"
                                [disabled]="vm.loading || selectedIds.length === restartDisabledIds.length"
                                [tooltip]="'DETAILS_VIEW.RESTARTS.RESTART_NOW_TOOLTIP' | translate"
                                size="small"
                                (click)="restartStations(selectedIds, false)"
                            >{{ 'DETAILS_VIEW.RESTARTS.RESTART_NOW' | translate }}</button>
                            <button
                                evc-button
                                icon="hourglass_bottom"
                                [loading]="vm.loading"
                                [disabled]="vm.loading || selectedIds.length === restartDisabledIds.length"
                                [tooltip]="'DETAILS_VIEW.RESTARTS.RESTART_ON_IDLE_TOOLTIP' | translate"
                                size="small"
                                (click)="restartStations(selectedIds, true)"
                            >{{ 'DETAILS_VIEW.RESTARTS.RESTART_ON_IDLE' | translate }}</button>
                        </div>

                        <br>
                        <div class="restart-title">{{ 'DETAILS_VIEW.RESTARTS.SCHEDULED_RESTARTS' | translate }}</div>
                        <div class="restart-body" [innerHTML]="'DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.SCHEDULED_RESTARTS_INFO' | translate: {n: selectedIds.length, stationVar: bulkActionStationTitle()}"></div>
                        <div class="button-row">
                            <button
                                evc-button 
                                class="mr-16"
                                icon="history"
                                [loading]="vm.loading"
                                [disabled]="vm.loading || selectedIds.length === restartDisabledIds.length"
                                (click)="updateStationRestartSchedule(selectedIds, 'Daily restart')"
                            >{{ 'DETAILS_VIEW.RESTARTS.DAILY_RESTART' | translate }}</button>
                            <button
                                evc-button
                                icon="event_repeat"
                                [loading]="vm.loading"
                                [disabled]="vm.loading || selectedIds.length === restartDisabledIds.length"
                                (click)="updateStationRestartSchedule(selectedIds, 'Weekly restart')"
                            >{{ 'DETAILS_VIEW.RESTARTS.WEEKLY_RESTART' | translate }}</button>
                        </div>
                    }
                }
            </div>
        </ng-template>

        <ng-template #disableRestartsTemplate let-selectedIds>
            @if (overviewRepo.allBulkActionRestartDisabledIds$ | async; as restartDisabledIds) {
                <evc-disable-restarts
                    [selectedIds]="selectedIds"
                    [restartDisabledIds]="restartDisabledIds ?? []"
                    [loading]="toggleRestartRequestRunning()"
                    (submit)="disableRestarts(selectedIds, $event.reason)"
                ></evc-disable-restarts>
            }
        </ng-template>

        <ng-template #enableRestartsTemplate let-selectedIds>
            @if (overviewRepo.allBulkActionRestartDisabledIds$ | async; as restartDisabledIds) {
                <evc-enable-restarts
                    [selectedIds]="selectedIds"
                    [restartDisabledIds]="restartDisabledIds ?? []"
                    [loading]="toggleRestartRequestRunning()"
                    (submit)="enableRestarts(selectedIds)"
                ></evc-enable-restarts>
            }
        </ng-template>
  `,
    styleUrl: './overview-bulk-actions.component.scss'
})
export class OverviewBulkActionsComponent {
    // repositories
    public overviewRepo = inject(overviewRepository);
    // data retrieval services
    private _stationService = inject(StationService);
    // app services
    private _notificationService = inject(NotificationService);
    private _exportService = inject(ExportService);
    private _stationRestartService = inject(StationRestartService);
    private _translate = inject(TranslateService);
    private _permService = inject(PermissionsService);
    private _router = inject(Router);

    // get stations from parent
    public stations = input<ExtendedChargingStation[] | null>();

    // TemplateRef for reset bulk action
    public hideStationsTemplate = viewChild<TemplateRef<any>>('hideStationsTemplate');
    public tomorrow = addDays(new Date(), 1);
    // TemplateRef for restart bulk action
    public restartStationsTemplate = viewChild<TemplateRef<any>>('restartStationsTemplate');
    /** TemplateRef for disabling restarts bulk action */
    public disableRestartsTemplate = viewChild<TemplateRef<any>>('disableRestartsTemplate');
    /** TemplateRef for disabling restarts bulk action */
    public enableRestartsTemplate = viewChild<TemplateRef<any>>('enableRestartsTemplate');
    // form to handle and sync inputs
    public hideChargersForm = new FormGroup({
        duration: new FormControl<number>(7),
        untilDate: new FormControl<Date>(addDays(new Date(), 7))
    });

    public bulkSelectActions: Signal<BulkSelectAction[]>;
    public bulkSelectTextTemplate = (selectedIds: (string | number)[]) => {
        const text = selectedIds.length == 1 
            ? this._t('DASHBOARD.BULK_ACTIONS.STATION_SELECTED.ONE', {n: selectedIds.length})
            : this._t('DASHBOARD.BULK_ACTIONS.STATION_SELECTED.OTHER', {n: selectedIds.length});
        return text
    };
    // UI States for restart bulk action
    public restartStates$ = combineLatest({
        loading: this._stationRestartService.isLoading$,
        restarting: this._stationRestartService.isRestarting$
    });
    // helper for singular/plural translations
    public bulkActionStationTitle: Signal<string>;
    /** All stationIds from current request */
    private _availableStationLocations = toSignal(this.overviewRepo.stationLocations$.pipe(
        map((res) => res.data.stationLocations)
    ));

    public readonly toggleRestartRequestRunning = signal(false);
    private readonly _restartDisabledIds = toSignal(this.overviewRepo.allBulkActionRestartDisabledIds$, { initialValue: [] });

    constructor() {
        // update bulk select actions with new language
        const newLang = toSignal(this._translate.onLangChange);
        /** Tracks all currently selected station objects */
        const allBulkActionChargingStations = toSignal(this.overviewRepo.bulkActionChargingStations$);
        /** Tracks all currently selected StationLocations */
        const allBulkActionStationLocations = toSignal(this.overviewRepo.bulkActionStationLocations$);

        this.bulkSelectActions = computed(() => {
            const lang = newLang();
            const hideStationsTemplate = this.hideStationsTemplate();
            const restartStationsTemplate = this.restartStationsTemplate();
            const disableRestartsTemplate = this.disableRestartsTemplate();
            const enableRestartsTemplate = this.enableRestartsTemplate();
            const restartDisabledIds = this._restartDisabledIds();

            /** full station object of selected rows */
            const selectedStationObjects = allBulkActionChargingStations();
            /** selected StationLocations (only access to stationID, evseIds, lng, lat, overallStates) */
            const selectedStationLocations = allBulkActionStationLocations();
            /** 
             * true if at least one of the selected stations connectors has value in the lastError property,
             * or any of the selected stationIds were selected through the stationLocations cache (no access to connectors, we assume that there is enough data)
             */
            const stationWithLastErrorSelected = selectedStationLocations && selectedStationLocations.length > 0 || selectedStationObjects && selectedStationObjects.some(
                (station: any) => station.connectors && station.connectors.some((connector: any) => connector && connector.lastError)
            );

            if (!hideStationsTemplate) return [];
            return [
                {
                    id: 'select-more',
                    title: this._t('DASHBOARD.BULK_ACTIONS.SELECT_MORE.TITLE'),
                    icon: 'add',
                    actions: [
                        {
                            title: this._t('DASHBOARD.BULK_ACTIONS.SELECT_MORE.ON_CURRENT_PAGE'),
                            id: 'select-current-page'
                        },
                        {
                            title: this._t('DASHBOARD.BULK_ACTIONS.SELECT_MORE.ON_ALL_PAGES'),
                            id: 'select-on-all-pages',
                            conditions: {
                                disabled: () => {
                                    const allLocations = this._availableStationLocations();
                                    return allLocations && allLocations.length > LIMITS.SELECT_ALL
                                }
                            },
                            tooltip: (selectedIds) => {
                                const allLocations = this._availableStationLocations();
                                return allLocations && allLocations.length > LIMITS.SELECT_ALL
                                    ? this._t('DASHBOARD.BULK_ACTIONS.SELECT_MORE.ONLY_N_CAN_BE_SELECTED', {n: LIMITS.SELECT_ALL})
                                    : null
                            }
                        }
                    ]
                },
                {
                    id: 'restart-stations',
                    title: this._t('DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.TITLE'),
                    icon: 'sync',
                    template: restartStationsTemplate,
                    conditions: {
                        disabled: (selectedIds) => selectedIds.length > LIMITS.RESTARTS,
                        show: (selectedIds) => this._permService.hasPermission('dashboard.table.bulkActions.resetStations'),
                        tooltip: (selectedIds) => selectedIds.length > LIMITS.RESTARTS ? this._t('DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.ONLY_N_CAN_BE_RESTARTED', {n: LIMITS.RESTARTS}) : null
                    }
                },
                {
                    id: 'disable-restart',
                    title: this._t('DASHBOARD.BULK_ACTIONS.DISABLE_RESTARTS.TITLE'),
                    icon: 'sync_lock',
                    template: disableRestartsTemplate,
                    conditions: {
                        disabled: (selectedIds) => selectedIds.length > LIMITS.CONFIGURE_RESTARTS || selectedIds.length === restartDisabledIds.length,
                        show: () => this._permService.hasPermission('dashboard.table.bulkActions.disableRestart'),
                        tooltip: (selectedIds) => selectedIds.length > LIMITS.CONFIGURE_RESTARTS 
                            ? this._t('DASHBOARD.BULK_ACTIONS.DISABLE_RESTARTS.ONLY_N_CAN_BE_DISABLED', {n: LIMITS.CONFIGURE_RESTARTS})
                            : selectedIds.length === restartDisabledIds.length
                                ? this._t("DASHBOARD.BULK_ACTIONS.DISABLE_RESTARTS.DIALOG.TOOLTIP_ALL")
                                : null,
                    }
                },
                {
                    id: 'enable-restart',
                    title: this._t('DASHBOARD.BULK_ACTIONS.ENABLE_RESTARTS.TITLE'),
                    icon: 'sync_lock',
                    template: enableRestartsTemplate,
                    conditions: {
                        disabled: (selectedIds) => selectedIds.length > LIMITS.CONFIGURE_RESTARTS || restartDisabledIds.length === 0,
                        show: () => this._permService.hasPermission('dashboard.table.bulkActions.disableRestart'),
                        tooltip: (selectedIds) => selectedIds.length > LIMITS.CONFIGURE_RESTARTS
                            ? this._t('DASHBOARD.BULK_ACTIONS.ENABLE_RESTARTS.ONLY_N_CAN_BE_ENABLED', {n: LIMITS.CONFIGURE_RESTARTS})
                            : restartDisabledIds.length === 0
                                ? this._t("DASHBOARD.BULK_ACTIONS.ENABLE_RESTARTS.DIALOG.TOOLTIP_ALL")
                                : null,
                    }
                },
                {
                    id: 'download-stations',
                    title: this._t('DASHBOARD.BULK_ACTIONS.EXPORT.TITLE'),
                    icon: 'download',
                    actions: [{
                        title: this._t('DASHBOARD.BULK_ACTIONS.EXPORT.TABLE_DATA'),
                        id: 'export-table'
                    }, {
                        title: this._t('DASHBOARD.BULK_ACTIONS.EXPORT.ERROR_REPORT'),
                        tooltip: this._t('DASHBOARD.BULK_ACTIONS.EXPORT.ERROR_REPORT_TOOLTIP'),
                        id: 'export-error-report',
                        conditions: {
                            // disable if no stations with errors are selected
                            disabled: (selectedIds) => !stationWithLastErrorSelected
                        }
                    }],
                    conditions: {
                        show: (selectedIds) => this._permService.hasPermission('global.export.stations')
                    }
                },
                {
                    id: 'hide-stations',
                    title: this._t('DASHBOARD.BULK_ACTIONS.HIDE.TITLE'),
                    icon: 'visibility_off',
                    template: hideStationsTemplate,
                    conditions: {
                        show: (selectedIds) => this._permService.hasPermission('dashboard.table.bulkActions.hideStations'),
                        disabled: (selectedIds) => selectedIds.length > LIMITS.HIDE,
                        tooltip: (selectedIds) => selectedIds.length > LIMITS.HIDE ? this._t('DASHBOARD.BULK_ACTIONS.HIDE.ONLY_N_CAN_BE_HIDDEN', {n: LIMITS.HIDE}) : null
                    }
                },
                {
                    id: 'open-in-new-tab',
                    title: this._t('DASHBOARD.BULK_ACTIONS.OPEN_IN_NEW_TAB'),
                    icon: 'open_in_new',
                    conditions: {
                        show: (selectedIds) => this._permService.hasPermission('routes.stationDetails'),
                        disabled: (selectedIds) => selectedIds.length > 1
                    }
                }
            ]
        });

        // updates singular/plural translations with new bulk selection and new language selection
        const allbulkActionRowIDs = toSignal(this.overviewRepo.allbulkActionRowIDs$);
        this.bulkActionStationTitle = computed(() => {
            const lang = newLang();
            const bulkSelection = allbulkActionRowIDs();
            return bulkSelection && bulkSelection.length == 1 ? this._t('COMMON.STATION.ONE') : this._t('COMMON.STATION.OTHER');
        });

        // handles updates in hide stations template, syncs interval input with date
        const durationControl = this.hideChargersForm.get('duration');
        const untilDateControl = this.hideChargersForm.get('untilDate');

        if (durationControl && untilDateControl) {
            durationControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((duration: number | null) => {
                const newUntilDate = addDays(new Date(), duration ?? 0);
                untilDateControl.setValue(newUntilDate, { emitEvent: false });
            });

            untilDateControl.valueChanges.pipe(takeUntilDestroyed()).subscribe((untilDate: Date | null) => {
                const newDuration = differenceInDays(new Date(untilDate!), new Date()) + 1;
                durationControl.setValue(newDuration, { emitEvent: false });
            });
        }
    }

    private _t(path: string, interpolateParams?: Object): string {
        return this._translate.instant(path, interpolateParams)
    }

    public handleBulkAction(event: {action: string, selectedIds: (string | number)[]}) {
        const stations = this.stations();
        if (!stations) return;
        switch (event.action) {
            case 'select-current-page':
                // add all non-selected stations from current page to selection
                const filtered = stations.filter((station) => event.selectedIds.indexOf(station.stationId) == -1);
                this.overviewRepo.updateBulkActionStations(filtered);
                break;
            case 'select-on-all-pages':
                // get all stationIds from current request
                const allLocations = this._availableStationLocations();
                // only add stations that are not already selected
                const filteredLocations = allLocations?.filter((stationLocation) => event.selectedIds.indexOf(stationLocation.stationId) == -1);
                if (filteredLocations) this.overviewRepo.updateBulkActionStationLocations(filteredLocations);
                break;
            case 'export-table': case 'export-error-report':
                this._handleExport(event.action, event.selectedIds);
                break;
            case 'open-in-new-tab':
                const stationId = event.selectedIds[0];
                const url = this._router.serializeUrl(
                    this._router.createUrlTree([`/details/${stationId}`])
                );
                window.open(url, '_blank')
                break;
        }
    }

    public toggleStationsVisibility() {
        combineLatest({
            stationIds: this.overviewRepo.allbulkActionRowIDs$,
            showOnlyHidden: this.overviewRepo.showOnlyHidden$
        }).pipe(
            take(1),
            switchMap(({stationIds, showOnlyHidden}) => {
                const interval = this.hideChargersForm.get('duration')?.value;
                if (interval == null) return EMPTY;

                return this._stationService.changeVisibilityOfChargingStations({
                    visibility: showOnlyHidden ? 'show' : 'hide',
                    stationIds,
                    interval
                }).pipe(
                    map((response) => ({response, showOnlyHidden, interval, stationIds}))
                )
            }),
            take(1)
        ).subscribe({
            error: (res) => {
                this._notificationService.showLocalizedError('DASHBOARD.BULK_ACTIONS.HIDE_ERROR');
            },
            next: ({response, showOnlyHidden, interval, stationIds}) => {
                this.closeBulkActions();

                const stationText = stationIds.length == 1 ? this._t('COMMON.STATION.ONE') : this._t('COMMON.STATION.OTHER');
                if (showOnlyHidden) {
                    const text = this._t('DASHBOARD.BULK_ACTIONS.SHOW_SUCCESS', {n: stationIds.length, stationVar: stationText});
                    this._notificationService.showSuccess(text);
                    // return to normal view if all hidden stations were unhidden
                    this.overviewRepo.hiddenStationLocations$.pipe(take(1)).subscribe((hiddenStations) => {
                        if (hiddenStations.length - stationIds.length <= 0) this.overviewRepo.toggleShowOnlyHidden();
                    })
                } else {
                    const interpolateParams = {
                        n: interval,
                        dayVar: interval == 1 ? this._t('COMMON.TIMES.DAY.ONE') : this._t('COMMON.TIMES.DAY.OTHER')
                    }
                    const text = stationIds.length == 1 
                        ? this._t('DASHBOARD.BULK_ACTIONS.HIDE_SUCCESS.ONE', interpolateParams) 
                        : this._t('DASHBOARD.BULK_ACTIONS.HIDE_SUCCESS.OTHER', interpolateParams);
                    this._notificationService.showSuccess(text);
                }
                this.overviewRepo.incrementRefetchRev();
            }
        })
    }

    private _handleExport(type: 'export-error-report' | 'export-table', selectedIds: (string | number)[]) {
        const notification = this._notificationService.showLocalizedLoading('DASHBOARD.BULK_ACTIONS.EXPORT.LOADING_EXPORT');
        
        if (type == 'export-table') {
            // create observable from selectedIds
            const results$ = of(selectedIds).pipe(
                switchMap((stationIds) => {

                    // start with stations from cache
                    return this.overviewRepo.stations$.pipe(
                        // return existing stations from cache and missing ids
                        map((cachedStations) => {
                            return {
                                stations: cachedStations.data.filter((station) => stationIds.includes(station.stationId)),
                                missingIds: stationIds.filter((id) => !cachedStations.data.find((station) => station.stationId === id))
                            }
                        })
                    )
                }),
                switchMap(({stations, missingIds}) => {
                    if (missingIds.length === 0) return of(stations)

                    // fetch additional stations
                    return this._stationService.getChargingStations({
                        filter: filterQueryBuilder([{id: 'stationId', value: missingIds}]),
                        pageNumber: 1,
                        perPage: missingIds.length
                    }).pipe(
                        map((missingStations) => {
                            return stations.concat(missingStations.stations ?? [])
                        })
                    )
                }),
                map((stations) => {
                    let dataToExport: any[] = [];
                    // always sort these columns to the front
                    const fixedColumns: (keyof ChargingStation | keyof Connector)[] = ['stationId', 'connectorId'];

                    for (let i = 0; i < stations.length; i++) {
                        const station = structuredClone(stations[i]);
                        const connectors = station.connectors;
                        // remove connectors array from station, as each connector will be a separate row
                        delete (station as any)['connectors']

                        dataToExport.push(...connectors.map((connector: any) => Object.assign(connector, station)))
                    }

                    // sort: stationId, connectorId, rest alphabetically
                    dataToExport = dataToExport.map((row) => {
                        return Object.keys(row).sort().sort((keyA: any, keyB: any) => {
                            // get pos in ordered array
                            let posA = fixedColumns.indexOf(keyA),
                                posB = fixedColumns.indexOf(keyB);

                            if (posA === -1) return 1
                            if (posB === -1 || posB > posA) return -1
                            return 0
                        }).reduce((obj: any, key: string) => {
                            obj[key] = row[key];
                            return obj
                        }, {})
                    })

                    return dataToExport
                }),
                take(1)
            )

            this._exportService.exportCSVPromise(lastValueFrom(results$)).finally(() => {
                this._notificationService.hideToast(notification);
            })
        }

        if (type === 'export-error-report') {
            const results$: Observable<any[]> = of(selectedIds).pipe(
                take(1),
                switchMap((stationIds) => {
                    const now = new Date();
                    // create requests for every station id
                    return forkJoin(
                        stationIds.map((stationId) =>
                            this._stationService.getErrorsOfChargingStation({
                                stationId: stationId as string,
                                date: now.toISOString(),
                                interval: 14
                            }).pipe(
                                catchError((_) => of([])),
                                mergeMap((errors) =>
                                    this._stationService.getChargingStation({
                                        stationId: stationId as string
                                    }).pipe(
                                        map((station) => {
                                            return {
                                                station: station,
                                                errors: errors
                                            };
                                        })
                                    )
                                )
                            )
                        )
                    ).pipe(
                        map((data) => {
                            // prepare info in export file
                            const errors = data.map((d) => {
                                return d.errors.map((err) => {
                                    // fill extented information from station
                                    const connector = d.station.connectors.find((con) => con.connectorId == err.connectorId);
                                    const error: ExtendedError = {
                                        ...err,
                                        stationId: d.station.stationId,
                                        socketType: connector?.socketType,
                                        currentType: connector?.currentType
                                    };
                                    // sort keys - stationId, connectorId then alphabetically
                                    const keyOrder = ["stationId", "connectorId"];
                                    const sortedKeys = Object.keys(error).sort((a, b) => {
                                        const indexA = keyOrder.indexOf(a);
                                        const indexB = keyOrder.indexOf(b);                                        
                                        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
                                        else if (indexA !== -1) return -1;
                                        else if (indexB !== -1) return 1;
                                        else return a.localeCompare(b);
                                    });
                                    return sortedKeys.reduce((sortedError: any, key) => {
                                        sortedError[key] = error[key as keyof ExtendedError];
                                        return sortedError as ExtendedError;
                                    }, {});
                                });
                            });

                            return errors.flat();
                        })
                    )
                })
            )

            this._exportService.exportCSVPromise(lastValueFrom(results$), 'error_report').finally(() => {
                this._notificationService.hideToast(notification);
            })
        }
    }

    public restartStations(stationIds: string[], onIdle: boolean) {
        this._stationRestartService.restartStation(stationIds, onIdle, this._closeBulkActionsAndRefetch, this._closeBulkActionsAndRefetch)
    }

    public updateStationRestartSchedule(stationIds: string[], schedule: ChargingStation['restartSchedule']) {
        this._stationRestartService.updateSchedule(stationIds, undefined, schedule, this._closeBulkActionsAndRefetch, this._closeBulkActionsAndRefetch)
    }

    public disableRestarts(stationIds: string[], reason: string): Promise<void> {
        this.toggleRestartRequestRunning.set(true);
        return this._stationRestartService.disableRestarts(stationIds, reason)
            .then(() => {
                this._closeBulkActionsAndRefetch();
            })
            .finally(() => {
                this.toggleRestartRequestRunning.set(false);
            });
    }

    public enableRestarts(stationIds: string[]): Promise<void> {
        this.toggleRestartRequestRunning.set(true);
        return this._stationRestartService.enableRestarts(stationIds)
            .then(() => {
                this._closeBulkActionsAndRefetch();
            })
            .finally(() => {
                this.toggleRestartRequestRunning.set(false);
            });
    }

    public closeBulkActions() {
        // remove selection to close bulk actions
        this.overviewRepo.emptyBulkActionStations();
        this.overviewRepo.emptyBulkActionStationLocations();
    }

    private _closeBulkActionsAndRefetch() {
        this.closeBulkActions()
        // refetch stations
        this.overviewRepo.incrementRefetchRev();
    }
}
