/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface AddAlert$Params {
      body: Array<{

/**
 * The id of the filterset with which the alert is associated.
 */
'filterSetId'?: number;

/**
 * Logical operator to be used for comparison.
 */
'operator'?: 'gt' | 'lt' | 'eq';

/**
 * Value to compare station count of the filterset to.
 */
'value'?: number;

/**
 * Name of the alert.
 */
'name'?: string;

/**
 * Whether or not a mail should be send when the alert gets triggered.
 */
'sendMail'?: boolean;

/**
 * Whether or not detailed monitoring (added/removed stationIds) for alert should be performed.
 */
'detailed'?: boolean;
}>
}

export function addAlert(http: HttpClient, rootUrl: string, params: AddAlert$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, addAlert.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<string>;
    })
  );
}

addAlert.PATH = '/alerts/';
