/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addNotification } from '../fn/notification-data/add-notification';
import { AddNotification$Params } from '../fn/notification-data/add-notification';
import { ApiResponse } from '../models/api-response';
import { deleteNotifications } from '../fn/notification-data/delete-notifications';
import { DeleteNotifications$Params } from '../fn/notification-data/delete-notifications';
import { getNotifications } from '../fn/notification-data/get-notifications';
import { GetNotifications$Params } from '../fn/notification-data/get-notifications';
import { Notification } from '../models/notification';
import { updateNotifications } from '../fn/notification-data/update-notifications';
import { UpdateNotifications$Params } from '../fn/notification-data/update-notifications';


/**
 * Notification Management
 */
@Injectable({ providedIn: 'root' })
export class NotificationDataService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getNotifications()` */
  static readonly GetNotificationsPath = '/notifications/';

  /**
   * Return list of notifications.
   *
   * Return a list of all notifications
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications$Response(params?: GetNotifications$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Notification>>> {
    return getNotifications(this.http, this.rootUrl, params, context);
  }

  /**
   * Return list of notifications.
   *
   * Return a list of all notifications
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications(params?: GetNotifications$Params, context?: HttpContext): Observable<Array<Notification>> {
    return this.getNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Notification>>): Array<Notification> => r.body)
    );
  }

  /** Path part for operation `addNotification()` */
  static readonly AddNotificationPath = '/notifications/';

  /**
   * Add a new notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNotification$Response(params?: AddNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return addNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a new notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addNotification(params?: AddNotification$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.addNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `updateNotifications()` */
  static readonly UpdateNotificationsPath = '/notifications/update/';

  /**
   * Update a list of notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotifications()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotifications$Response(params?: UpdateNotifications$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return updateNotifications(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a list of notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNotifications$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNotifications(params?: UpdateNotifications$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.updateNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `deleteNotifications()` */
  static readonly DeleteNotificationsPath = '/notifications/delete/';

  /**
   * Delete a list of notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNotifications$Response(params: DeleteNotifications$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return deleteNotifications(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a list of notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNotifications(params: DeleteNotifications$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.deleteNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

}
