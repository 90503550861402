/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FilterOption } from '../../models/filter-option';

export interface GetFilterOption$Params {

/**
 * Name of the variable for which should be filtered
 */
  filterVariable: string;

/**
 * String representation of a complex filter expression
 */
  filter?: string;
}

export function getFilterOption(http: HttpClient, rootUrl: string, params: GetFilterOption$Params, context?: HttpContext): Observable<StrictHttpResponse<FilterOption>> {
  const rb = new RequestBuilder(rootUrl, getFilterOption.PATH, 'get');
  if (params) {
    rb.path('filterVariable', params.filterVariable, {});
    rb.query('filter', params.filter, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FilterOption>;
    })
  );
}

getFilterOption.PATH = '/filterOptions/{filterVariable}/';
