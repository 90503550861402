import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { takeUntil, Subject, take } from 'rxjs';
import { NotificationService } from 'src/app/core/app-services';
import { GlobalService } from 'src/app/core/app-services/global.service';
import { KeywordLoggingService } from 'src/app/core/data-backend/data-services';
import { inAnimation, outAnimation } from 'src/app/core/helpers/animations';
import { appRepository } from 'src/app/core/stores/app.repository';

@Component({
    selector: 'app-navigation-menu',
    template: `
    <div class="container" #containerRef>
        <div 
            *ngIf="isOpen && (globalService.urlEnd$ | async) as view"
            class="settings box p-16"
            [@inOutAnim]
            (@inOutAnim.start)="updateDropdownPosition()"
        >

            <div 
                *evcHasPermissions="'routes.dashboard'"
                class="tile"
                [class.active]="view == 'overview'"
                tabindex="1"
                [routerLink]="'/overview'"
                (click)="close()"
            >
                <div class="material-icon">dashboard</div>
                {{ 'NAVIGATION.OPERATOR_DASHBOARD' | translate }}
            </div>

            <div
                *evcHasPermissions="'global.meterValueCalculator'"
                class="tile"
                tabindex="1"
                (click)="
                    globalService.toggleMeterValueEstimator(); 
                    sendKeywordToBackend('meter_value_calculator');
                    close();
                "
            >
                <div class="material-icon">electric_meter</div>
                {{ 'NAVIGATION.METER_VALUE_ESTIMATOR' | translate }}
            </div>

            <div 
                *evcHasPermissions="'routes.operationMap'"
                class="tile"
                [class.active]="view == 'map'"
                tabindex="1"
                [routerLink]="'/map'"
                (click)="
                    view !== 'map' ? sendKeywordToBackend('map_overview') : '';
                    close();
                "
            >
                <div class="material-icon">map</div>
                {{ 'NAVIGATION.OPERATION_MAP' | translate }}
            </div>

            <div 
                *evcHasPermissions="'routes.kpiDashboard'"
                class="tile"
                [class.active]="view == 'kpi'"
                tabindex="1"
                [routerLink]="'/kpi'"
                (click)="
                    view !== 'kpi' ? sendKeywordToBackend('kpi_dashboard') : '';
                    close();
                "
            >
                <div class="material-icon">speed</div>
                {{ 'NAVIGATION.KPI_DASHBOARD' | translate }}
            </div>

            <div 
                *evcHasPermissions="'routes.filterSets'"
                class="tile"
                [class.active]="view == 'filtersets'"
                tabindex="1"
                [routerLink]="'/filtersets'"
                (click)="
                    view !== 'filtersets' ? sendKeywordToBackend('filtersets') : '';
                    close();
                "
            >
                <div class="material-icon">filter_alt</div>
                {{ 'NAVIGATION.FILTER_SETS_AND_ALERTS' | translate }}
            </div>

            <div 
                *evcHasPermissions="'routes.insights'"
                class="tile"
                [class.active]="view == 'error-insights'"
                tabindex="1"
                [routerLink]="'/insights'"
                (click)="
                    view !== 'error-insights' ? sendKeywordToBackend('insights') : '';
                    close();
                "
            >
                <div class="material-icon">insights</div>
                {{ 'NAVIGATION.INSIGHTS' | translate }}
            </div>
            
        </div>
    </div>
    <div 
        [class.page-mask]="isOpen"
        (click)="close()"
    ></div>
    `,
    styleUrls: ['./navigation-menu.component.scss'],
    animations: [
        trigger('inOutAnim', [
            transition(':enter', [
                useAnimation(inAnimation)
            ]),
            transition(':leave', [
                useAnimation(outAnimation)
            ])
        ])
    ]
})
export class NavigationMenuComponent implements OnInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    @Input() set open(open: boolean) {
        open ? this._setListeners() : this._removeListeners();
        this.isOpen = open
    };
    @Output() openChange = new EventEmitter<boolean>();
    isOpen: boolean = false;

    private _unlistenEvents: (() => void)[] = [];

    @ViewChild('containerRef', {static: false}) set listener(elRef: ElementRef) {
        if (!elRef) return;
        this._containerRef = elRef;
        this._setListeners()
    }
    private _containerRef: ElementRef | undefined;

    constructor(
        private _elRef: ElementRef,
        private _renderer: Renderer2,
        private _keywordLoggingService: KeywordLoggingService,
        public appRepo: appRepository,
        public ngZone: NgZone,
        public globalService: GlobalService,
        public notificationService: NotificationService,
    ) {}

    // set document event listeners
    private _setListeners() {
        this.ngZone.runOutsideAngular(() => {
            this._unlistenEvents.push(
                // listen to scroll and mousedown on document once dropdown is rendered
                this._renderer.listen('document', 'scroll', (event) => {
                    this.updateDropdownPosition()
                })
            )
        })
        this._unlistenEvents.push(
            this._renderer.listen('document', 'mousedown', (event) => {
                let inComponent = this._elRef.nativeElement.contains(event.target),
                    ofTrigger = (event.target as any).classList.contains('menu-trigger');
                
                if (!inComponent && !ofTrigger) {
                    this.close()
                }
            }),
            this._renderer.listen('window', 'keydown.esc', () => this.close())
        )
    }

    updateDropdownPosition() {
        if (!this._containerRef) return
        const height = window.scrollY > 50 ? 65 : 75;
        this._renderer.setStyle(this._containerRef.nativeElement, 'top', height + 'px')
    }

    private _removeListeners() {
        this._unlistenEvents.forEach((fn) => fn())
    }

    close() {
        this.isOpen = false
        this._removeListeners()
        this.openChange.emit(false)
    }

    sendKeywordToBackend(keyword: string): void {
        this._keywordLoggingService.logTraffic({'body': {keyword: keyword}}).pipe(
            take(1)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._removeListeners()
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    ngOnInit() {
        this.updateDropdownPosition()
    }
}
