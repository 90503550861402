import { Component, computed, inject, input, output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'evc-enable-restarts',
    template: `
        <div class="enable-restarts-dialog">
            @if (restartEnabledIds() > 0) {
                <strong>{{ 'DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.ENABLED_RESTARTS_TITLE' | translate }}</strong>
                <span>{{ 'DASHBOARD.BULK_ACTIONS.RESTART_STATIONS.ENABLED_RESTARTS_INFO' | translate: {n: restartEnabledIds(), m: selectedIds().length} }}</span>
            }
            <div>
                <button
                    evc-button
                    icon="sync_lock"
                    [nowrap]="true"
                    [loading]="loading()"
                    [disabled]="loading() || selectedIds().length === restartEnabledIds()"
                    [tooltip]="tooltip()"
                    size="small"
                    (click)="submit.emit()"
                >{{ 'DASHBOARD.BULK_ACTIONS.ENABLE_RESTARTS.TITLE' | translate }}</button>
            </div>
        </div>
  `,
    styleUrl: './enable-restarts.component.scss'
})
export class EnableRestartsComponent {
    private readonly _t = inject(TranslateService);
    public readonly selectedIds = input<string[]>([]);
    public readonly restartDisabledIds = input<string[]>([]);
    public readonly restartEnabledIds = computed(() => this.selectedIds().length - this.restartDisabledIds().length);
    public readonly loading = input<boolean>(false);
    public readonly tooltip = computed(() => {
        if (this.restartDisabledIds().length > 0) return null;
        return this._t.instant('DASHBOARD.BULK_ACTIONS.ENABLE_RESTARTS.DIALOG.TOOLTIP_ALL');
    });
    public readonly submit = output<void>();
}
