/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OverallStatus } from '../../models/overall-status';

export interface GetOverallStatesOfChargingStation$Params {

/**
 * Unique identifier of the Charging Station
 */
  stationId: string;

/**
 * The time up to which the list of overall states should be displayed
 */
  date?: string;

/**
 * The period in days before 'date' from which all overall states should be returned
 */
  interval?: number;

/**
 * Whether to aggregate per day and return the worst result
 */
  aggregate?: boolean;
}

export function getOverallStatesOfChargingStation(http: HttpClient, rootUrl: string, params: GetOverallStatesOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OverallStatus>>> {
  const rb = new RequestBuilder(rootUrl, getOverallStatesOfChargingStation.PATH, 'get');
  if (params) {
    rb.path('stationId', params.stationId, {});
    rb.query('date', params.date, {});
    rb.query('interval', params.interval, {});
    rb.query('aggregate', params.aggregate, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<OverallStatus>>;
    })
  );
}

getOverallStatesOfChargingStation.PATH = '/chargingStations/{stationId}/overallStates/';
