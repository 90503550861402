/* tslint:disable */
/* eslint-disable */
/* 
 * Code generated by ng-openapi-gen.
 * Based on handlebars/auth-backend/configuration.handlebars
 * DO NOT EDIT. 
 */

import { Injectable } from '@angular/core';

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root',
})
export class AuthApiConfiguration {
  rootUrl: string = '';
}

/**
 * Parameters for `AuthApiModule.forRoot()`
 */
export interface AuthApiConfigurationParams {
  rootUrl?: string;
}
