{
    "bp": {
        "md": "992px",
        "lg": "1200px",
        "xl": "1440px",
        "xxl": "1800px"
    },
    "animation-duration": ".35s",
    "max-width": "1920px",
    "nav-height": {
        "base": "75px",
        "condensed": "65px"
    },
    "text": {
        "base": "#475569",
        "darker": "darken(base, 10%)",
        "dark": "darken(base, 20%)",
        "lighter": "lighten(base, 10%)",
        "light": "lighten(base, 20%)",
        "super-light": "lighten(base, 30%)",
        "active": "#404040"
    },
    "background": {
        "base": "#F3F2FB",
        "light": "lighten(base, 10%)",
        "dark": "darken(base, 10%)"
    },
    "grey": {
        "base": "#C4C4C4",
        "light": "#EDEDED",
        "strong": "#94A3B8",
        "table": "#E8E8E8"
    },
    "primary": {
        "base": "#8B71D2",
        "lighter": "lighten(base, 10%)",
        "light": "lighten(base, 20%)",
        "super-light": "lighten(base, 30%)",
        "darker": "darken(base, 10%)",
        "dark": "#6A21B8"
    },
    "secondary": {
        "base": "#B7AFEA",
        "darker": "darken(base, 8%)",
        "dark": "darken(base, 12%)",
        "lighter": "lighten(base, 8%)",
        "light": "lighten(base, 12%)",
        "super-light": "#D8D4F3"
    },
    "tertiary": {
        "lighter": "#B7B0E4",
        "base": "#5400AE",
        "dark": "darken(base, 10%)"
    },
    "status": {
        "ok": "#027B75",
        "to-be-monitored": "#F8BB00",
        "potential-failure": "#DD7900",
        "failure": "#B92001",
        "no-data": "#C4C4C4"
    },
    "white": "#ffffff",
    "off-white": "#F6F6F7",
    "midnight": "#190446",
    "box-shadow": {
        "base": "0px 1px 4px 0px #0000002E",
        "focus": "0px 0px 0px 2px rgba($primary--dark, .35)",
        "panel": "rgba(0, 0, 0, 0.08) 0px 0px 10px"
    },
    "border-image": "linear-gradient(98deg, rgba(0, 0, 0, 0.2) 7.5%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.1) 54%, rgba(0, 0, 0, 0.2) 88%) 1",
    "background-image": "linear-gradient(white, white), linear-gradient(140deg, rgba(0, 0, 0, 0.24) 10.5%, rgba(0, 0, 0, 0.12) 40% 54%, rgba(0, 0, 0, 0.24) 88%)"
}
