import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2, Signal, ViewChild, computed, model } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { changeHeight } from 'src/app/core/helpers/animations';
import { StationColumn, overviewRepository } from 'src/app/core/stores/overview.repository';

type ColumnOption = {
    id: number,
    name: string,
    checked: boolean,
    default: boolean,
    columnIndex?: number
}

@Component({
    selector: 'app-select-table-columns',
    template: `
    <div class="column-icon">
        <app-dropdown-button
            size="small"
            [hideBtn]="true"
            [alignment]="'right'"
            [bodyClass]="bodyClasses.join(' ')"
            [heightFollowsChild]="true"
            [open]="isOpen()"
            (onClose)="onClose()"
            (onEnter)="onClose()"
            (onOpen)="onOpen()"
            (onSearch)="searchInput.focus()"
        >
            <ng-container body>
                <div class="columns-body">
                    <div class="position-relative search-bar">
                        <input 
                            type="text"
                            [placeholder]="'COMMON.SEARCH' | translate"
                            (input)="searchEntries($event)"
                            #searchInput
                        >
                        <button
                            class="delete-search"
                            (click)="resetSearch()"
                            tabindex="-1"
                            type="button"
                        ></button>
                    </div>
                    @if (searchQuery && visibleOptions && visibleOptions.length > 0) {
                        <a
                            class="select-all"
                            tabindex="0"
                            (click)="selectAllFilteredOptions()"
                        >
                            {{ 'COMMON.SELECT_ALL' | translate }}
                        </a>
                    }
                    <!-- Main List -->
                    <div class="columns-cont">
                        @for (column of visibleImportantOptionsColumns; track column; let i = $index) {
                            <div class="options-column">
                                @for (option of visibleImportantOptionsColumns[i]; track option.name) {
                                    <label class="justify-content-start align-items-center">
                                        <input 
                                            type="checkbox" 
                                            [name]="option.name"
                                            [checked]="option.checked"
                                            (change)="prepareSelection(option)"
                                        >
                                        <span
                                            class="title"
                                            [tooltip]="
                                                option.name.length > limit ?
                                                option.name : null
                                            "
                                            size="small"
                                            textAlign="left"
                                        >{{ 
                                            option.name.length > limit ? 
                                            option.name.substring(0, limit) + '...'
                                            : option.name 
                                        }}</span>
                                    </label>
                                }
                        </div>
                        }
                    </div>
                    <ng-container *ngTemplateOutlet="
                        availableOptions.length == 0 ? loadingState : emptyState; 
                        context: {$implicit: visibleOptions}">
                    </ng-container>
                    <!-- Expand Btn -->
                    <button 
                        class="show-more"
                        type="button"
                        [class.expanded]="showMore$ | async"
                        [disabled]="showMoreDisabled$ | async"
                        (click)="showMore$.next(!showMore$.value)"
                    >{{ 'COMMON.SHOW_MORE' | translate }}</button>
                    <!-- Sub List-->
                    @if (showMore$ | async) {
                        <div
                            class="columns-cont-sub"
                            [@changeHeight]
                        >
                            @for (column of visibleOtherOptionsColumns; track column; let i = $index) {
                                <div class="options-column">
                                    @for (option of visibleOtherOptionsColumns[i]; track option.name) {
                                        <label class="justify-content-start align-items-center">
                                            <input 
                                                type="checkbox" 
                                                [name]="option.name"
                                                [checked]="option.checked"
                                                (change)="prepareSelection(option)"
                                            >
                                            <span
                                                class="title"
                                                [tooltip]="
                                                    option.name.length > limit ?
                                                    option.name : null
                                                "
                                                size="small"
                                                textAlign="left"
                                            >{{ 
                                                option.name.length > limit ? 
                                                option.name.substring(0, limit) + '...'
                                                : option.name 
                                            }}</span>
                                        </label>
                                    }
                                </div>
                            }

                        </div>
                        <button
                            class="restore-default-btn"
                            type="button"
                            (click)="restoreDefaultColumns()"
                            [@changeHeight]="{ value: '*', params: { duration: '100ms' } }"
                        >
                            {{ 'DASHBOARD.TABLE.RESTORE_DEFAULT' | translate }}
                        </button>
                    }
                </div>
            </ng-container>
        </app-dropdown-button>
    </div>
    <!-- empty state -->
    <ng-template #emptyState let-contents>
        @if (!contents || contents.length == 0) {
            <p class="empty-state">
                @if (searchQuery && searchQuery.trim().length > 0) {
                    {{ 'DASHBOARD.TABLE.NO_MATCHING_COLUMNS' | translate: {content: searchQuery} }}
                } @else {
                    {{ 'DASHBOARD.TABLE.NO_COLUMNS' | translate }}
                }
            </p>
        }
    </ng-template>
    <!-- loading state -->
    <ng-template #loadingState let-contents>
        <!-- only show loading state when no data is available (first fetch), not while updating -->
        @if (!contents || contents.length == 0) {
            <p class="empty-state">
                {{ 'COMMON.LOADING.DEFAULT' | translate }}
            </p>
        }
    </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./select-table-columns.component.scss'],
    animations: [changeHeight]
})
export class SelectTableColumnsComponent {
    @ViewChild('searchInput') searchInput: ElementRef | undefined;

    @Input('availableTableColumns') set availableTableColumns(columns: StationColumn[] | null){
        this.availableOptions = columns?.map((column) => {
            // remove <br> from column names
            const name = column.name.split('<br>').join('');
            return {
                id: column.id,
                name: name,
                checked: false,
                default: column.selected ?? false
            }
        }) || [];
        // set visible options to all at start
        this.visibleOptions = this.availableOptions;
    }
    // available column options
    public availableOptions: ColumnOption[] = [];
    // visible column options
    private _visibleOptions: ColumnOption[] = [];
    public visibleImportantOptionsColumns: ColumnOption[][] = [];
    public visibleOtherOptionsColumns: ColumnOption[][] = [];
    get visibleOptions(): ColumnOption[] {
        return this._visibleOptions;
    }
    set visibleOptions(o: ColumnOption[]) {
        this._visibleOptions = o;
        // preserve previous column indexes if they exist
        const previousIndexes = new Map<string, number>();
        this._visibleOptions.forEach(option => {
            if (option.columnIndex != undefined) {
                previousIndexes.set(option.name, option.columnIndex);
            }
        });
        // get current column translations
        const importantColumnNames = this.importantColumns();
        // sort important options as in given list
        const visibleImportantOptions = this._visibleOptions.filter(
            option => importantColumnNames.includes(option.name)
        ).sort((a, b) => {
            const indexA = importantColumnNames.indexOf(a.name);
            const indexB = importantColumnNames.indexOf(b.name);        
            return indexA - indexB;
        });
        // other options are sorted alphabetically
        const visibleOtherOptions = this._visibleOptions.filter(
            option => !importantColumnNames.includes(option.name)
        ).sort((a, b) => a.name.localeCompare(b.name)); // Ensure consistent sorting for other options
        // split into three columns
        this.visibleImportantOptionsColumns = this.splitIntoColumns(visibleImportantOptions, previousIndexes);
        this.visibleOtherOptionsColumns = this.splitIntoColumns(visibleOtherOptions, previousIndexes);
    }

    @Input('selectedTableColumns') set selectedTableColumns(columns: StationColumn[] | null) {
        // we only need the column id
        const mappedActiveValues = columns?.map((column) => column.id) || [];
        // store prev values to compare against, start with selectedTableColumns from parent
        const toCopy = this.activeValues === null || this.activeValues.length === 0 ? mappedActiveValues : this.activeValues;
        this._previousValues = structuredClone(toCopy);
        this.activeValues = mappedActiveValues
    };
    // currently selected columns - and prev value
    public activeValues: number[] = [];
    private _previousValues: number[] = [];

    // max amount of chars per label
    limit: number = 30;
    // whether more items are shown or not
    showMore$ = new BehaviorSubject<boolean>(false);
    // whether show more button is enabled or disabled
    showMoreDisabled$ = new BehaviorSubject<boolean>(false);
    // classes for body of dropdown
    bodyClasses: string[] = [
        'select-filters-dropdown',
        'swiper-no-swiping',
        'overflow-hidden'
    ];
    // keep track of dropdown state, can be controlled by parent
    isOpen = model<boolean>(false);
    // search string in dropdown
    searchQuery: string | undefined;
    // helper to set and unlisten to events
    private _unlistenEvents: (() => void)[] = [];

    // translation mapping
    private _importantColumns: StationColumn['name'][] = [
        "STATION", "DEVICE", "LOCATION", "EVSE_ID", "EVSE_NAME", "CPO_INFORMATION", "SERIAL_NUMBER", "VERSIONS", "ADDRESS", "ADDRESS_SUFFIX", "CITY", "TENANT",
        "INDICATORS", "OVERALL_STATE", "CHARGING_MODEL_STATE", "HEARTBEAT_STATE", "AI_PREDICTION", "LAST_ERROR", "LAST_ERROR_STATE_CAUSE", "PLUG", "LAST_RESTART", "CURRENT_TICKET", "TICKETS",
        "USAGE", "SESSIONS_2_W", "SESSIONS_24_H", "LAST_SESSION", "MAX_CHARGING_POWER_2_W", "AVG_CHARGING_POWER_2_W", "CPO_NAME", "CPO_SELLER_ID", "LAST_OCPP_MESSAGE", "RECOMMENDATION"
    ];
    public importantColumns: Signal<StationColumn['name'][]>;

    constructor(
        private _repo: overviewRepository,
        private _renderer: Renderer2,
        private _translate: TranslateService
    ) {
        const newLang = toSignal(this._translate.onLangChange);
        this.importantColumns = computed(() => {
            newLang();
            return this._importantColumns.map((mapKey) => this._translate.instant(`DASHBOARD.COLUMNS.${mapKey}.TITLE`))
        })
    }

    // filter for all groups
    searchEntries(search: Event) {
        this.searchQuery = (search.target as HTMLInputElement).value;

        if (this.searchQuery && this.searchQuery.length > 0) {
            const query = this.searchQuery!.trim().toLowerCase();
            this.visibleOptions = this.availableOptions.filter(option => {
                const optLabel  = JSON.stringify(option.name).toLowerCase();
                return optLabel.includes(query)
            })
        } else {
            this.visibleOptions = this.availableOptions;
            this.searchQuery = undefined;
        }

        this.updateShowMore();
    }

    resetSearch() {
        // delete search input, set input back in focus
        this.searchQuery = undefined;
        this.visibleOptions = this.availableOptions;

        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
            this.searchInput.nativeElement.focus()
        }

        this.updateShowMore();
    }

    restoreDefaultColumns() {
        this.availableOptions.forEach(option => option.checked = false);
        this.activeValues = [];
        const defaultColumns = this.availableOptions
            .filter(option => option.default)
            .sort((a, b) => a.id - b.id);
        defaultColumns.forEach(option => this.prepareSelection(option));
        setTimeout(() => {
            this._repo.setColumns(defaultColumns.map(option => option.id));
        }, 100);
        this.onClose(false);
    }

    selectAllFilteredOptions() {
        const columnsToPrepare = this.visibleOptions.filter(option => {
            return !this.activeValues.includes(option.id)
        });

        if (columnsToPrepare.length == 0) {
            this.visibleOptions.forEach((option) => this.prepareSelection(option))
        } else {
            columnsToPrepare.forEach((option) => this.prepareSelection(option))
        }
    }

    // check all currently active options
    checkOptions() {
        this.availableOptions.forEach(option => {
            if (this.activeValues.includes(option.id)) {
                option.checked = true;
            }
        });
    }

    // add or remove from retrieved initial array of values
    prepareSelection(option: ColumnOption) {
        let index = this.activeValues.indexOf(option.id);
        option.checked = !option.checked;
        if (index === -1) {
            this.activeValues.push(option.id);
        } else {
            this.activeValues.splice(index, 1);
        }
        this.updateShowMore()
    }

    // updates whether the show more button should be enabled or disabled
    updateShowMore() {
        const importantColumnNames = this.importantColumns();
        const noImportantOptions = this.visibleImportantOptionsColumns.every(column => column.length === 0);
        const noOtherOptions = this.visibleOtherOptionsColumns.every(column => column.length === 0);
        const hasOtherOption = this.availableOptions
            .filter(option => this.activeValues.includes(option.id))
            .some(option => !importantColumnNames.includes(option.name));
        const hasSearch = this.searchQuery !== undefined;

        const setShowMore = (showMore: boolean, disabled: boolean) => {
            this.showMore$.next(showMore);
            this.showMoreDisabled$.next(disabled);
        };

        if (hasSearch && !noOtherOptions) {
            setShowMore(true, false);
            return;
        }

        if (noOtherOptions) {
            setShowMore(false, true);
            return;
        }
        if (
            (noImportantOptions && !noOtherOptions) ||
            hasOtherOption
        ) {
            setShowMore(true, true);
            return;
        }
        this.showMoreDisabled$.next(false);
    }

    // update filters value in repo when dropdown is closed
    onClose(applyChanges = true) {
        this.isOpen.set(false);
        // timeout 0 for angular animations
        setTimeout(() => this.showMore$.next(false), 0);
        this.resetSearch();
        if (applyChanges) this.applyChanges();
        // fn called on close of dropdown, clear all event listeners
        this._unlistenEvents.forEach((unlistener) => unlistener());
    }

    // compare against last selected columns
    applyChanges() {
        const columnsToRemove = this._previousValues.filter((activeId) => {
            return this.activeValues.indexOf(activeId) === -1
        });
        const columnsToAdd = this.activeValues.filter((selectedId) => {
            return this._previousValues.indexOf(selectedId as number) === -1
        })
        setTimeout(() => {
            columnsToRemove.forEach((columnId) => this._repo.removeColumn(columnId))
            columnsToAdd.forEach((columnId) => this._repo.addColumn(columnId as number))
        }, 0)
    }

    onOpen() {
        this.isOpen.set(true);
        this.visibleOptions = this.availableOptions;
        this.checkOptions();
        setTimeout(() => {
            this.updateShowMore();
            // foucs search input if available
            if (this.searchInput) this.searchInput.nativeElement.focus()
        }, 20);

        this._unlistenEvents.push(
            this._renderer.listen('document', 'keydown.space', (event: KeyboardEvent) => {
                // dont prevent default while on input
                if (event.target instanceof Element && event.target instanceof HTMLInputElement) return
                // prevent scrolling on space, we only want to select checkbox in focus
                event.preventDefault()
            })
        )
    }


    splitIntoColumns(array: ColumnOption[], previousIndexes: Map<string, number>): ColumnOption[][] {
        const columns: ColumnOption[][] = [[], [], []];
        const partSize = Math.ceil(array.length / 3);
    
        array.forEach(option => {
            const index = previousIndexes.get(option.name) ?? -1;
            if (index >= 0 && index < 3) {
                columns[index].push(option);
            } else {
                const colIndex = columns.findIndex(col => col.length < partSize);
                columns[colIndex].push(option);
                option.columnIndex = colIndex;
            }
        });
    
        return columns;
    }
}
