/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HealthIndexValue } from '../../models/health-index-value';

export interface GetHealthIndexValuesOfChargingStation$Params {

/**
 * Unique identifier of the Charging Station
 */
  stationId: string;

/**
 * Time at which the health index values of a specific charging station should be returned
 */
  date?: string;

/**
 * The period in days before 'date' from which all health index values should be returned
 */
  interval?: number;
}

export function getHealthIndexValuesOfChargingStation(http: HttpClient, rootUrl: string, params: GetHealthIndexValuesOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<HealthIndexValue>>> {
  const rb = new RequestBuilder(rootUrl, getHealthIndexValuesOfChargingStation.PATH, 'get');
  if (params) {
    rb.path('stationId', params.stationId, {});
    rb.query('date', params.date, {});
    rb.query('interval', params.interval, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<HealthIndexValue>>;
    })
  );
}

getHealthIndexValuesOfChargingStation.PATH = '/chargingStations/{stationId}/healthIndexValues/';
