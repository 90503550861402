/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ChargingStation } from '../../models/charging-station';
import { OverviewMetaInfo } from '../../models/overview-meta-info';

export interface GetChargingStations$Params {

/**
 * Time at which the list of chargers should be displayed
 */
  date?: string;

/**
 * Number of the page
 */
  pageNumber?: number;

/**
 * Number of items per page
 */
  perPage?: number;

/**
 * Attribute to sort by
 */
  sortBy?: string;

/**
 * Order of sorting
 */
  sortOrder?: 'asc' | 'desc';

/**
 * Term to search for in all fields of "#/components/schemas/ChargingStation"
 */
  search?: string;

/**
 * String representation of a complex search expression in url-encoded format. The query is a list of key-value pairs, the key being a field of "#/components/schemas/ChargingStation"
 */
  searchFields?: string;

/**
 * List of string representations of complex filter expressions
 */
  filter?: Array<string>;

/**
 * Minimum latitude of map section
 */
  latMin?: number;

/**
 * Minimum latitude of map section
 */
  latMax?: number;

/**
 * Maximum longitude of map section
 */
  lonMin?: number;

/**
 * Maximum longitude of map section
 */
  lonMax?: number;

/**
 * Include only hidden stations
 */
  showOnlyHidden?: boolean;
}

export function getChargingStations(http: HttpClient, rootUrl: string, params?: GetChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}>> {
  const rb = new RequestBuilder(rootUrl, getChargingStations.PATH, 'get');
  if (params) {
    rb.query('date', params.date, {});
    rb.query('pageNumber', params.pageNumber, {});
    rb.query('perPage', params.perPage, {});
    rb.query('sortBy', params.sortBy, {});
    rb.query('sortOrder', params.sortOrder, {});
    rb.query('search', params.search, {});
    rb.query('searchFields', params.searchFields, {});
    rb.query('filter', params.filter, {});
    rb.query('latMin', params.latMin, {});
    rb.query('latMax', params.latMax, {});
    rb.query('lonMin', params.lonMin, {});
    rb.query('lonMax', params.lonMax, {});
    rb.query('showOnlyHidden', params.showOnlyHidden, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'stations'?: Array<ChargingStation>;
      'meta'?: OverviewMetaInfo;
      }>;
    })
  );
}

getChargingStations.PATH = '/chargingStations/';
