import { trigger, transition, useAnimation } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { distinctUntilArrayItemChanged } from '@ngneat/elf';
import { of, BehaviorSubject, combineLatest, map, tap, switchMap, catchError, Subject, takeUntil, zip, forkJoin, withLatestFrom, filter } from 'rxjs';
import { NotificationService } from 'src/app/core/app-services';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { DashboardService } from 'src/app/core/data-backend/data-services';
import { inAnimation } from 'src/app/core/helpers/animations';
import { filterQueryBuilder } from 'src/app/core/helpers/utils.helper';
import { KpiPlotsService } from 'src/app/core/plots/kpi-plots.service';
import { PlotTooltipsService } from 'src/app/core/plots/plot-tooltips.service';
import { DateRange } from 'src/app/core/plots/plot.models';
import { appRepository } from 'src/app/core/stores/app.repository';
import { detailsRepository } from 'src/app/core/stores/details.repository';
import { overviewRepository } from 'src/app/core/stores/overview.repository';
import { stationFiltersRepository } from 'src/app/core/stores/station-filters.repository';

@Component({
    selector: 'app-kpi-plots',
    template: `
        <div class="container pb-48">
            <div class="kpi-plots-box flex-row mt-48">
                <ng-container *evcHasPermissions="'kpiDashboard.plots.chargingSessions'">
                    <div 
                        class="plot-box"
                        *ngIf="showChargingSessions$ | async; else pollingOrFailure"
                        [@inAnimation]
                    >
                        <div class="plot-header">
                            <div>
                                <div class="title">{{ 'KPI_VIEW.CHARGING_SESSIONS_PLOT.TITLE' | translate }}</div>
                                <div class="subtitle">{{ 'KPI_VIEW.CHARGING_SESSIONS_PLOT.SUBLINE' | translate }}</div>
                            </div>
                            <div *ngIf="(kpiChargingSessionsPlot?.plotStatus$ | async) == 'ready'" class="total-amount">
                                @if (totalChargingSessions > 0) {
                                    {{ 'KPI_VIEW.CHARGING_SESSIONS_PLOT.TOTAL' | translate: {n: (totalChargingSessions | localizedNumber)} }}
                                } @else {
                                    {{ 'KPI_VIEW.CHARGING_SESSIONS_PLOT.CALC_FAILED' | translate }}
                                }
                            </div>
                        </div>
                        <div
                            class="position-relative plot-wrapper"
                            #tooltipRef
                            *ngIf="(kpiChargingSessionsPlot?.plotStatus$ | async) == 'ready'; else noData"
                        >
                            <plotly-plot
                                [data]="(kpiChargingSessionsPlot?.plotData$ | async) || []"
                                [layout]="(kpiChargingSessionsPlot?.layout$ | async) || {}"
                                [config]="kpiChargingSessionsPlot?.plotConfig"
                                [updateOnlyWithRevision]="true"
                                [revision]="(kpiChargingSessionsPlot?.plotRevision$ | async) || 0"
                                [useResizeHandler]="false"
                                [style]="{position: 'relative', width: '470px', height: '380px'}"
                                (doubleClick)="kpiChargingSessionsPlot?.onDoubleClick()"
                                (hover)="this.plotTooltips.renderTooltip($event, tooltipRef, 'small')"
                                (unhover)="this.plotTooltips.removeTooltip()"
                                (relayout)="onRelayout($event)"
                            >
                            </plotly-plot>
                        </div>
                    </div>
                </ng-container>

                <ng-container *evcHasPermissions="'kpiDashboard.plots.successfulSessions'">
                    <div 
                        class="plot-box"
                        *ngIf="showSuccessfulSessions$ | async; else pollingOrFailure"
                        [@inAnimation]
                    >
                        <div class="plot-header">
                            <div>
                                <div class="title">{{ 'KPI_VIEW.SUCCESSFUL_SESSIONS_PLOT.TITLE' | translate }}</div>
                                <div class="subtitle">{{ 'KPI_VIEW.SUCCESSFUL_SESSIONS_PLOT.SUBLINE' | translate }}</div>
                            </div>
                            <div *ngIf="(kpiSuccessfulSessionsPlot?.plotStatus$ | async) == 'ready'" class="total-amount">
                                @if (meanSuccessfulSessions > 0) {
                                    {{ 'KPI_VIEW.SUCCESSFUL_SESSIONS_PLOT.AVERAGE' | translate: {n: (meanSuccessfulSessions*100).toFixed(1)} }}
                                } @else {
                                    {{ 'KPI_VIEW.SUCCESSFUL_SESSIONS_PLOT.CALC_FAILED' | translate }}
                                }
                            </div>
                        </div>
                        <div
                            class="position-relative plot-wrapper"
                            #tooltipRef
                            *ngIf="(kpiSuccessfulSessionsPlot?.plotStatus$ | async) == 'ready'; else noData"
                        >
                            <plotly-plot
                                [data]="(kpiSuccessfulSessionsPlot?.plotData$ | async) || []"
                                [layout]="(kpiSuccessfulSessionsPlot?.layout$ | async) || {}"
                                [config]="kpiSuccessfulSessionsPlot?.plotConfig"
                                [updateOnlyWithRevision]="true"
                                [revision]="(kpiSuccessfulSessionsPlot?.plotRevision$ | async) || 0"
                                [useResizeHandler]="false"
                                [style]="{position: 'relative', width: '470px', height: '380px'}"
                                (doubleClick)="kpiSuccessfulSessionsPlot?.onDoubleClick()"
                                (hover)="this.plotTooltips.renderTooltip($event, tooltipRef, 'small')"
                                (unhover)="this.plotTooltips.removeTooltip()"
                                (relayout)="onRelayout($event)"
                            >
                            </plotly-plot>
                        </div>
                    </div>
                </ng-container>

                <ng-container *evcHasPermissions="'kpiDashboard.plots.chargersInFailure'">
                    <div 
                        class="plot-box"
                        *ngIf="(showChargersInFailure$ | async); else pollingOrFailure"
                        [@inAnimation]
                    >
                        <div class="plot-header">
                            <div>
                                <div 
                                    class="title"
                                    [tooltip]="'KPI_VIEW.STATIONS_FAILURE_PLOT.TOOLTIP' | translate"
                                    toSide="top"
                                    size="small"
                                    textAlign="left"
                                >
                                    {{ 'KPI_VIEW.STATIONS_FAILURE_PLOT.TITLE' | translate }} <span class="info-icon"></span>
                                </div>
                                <div class="subtitle">{{ 'KPI_VIEW.STATIONS_FAILURE_PLOT.SUBLINE' | translate }}</div>
                            </div>
                            <div *ngIf="(kpiChargersInFailurePlot?.plotStatus$ | async) == 'ready'" class="total-amount">
                                @if (meanChargersInFailure > 0) {
                                    {{ 'KPI_VIEW.STATIONS_FAILURE_PLOT.AVERAGE' | translate: {n: (meanChargersInFailure.toFixed(0) | localizedNumber)} }}
                                } @else {
                                    {{ 'KPI_VIEW.STATIONS_FAILURE_PLOT.CALC_FAILED' | translate }}
                                }
                            </div>
                        </div>
                        <div
                            class="position-relative plot-wrapper"
                            #tooltipRef
                            *ngIf="(kpiChargersInFailurePlot?.plotStatus$ | async) == 'ready'; else noData"
                        >
                            <plotly-plot
                                [data]="(kpiChargersInFailurePlot?.plotData$ | async) || []"
                                [layout]="(kpiChargersInFailurePlot?.layout$ | async) || {}"
                                [config]="kpiChargersInFailurePlot?.plotConfig"
                                [updateOnlyWithRevision]="true"
                                [revision]="(kpiChargersInFailurePlot?.plotRevision$ | async) || 0"
                                [useResizeHandler]="false"
                                [style]="{position: 'relative', width: '470px', height: '380px'}"
                                (doubleClick)="kpiChargersInFailurePlot?.onDoubleClick()"
                                (hover)="this.plotTooltips.renderTooltip($event, tooltipRef, 'small')"
                                (unhover)="this.plotTooltips.removeTooltip()"
                                (relayout)="onRelayout($event)"
                            >
                            </plotly-plot>
                        </div>
                    </div>
                </ng-container>

                <ng-container *evcHasPermissions="'kpiDashboard.plots.chargedAmount'">
                    <div 
                        class="plot-box"
                        *ngIf="(showChargedAmount$ | async); else pollingOrFailure"
                        [@inAnimation]
                    >
                        <div class="plot-header">
                            <div>
                                <div class="title">
                                    {{ 'KPI_VIEW.CHARGED_AMOUNT_PLOT.TITLE' | translate }}
                                </div>
                                <div class="subtitle">{{ 'KPI_VIEW.CHARGED_AMOUNT_PLOT.SUBLINE' | translate }}</div>
                            </div>
                            <div *ngIf="(kpiChargedAmountPlot?.plotStatus$ | async) == 'ready'" class="total-amount">
                                @if (meanChargedAmount > 0) {
                                    {{ 'KPI_VIEW.CHARGED_AMOUNT_PLOT.AVERAGE' | translate: {n: (meanChargedAmount | localizedNumber)} }}
                                } @else {
                                    {{ 'KPI_VIEW.CHARGED_AMOUNT_PLOT.CALC_FAILED' | translate }}
                                }
                            </div>
                        </div>
                        <div
                            class="position-relative plot-wrapper"
                            #tooltipRef
                            *ngIf="(kpiChargedAmountPlot?.plotStatus$ | async) == 'ready'; else noData"
                        >
                            <plotly-plot
                                [data]="(kpiChargedAmountPlot?.plotData$ | async) || []"
                                [layout]="(kpiChargedAmountPlot?.layout$ | async) || {}"
                                [config]="kpiChargedAmountPlot?.plotConfig"
                                [updateOnlyWithRevision]="true"
                                [revision]="(kpiChargedAmountPlot?.plotRevision$ | async) || 0"
                                [useResizeHandler]="false"
                                [style]="{position: 'relative', width: '470px', height: '380px'}"
                                (doubleClick)="kpiChargedAmountPlot?.onDoubleClick()"
                                (hover)="this.plotTooltips.renderTooltip($event, tooltipRef, 'small')"
                                (unhover)="this.plotTooltips.removeTooltip()"
                                (relayout)="onRelayout($event)"
                            >
                            </plotly-plot>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-template #pollingOrFailure>
            <div *ngIf="!(errorPollData$ | async)" class="text-center">
                <div class="loading-box">
                    <app-preloader></app-preloader>
                </div>
            </div>
            <div *ngIf="(errorPollData$ | async)" class="flex-row text-center align-items-center justify-content-center p-32 h-300">
                <div class="material-icon default-icon mr-16">warning</div>
                <p class="subheadline">{{ 'APP_ERRORS.NO_DATA_AVAILABLE' | translate }}</p>
            </div>
        </ng-template>
        <ng-template #noData>
            <div class="flex-row text-center align-items-center justify-content-center p-32 h-300">
                <div class="material-icon default-icon mr-16">warning</div>
                <p class="subheadline">{{ 'APP_ERRORS.NO_DATA_AVAILABLE' | translate }}</p>
            </div>
        </ng-template>
    `,
    styleUrls: ['./kpi-plots.component.scss'],
    animations: [
        trigger('inAnimation', [
            transition(':enter', [
                    useAnimation(inAnimation)
                ]
            )
        ])
    ]
})
export class KpiPlotsComponent implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();

    // subject that receives charging sessions plot data
    chargingSessionsPlotData$: Subject<{ date: string; value: number; }[]> = new Subject<{ date: string; value: number; }[]>()
    // subject that receives successful sessions plot data
    successfulSessionsPlotData$: Subject<{ date: string; value: number; }[]> = new Subject<{ date: string; value: number; }[]>()
    // subject that receives chargers in failure plot data
    chargersInFailurePlotData$: Subject<{ date: string; value: number; }[]> = new Subject<{ date: string; value: number; }[]>();
    // subject that receives charged amount
    chargedAmountPlotData$: Subject<{ date: string; value: number; }[]> = new Subject<{ date: string; value: number; }[]>();

    // extra info on top of the corresponding plot
    totalChargingSessions: number = 0;
    meanSuccessfulSessions: number = 0;
    meanChargersInFailure: number = 0;
    meanChargedAmount: number = 0;
    
    // charging sessions plot service
    kpiChargingSessionsPlot: KpiPlotsService | undefined = undefined;
    // successful sessions plot service
    kpiSuccessfulSessionsPlot: KpiPlotsService | undefined = undefined;
    // chargers in failure plot service
    kpiChargersInFailurePlot: KpiPlotsService | undefined = undefined;
    // charged amount plot service
    kpiChargedAmountPlot: KpiPlotsService | undefined;

    // whether the plots shall be displayed or not
    showChargingSessions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showSuccessfulSessions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showChargersInFailure$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showChargedAmount$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // error when receiving data
    errorPollData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // current daterange
    currentDateRange!: DateRange;
    // interval
    interval!: number;

    constructor(
        private _dashboardService: DashboardService,
        private _notificationService: NotificationService,
        private _permService: PermissionsService,
        private _appRepo: appRepository,
        public detailsRepo: detailsRepository,
        public overviewRepo: overviewRepository,
        public plotTooltips: PlotTooltipsService,
        public filtersRepo: stationFiltersRepository
    ) {

        // get plot data | listening to filter and daterange updates
        combineLatest([
            this.detailsRepo.dateRange$, 
            this.detailsRepo.interval$,
            this.filtersRepo.activeFilterValues$.pipe(
                map((filters) => filters.filter((filter) => filter.value !== undefined && filter.value !== null && filter.value.length !== 0)),
                distinctUntilArrayItemChanged()
            ),
        ]).pipe(
            takeUntil(this._destroying$),
            withLatestFrom(combineLatest({
                permissions: _permService.userPermissions$,
                customer: _appRepo.selectedCustomer$
            })),
            filter(([combined, {customer}]) => customer !== null && customer.identifier !== null && customer.identifier !== ''),
            tap(([[dateRange, interval, activeFilters]]) => {
                let yesterday = new Date()
                let fromDate = dateRange.from
                yesterday.setDate(yesterday.getDate() - 1)
                yesterday.setHours(0,0,0,0)
                fromDate.setHours(0,0,0,0)
                this.currentDateRange = {from: fromDate, until: yesterday}
                this.interval = interval;
                this.showChargingSessions$.next(false);
                this.showSuccessfulSessions$.next(false);
                this.showChargersInFailure$.next(false);
                this.showChargedAmount$.next(false);
            }),
            switchMap(([[dateRange, interval, activeFilters], {permissions}]) => {
                const query = this.queryBuilder(activeFilters, interval+1);
                const numSessionsCall$          = _permService.checkPermissions('kpiDashboard.plots.chargingSessions', permissions) || _permService.checkPermissions('kpiDashboard.plots.successfulSessions', permissions)
                    ? _dashboardService.getNumSessions(query)
                    : of([]);
                const chargersInFailureCall$    = _permService.checkPermissions('kpiDashboard.plots.chargersInFailure', permissions) 
                    ? _dashboardService.getChartChargersInFailure(query)
                    : of([]);
                const chargedAmountCall$        = _permService.checkPermissions('kpiDashboard.plots.chargedAmount', permissions) 
                    ? _dashboardService.getChartChargedAmount(query)
                    : of([]);
                return forkJoin([numSessionsCall$, chargersInFailureCall$, chargedAmountCall$]).pipe(
                    map(([numSessionsData, chargersInFailureData, chargedAmountData]) => {
                        numSessionsData = numSessionsData.filter(entry => entry.date.substring(0,10) !== new Date().toISOString().substring(0,10));
                        chargersInFailureData = chargersInFailureData.filter(entry => entry.date.substring(0,10) !== new Date().toISOString().substring(0,10));
                        const chargingSessions = numSessionsData.map(entry => { return { date: entry.date, value: entry.tot_count } } );
                        const successfulSessions = numSessionsData.map(entry => { return { date: entry.date, value: entry.regular + (entry.in_progress ?? 0)} } );
                        const chargersInFailure = chargersInFailureData.map(entry => { return { date: entry.date, value: entry.n_chargers } } );
                        const chargedAmount = chargedAmountData.map(entry => {return {date: entry.date, value: entry.charged_amount}})
                        this.chargingSessionsPlotData$.next(chargingSessions);
                        this.successfulSessionsPlotData$.next(successfulSessions);
                        this.chargersInFailurePlotData$.next(chargersInFailure);
                        this.chargedAmountPlotData$.next(chargedAmount)
                        this.errorPollData$.next(false)
                    }),
                    catchError(_ => {
                        this._notificationService.showError('Error could not retrieve charts KPI data')
                        this.errorPollData$.next(true)
                        return of()
                    })
                )
            })
        ).subscribe();

        // charging sessions plot
        this.chargingSessionsPlotData$.pipe(
            takeUntil(this._destroying$),
            tap((chargingSessions) => {
                if (this.kpiChargingSessionsPlot === undefined) {
                    this.kpiChargingSessionsPlot = new KpiPlotsService(
                        'kpi-charging-sessions',
                        this.currentDateRange,
                        this.interval,
                        chargingSessions,
                        {
                            fixedY: true,
                            tickformat: ''
                        }
                    );
                }
                this.kpiChargingSessionsPlot.dateRange = this.currentDateRange;
                this.kpiChargingSessionsPlot.interval = this.interval;
                this.kpiChargingSessionsPlot.dataToDisplay = chargingSessions;
                this.totalChargingSessions = chargingSessions.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.value;
                }, 0);
            }),
            tap((_) => {
                this.showChargingSessions$.next(true);
            })
        ).subscribe();

        // successful sessions plot
        zip ([
            this.successfulSessionsPlotData$,
            this.chargingSessionsPlotData$
        ]).pipe(
            takeUntil(this._destroying$),
            tap(([successfulSessions, chargingSessions]) => {
                const pctSuccessfulSessions = successfulSessions.map((item, index) => ({
                    date: item.date,
                    value: (chargingSessions[index].value == 0 ? 0 : 
                        item.value / chargingSessions[index].value)
                }));                  
                if (this.kpiSuccessfulSessionsPlot === undefined) {
                    this.kpiSuccessfulSessionsPlot = new KpiPlotsService(
                        'kpi-successful-sessions',
                        this.currentDateRange,
                        this.interval,
                        pctSuccessfulSessions, 
                        {
                            fixedY: true,
                            tickformat: '.0%',
                            yaxisrange: [0, 1]
                        },
                        successfulSessions
                    );
                }
                this.kpiSuccessfulSessionsPlot.tooltipData = successfulSessions;
                this.kpiSuccessfulSessionsPlot.dateRange = this.currentDateRange;
                this.kpiSuccessfulSessionsPlot.interval = this.interval;
                this.kpiSuccessfulSessionsPlot.dataToDisplay = pctSuccessfulSessions;
                let sum = pctSuccessfulSessions.reduce((acc, curr) => acc + curr.value, 0);
                this.meanSuccessfulSessions = sum / pctSuccessfulSessions.length;
            }), 
            tap((_) => {
                this.showSuccessfulSessions$.next(true);
            })
        ).subscribe();

        // chargers in failure plot
        this.chargersInFailurePlotData$.pipe(
            takeUntil(this._destroying$),
            tap((chargersInFailure) => {
                const filteredChargersInFailure = Object.values(chargersInFailure)
                    .filter(entry => entry.value !== 0);
                if (this.kpiChargersInFailurePlot === undefined) {
                    this.kpiChargersInFailurePlot = new KpiPlotsService(
                        'kpi-chargers-in-failure',
                        this.currentDateRange,
                        this.interval,
                        filteredChargersInFailure, 
                        {
                            fixedY: true,
                            tickformat: ''
                        }
                    );
                }
                this.kpiChargersInFailurePlot.dateRange = this.currentDateRange;
                this.kpiChargersInFailurePlot.interval = this.interval;
                this.kpiChargersInFailurePlot.dataToDisplay = filteredChargersInFailure;
                let sum = filteredChargersInFailure.reduce((acc, curr) => acc + curr.value, 0);
                this.meanChargersInFailure = sum / filteredChargersInFailure.length;
            }),
            tap((_) => {
                this.showChargersInFailure$.next(true);
            })
        ).subscribe();

        // charged amount plot
        this.chargedAmountPlotData$.pipe(
            takeUntil(this._destroying$),
            tap((chargedAmount) => {
                
                if (this.kpiChargedAmountPlot === undefined) {
                    this.kpiChargedAmountPlot = new KpiPlotsService(
                        'kpi-charged-amount',
                        this.currentDateRange,
                        this.interval,
                        chargedAmount, 
                        {
                            fixedY: true,
                            tickformat: ''
                        }
                    );
                }
                this.kpiChargedAmountPlot.dateRange = this.currentDateRange;
                this.kpiChargedAmountPlot.interval = this.interval;
                this.kpiChargedAmountPlot.dataToDisplay = chargedAmount;
                let sum = chargedAmount.reduce((acc, curr) => acc + curr.value, 0);
                this.meanChargedAmount = Math.round((sum / chargedAmount.length) / 1000);
            }),
            tap((_) => {
                this.showChargedAmount$.next(true);
            })
        ).subscribe()

    }

    queryBuilder(activeFilters: any[], interval: number) {
        let request: { [key: string]: any } = {};
        request["filters"] = filterQueryBuilder(activeFilters);
        request["interval"] = interval;
        return request
    }

    onRelayout(event: any) {
        // this indicates that the user manually zoomed into the plot
        if (event['xaxis.range[0]']) {
            this._notificationService.showInfo('Double-click to zoom back out');
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

}
