/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FilterSet } from '../../models/filter-set';
import { SharedFilterSet } from '../../models/shared-filter-set';

export interface AddFilterSets$Params {
      body?: Array<{

/**
 * Whether the filter set should be shared or not
 */
'isShared': boolean;

/**
 * Name of the filter set
 */
'name': string;

/**
 * Definition of filter set in JSON format
 */
'filterSetDefinition': Array<{
'id'?: string;
'value'?: (string | Array<any>);
}>;

/**
 * Tenant associated with the shared filter set
 */
'tenant'?: string;
}>
}

export function addFilterSets(http: HttpClient, rootUrl: string, params?: AddFilterSets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>> {
  const rb = new RequestBuilder(rootUrl, addFilterSets.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>;
    })
  );
}

addFilterSets.PATH = '/filterSets/';
