/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addRole } from '../fn/admin/add-role';
import { AddRole$Params } from '../fn/admin/add-role';
import { addTenant } from '../fn/admin/add-tenant';
import { AddTenant$Params } from '../fn/admin/add-tenant';
import { addTenantsToUsers } from '../fn/admin/add-tenants-to-users';
import { AddTenantsToUsers$Params } from '../fn/admin/add-tenants-to-users';
import { ApiResponse } from '../models/api-response';
import { deleteUsers } from '../fn/admin/delete-users';
import { DeleteUsers$Params } from '../fn/admin/delete-users';
import { getTenants } from '../fn/admin/get-tenants';
import { GetTenants$Params } from '../fn/admin/get-tenants';
import { getUser } from '../fn/admin/get-user';
import { GetUser$Params } from '../fn/admin/get-user';
import { getUsers } from '../fn/admin/get-users';
import { GetUsers$Params } from '../fn/admin/get-users';
import { inviteUsers } from '../fn/admin/invite-users';
import { InviteUsers$Params } from '../fn/admin/invite-users';
import { removeRole } from '../fn/admin/remove-role';
import { RemoveRole$Params } from '../fn/admin/remove-role';
import { removeTenant } from '../fn/admin/remove-tenant';
import { RemoveTenant$Params } from '../fn/admin/remove-tenant';
import { removeTenantsFromUsers } from '../fn/admin/remove-tenants-from-users';
import { RemoveTenantsFromUsers$Params } from '../fn/admin/remove-tenants-from-users';
import { Tenant } from '../models/tenant';
import { updateUser } from '../fn/admin/update-user';
import { UpdateUser$Params } from '../fn/admin/update-user';
import { User } from '../models/user';


/**
 * User and Tenant Administration
 */
@Injectable({ providedIn: 'root' })
export class AdminService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/admin/users/';

  /**
   * Return list of users.
   *
   * Return a list of all users with their roles and tenants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<User>>> {
    return getUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Return list of users.
   *
   * Return a list of all users with their roles and tenants
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: GetUsers$Params, context?: HttpContext): Observable<Array<User>> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<User>>): Array<User> => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/admin/users/{uuid}/';

  /**
   * Return a single users.
   *
   * Return a list of all users with their roles and tenants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Return a single users.
   *
   * Return a list of all users with their roles and tenants
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: GetUser$Params, context?: HttpContext): Observable<User> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/admin/users/{uuid}/';

  /**
   * Update a single users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: UpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return updateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a single users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: UpdateUser$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.updateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `deleteUsers()` */
  static readonly DeleteUsersPath = '/admin/users/delete/';

  /**
   * Delete a list of users by uuids.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUsers$Response(params?: DeleteUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return deleteUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a list of users by uuids.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUsers(params?: DeleteUsers$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.deleteUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `inviteUsers()` */
  static readonly InviteUsersPath = '/admin/users/invite/';

  /**
   * Invite a list of users by mail address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inviteUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUsers$Response(params?: InviteUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return inviteUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Invite a list of users by mail address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inviteUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inviteUsers(params?: InviteUsers$Params, context?: HttpContext): Observable<string> {
    return this.inviteUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `addTenant()` */
  static readonly AddTenantPath = '/admin/users/addTenant/';

  /**
   * Add a tenant for a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTenant$Response(params?: AddTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return addTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a tenant for a list of users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTenant(params?: AddTenant$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.addTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `removeTenant()` */
  static readonly RemoveTenantPath = '/admin/users/removeTenant/';

  /**
   * Remove a tenant for a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeTenant$Response(params?: RemoveTenant$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return removeTenant(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove a tenant for a list of users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeTenant(params?: RemoveTenant$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.removeTenant$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `addRole()` */
  static readonly AddRolePath = '/admin/users/addRole/';

  /**
   * Add a role for a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRole$Response(params?: AddRole$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return addRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a role for a list of users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addRole(params?: AddRole$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.addRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `removeRole()` */
  static readonly RemoveRolePath = '/admin/users/removeRole/';

  /**
   * Remove a role for a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeRole$Response(params?: RemoveRole$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return removeRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove a role for a list of users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeRole(params?: RemoveRole$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.removeRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `addTenantsToUsers()` */
  static readonly AddTenantsToUsersPath = '/admin/users/addTenantsToUsers/';

  /**
   * Add a list of tenants to a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTenantsToUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTenantsToUsers$Response(params?: AddTenantsToUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return addTenantsToUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a list of tenants to a list of users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTenantsToUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addTenantsToUsers(params?: AddTenantsToUsers$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.addTenantsToUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `removeTenantsFromUsers()` */
  static readonly RemoveTenantsFromUsersPath = '/admin/users/removeTenantsFromUsers/';

  /**
   * Remove a list if tenants from a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTenantsFromUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeTenantsFromUsers$Response(params?: RemoveTenantsFromUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return removeTenantsFromUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove a list if tenants from a list of users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeTenantsFromUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  removeTenantsFromUsers(params?: RemoveTenantsFromUsers$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.removeTenantsFromUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `getTenants()` */
  static readonly GetTenantsPath = '/admin/tenants/';

  /**
   * Return list of tenants.
   *
   * Return a list of all tenants with their users and charger count
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants$Response(params?: GetTenants$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Tenant>>> {
    return getTenants(this.http, this.rootUrl, params, context);
  }

  /**
   * Return list of tenants.
   *
   * Return a list of all tenants with their users and charger count
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants(params?: GetTenants$Params, context?: HttpContext): Observable<Array<Tenant>> {
    return this.getTenants$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Tenant>>): Array<Tenant> => r.body)
    );
  }

}
