/* tslint:disable */
/* eslint-disable */
/* 
 * Code generated by ng-openapi-gen.
 * Based on handlebars/data-backend/module.handlebars
 * DO NOT EDIT.
 */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataApiConfiguration } from './data-api-configuration';

import { StationService } from './services/station.service';
import { DashboardService } from './services/dashboard.service';
import { AnalyticsService } from './services/analytics.service';
import { FilterService } from './services/filter.service';
import { IncidentService } from './services/incident.service';
import { AdminService } from './services/admin.service';
import { UserService } from './services/user.service';
import { KeywordLoggingService } from './services/keyword-logging.service';
import { FilterSetService } from './services/filter-set.service';
import { AlertService } from './services/alert.service';
import { NotificationDataService } from './services/notification-data.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    StationService,
    DashboardService,
    AnalyticsService,
    FilterService,
    IncidentService,
    AdminService,
    UserService,
    KeywordLoggingService,
    FilterSetService,
    AlertService,
    NotificationDataService,
    DataApiConfiguration
  ],
})
export class DataApiModule {
  static forRoot(): ModuleWithProviders<DataApiModule> {
    return {
      ngModule: DataApiModule,
      providers: [
        {
          provide: DataApiConfiguration
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: DataApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('DataApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
