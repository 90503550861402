/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FilterOption } from '../models/filter-option';
import { getDetailedInformation } from '../fn/incident/get-detailed-information';
import { GetDetailedInformation$Params } from '../fn/incident/get-detailed-information';
import { getIncidentFilterOptions } from '../fn/incident/get-incident-filter-options';
import { GetIncidentFilterOptions$Params } from '../fn/incident/get-incident-filter-options';
import { getIncidentsWithFilters } from '../fn/incident/get-incidents-with-filters';
import { GetIncidentsWithFilters$Params } from '../fn/incident/get-incidents-with-filters';
import { getMetaData } from '../fn/incident/get-meta-data';
import { GetMetaData$Params } from '../fn/incident/get-meta-data';
import { Incident } from '../models/incident';
import { IncidentCountTotal } from '../models/incident-count-total';
import { ReasonCount } from '../models/reason-count';
import { resolveIncident } from '../fn/incident/resolve-incident';
import { ResolveIncident$Params } from '../fn/incident/resolve-incident';
import { SeverityCount } from '../models/severity-count';


/**
 * Incident Management
 */
@Injectable({ providedIn: 'root' })
export class IncidentService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMetaData()` */
  static readonly GetMetaDataPath = '/incidents/getMetaData';

  /**
   * Gets meta info about incidents.
   *
   * Number of incidents, Counts of severity, Counts of reason
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMetaData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaData$Response(params?: GetMetaData$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'listSeverityCount'?: Array<SeverityCount>;
'listReasonCount'?: Array<ReasonCount>;
'incidentCountTotal'?: IncidentCountTotal;
}>> {
    return getMetaData(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets meta info about incidents.
   *
   * Number of incidents, Counts of severity, Counts of reason
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMetaData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMetaData(params?: GetMetaData$Params, context?: HttpContext): Observable<{
'listSeverityCount'?: Array<SeverityCount>;
'listReasonCount'?: Array<ReasonCount>;
'incidentCountTotal'?: IncidentCountTotal;
}> {
    return this.getMetaData$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'listSeverityCount'?: Array<SeverityCount>;
'listReasonCount'?: Array<ReasonCount>;
'incidentCountTotal'?: IncidentCountTotal;
}>): {
'listSeverityCount'?: Array<SeverityCount>;
'listReasonCount'?: Array<ReasonCount>;
'incidentCountTotal'?: IncidentCountTotal;
} => r.body)
    );
  }

  /** Path part for operation `getDetailedInformation()` */
  static readonly GetDetailedInformationPath = '/incidents/getDetailedInformation';

  /**
   * Get detailed information about an incident.
   *
   * Fetches information for the details page.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDetailedInformation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedInformation$Response(params?: GetDetailedInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<Incident & {

/**
 * Success of the last restart
 */
'isLastRestartSuccessful'?: boolean;

/**
 * Prioirity of the charger
 */
'chargerPriority'?: 'Low' | 'Medium' | 'High';

/**
 * Recommendation on the incident
 */
'incidentRecommendation'?: string;

/**
 * Timestamp of last restart attempt
 */
'lastRestartAttemptDate'?: string;

/**
 * Timestamp of last successful restart
 */
'lastSuccessfulRestartDate'?: string;

/**
 * Date and time of last regular charging session
 */
'lastRegularChargingDate'?: string;

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
}>> {
    return getDetailedInformation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get detailed information about an incident.
   *
   * Fetches information for the details page.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDetailedInformation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDetailedInformation(params?: GetDetailedInformation$Params, context?: HttpContext): Observable<Incident & {

/**
 * Success of the last restart
 */
'isLastRestartSuccessful'?: boolean;

/**
 * Prioirity of the charger
 */
'chargerPriority'?: 'Low' | 'Medium' | 'High';

/**
 * Recommendation on the incident
 */
'incidentRecommendation'?: string;

/**
 * Timestamp of last restart attempt
 */
'lastRestartAttemptDate'?: string;

/**
 * Timestamp of last successful restart
 */
'lastSuccessfulRestartDate'?: string;

/**
 * Date and time of last regular charging session
 */
'lastRegularChargingDate'?: string;

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
}> {
    return this.getDetailedInformation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Incident & {

/**
 * Success of the last restart
 */
'isLastRestartSuccessful'?: boolean;

/**
 * Prioirity of the charger
 */
'chargerPriority'?: 'Low' | 'Medium' | 'High';

/**
 * Recommendation on the incident
 */
'incidentRecommendation'?: string;

/**
 * Timestamp of last restart attempt
 */
'lastRestartAttemptDate'?: string;

/**
 * Timestamp of last successful restart
 */
'lastSuccessfulRestartDate'?: string;

/**
 * Date and time of last regular charging session
 */
'lastRegularChargingDate'?: string;

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
}>): Incident & {

/**
 * Success of the last restart
 */
'isLastRestartSuccessful'?: boolean;

/**
 * Prioirity of the charger
 */
'chargerPriority'?: 'Low' | 'Medium' | 'High';

/**
 * Recommendation on the incident
 */
'incidentRecommendation'?: string;

/**
 * Timestamp of last restart attempt
 */
'lastRestartAttemptDate'?: string;

/**
 * Timestamp of last successful restart
 */
'lastSuccessfulRestartDate'?: string;

/**
 * Date and time of last regular charging session
 */
'lastRegularChargingDate'?: string;

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
} => r.body)
    );
  }

  /** Path part for operation `getIncidentsWithFilters()` */
  static readonly GetIncidentsWithFiltersPath = '/incidents/getIncidentsWithFilters';

  /**
   * Gets incidents with station information.
   *
   * Fetch incidents with station infromation for a certain client
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIncidentsWithFilters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentsWithFilters$Response(params?: GetIncidentsWithFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Incident & {

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
}>>> {
    return getIncidentsWithFilters(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets incidents with station information.
   *
   * Fetch incidents with station infromation for a certain client
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIncidentsWithFilters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentsWithFilters(params?: GetIncidentsWithFilters$Params, context?: HttpContext): Observable<Array<Incident & {

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
}>> {
    return this.getIncidentsWithFilters$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Incident & {

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
}>>): Array<Incident & {

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
}> => r.body)
    );
  }

  /** Path part for operation `getIncidentFilterOptions()` */
  static readonly GetIncidentFilterOptionsPath = '/incidents/getFilterOptions';

  /**
   * Gets filter options for incidents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIncidentFilterOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentFilterOptions$Response(params?: GetIncidentFilterOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'filterOptions'?: Array<FilterOption>;
}>> {
    return getIncidentFilterOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets filter options for incidents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getIncidentFilterOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIncidentFilterOptions(params?: GetIncidentFilterOptions$Params, context?: HttpContext): Observable<{
'filterOptions'?: Array<FilterOption>;
}> {
    return this.getIncidentFilterOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'filterOptions'?: Array<FilterOption>;
}>): {
'filterOptions'?: Array<FilterOption>;
} => r.body)
    );
  }

  /** Path part for operation `resolveIncident()` */
  static readonly ResolveIncidentPath = '/incidents/resolveIncident';

  /**
   * Set incident to manually resolved.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resolveIncident()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveIncident$Response(params?: ResolveIncident$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return resolveIncident(this.http, this.rootUrl, params, context);
  }

  /**
   * Set incident to manually resolved.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resolveIncident$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resolveIncident(params?: ResolveIncident$Params, context?: HttpContext): Observable<void> {
    return this.resolveIncident$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
