/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Incident } from '../../models/incident';

export interface GetIncidentsWithFilters$Params {

/**
 * Page number for pagination
 */
  page?: number;

/**
 * Number of items per page
 */
  pageSize?: number;

/**
 * Sorting criteria in the format `field:direction`. Multiple sorts are be separated by commas, e.g., `state:asc,stationId:desc` similar to opensearch.
 */
  sort?: string;

/**
 * Filters in the format `field:value`. Multiple filters can be passed by separating them with commas, e.g., `state:ok,reason:error` similar to opensearch. Filter values can be achieved by the [getMetaData](#/incidents/getMetaData) request.
 */
  filters?: string;

/**
 * Whether to return only open incidents. If not set, all incidents are returned.
 */
  isOpen?: boolean;

/**
 * Whether to return only incidents with action required, implies isOpen=true. If not set, all incidents are returned.
 */
  actionRequired?: boolean;

/**
 * The period in days before 'date' from which all incidents should be returned. Defaults to 14 (days).
 */
  interval?: number;

/**
 * Time at which the incidents should be displayed, by lastChangedAt. Defaults to now.
 */
  date?: string;
}

export function getIncidentsWithFilters(http: HttpClient, rootUrl: string, params?: GetIncidentsWithFilters$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Incident & {

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
}>>> {
  const rb = new RequestBuilder(rootUrl, getIncidentsWithFilters.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('pageSize', params.pageSize, {});
    rb.query('sort', params.sort, {});
    rb.query('filters', params.filters, {});
    rb.query('isOpen', params.isOpen, {});
    rb.query('actionRequired', params.actionRequired, {});
    rb.query('interval', params.interval, {});
    rb.query('date', params.date, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<Incident & {
      
      /**
       * The model of the station
       */
      'chargerModel'?: string;
      
      /**
       * CPO name
       */
      'cpoName'?: string;
      
      /**
       * status of the station's sim card
       */
      'simCardStatus'?: 'online' | 'offline' | 'none';
      
      /**
       * Timestamp of last change in the latest ticket of the charging station
       */
      'ticketModifiedOn'?: string;
      
      /**
       * The EVSE-ID of the connector
       */
      'evseId'?: string;
      
      /**
       * Name of the EVSE
       */
      'evseName'?: string;
      
      /**
       * The plug type of the connector
       */
      'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';
      
      /**
       * The current type of the connector. AC: Alternating Current DC: Direct Current
       */
      'currentType'?: 'AC' | 'DC';
      
      /**
       * Last known calculated overall state of connector
       */
      'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';
      
      /**
       * Last known error of connector or None
       */
      'lastError'?: string;
      
      /**
       * Date and time of last error
       */
      'lastErrorDate'?: string;
      } & {
      }>>;
    })
  );
}

getIncidentsWithFilters.PATH = '/incidents/getIncidentsWithFilters';
