import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, input, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, viewChild } from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    iif,
    map,
    Observable,
    scan,
    shareReplay,
    startWith,
    Subject,
    take,
    takeUntil,
    tap,
    throttleTime,
    withLatestFrom,
} from "rxjs";
import { TableColumnHeader } from "../table-header/table-header.component";
import { isWithinInterval } from "date-fns/esm";
import { Sort } from "src/app/core/helpers/sort";
import { tablesRepository } from "src/app/core/stores/tables.repository";
import { formatDate } from "@angular/common";
import { animate, style, transition, trigger } from "@angular/animations";
import { StateHelperService } from "src/app/core/helpers/state-helper.service";
import { Filter } from "src/app/core/stores/station-filters.repository";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateService } from "@ngx-translate/core";
import { BulkSelectAction } from "../table-bulk-panel/table-bulk-panel.component";
import { RowMeta } from "./table-row/table-row.component";

export type TooltipConfig = {
    key?: string;
    text?: string;
    // TODO: Update to proper configurable type, get rid of max-{n} stuff
    type?: 'max-40' | 'max-60' | 'max-80' | 'max-100' | 'fixable-by' | 'autorestart' | 'category' | 'persistent' | 'activated';
    toSide?: 'top' | 'right' | 'bottom' | 'left';
    textAlign?: 'center' | 'left';
    size?: 'large' | 'small';
    width?: number;
}

type RowInfo = {
    isSubrow?: boolean
    isGroup?: boolean
    groupIndex?: number
    groupSize?: number
    sortBy?: string
    sortDirection?: 'asc' | 'desc'
}

type TableResultMatrix = {
    isGrouped: false
    matrix: [RowMeta, ...RowContent[]][]
} | {
    isGrouped: true
    matrix: [RowMeta, ...RowContent[]][][]
}

export interface TableColumn {
    id: number;
    title: string;
    keys?: {
        key: string,
        title: string,
        // predefined type is needed for searching and filtering
        // Type enum currently only supports the type EnumeratedState, which is commonly used throughout the app.
        // Might be extended in a future update to handle custom enums
        type: 'string' | 'date' | 'number' | 'boolean' | 'array' | 'enum',
        // make the key not filterable / searchable / sortable
        hidden?: boolean,
    }[];
    renderCell?: (values: any[], meta?: RowInfo) => string;
    renderRef?: TemplateRef<any>; // TemplateRef allows for rendering of complex cells. Removes wrappers and padding
    config?: {
        leading?: boolean;
        trailing?: boolean;
        noMinWidth?: boolean;
        defaultWidth?: number;
        noFilter?: boolean;
        noSort?: boolean;
        noSearch?: boolean;
        squished?: 'left' | 'right';
        icon?: {
            type: string;
            color: string;
            size: 'default' | 'large';
            only: boolean;
        };
        hoverInfo?: string;
        tooltip?: TooltipConfig;
        hideWhenEmpty?: boolean;
    };
    actions?: TableCellAction[]
}


type BaseTableCellAction = {
    id: string,
    title: string | ((attr: any[]) => string),
    icon?: string | ((attr: any[]) => string),
    condition?: boolean | ((attr: any[]) => boolean),
    renderAction?: (attr: any[]) => string
}

type TableCellActionWithIcon = Pick<BaseTableCellAction, Exclude<keyof BaseTableCellAction, 'renderAction'>> & { icon: string | ((attr: any[]) => string) };
type TableCellActionWithRenderAction = Pick<BaseTableCellAction, Exclude<keyof BaseTableCellAction, 'icon'>> & { renderAction: (attr: any) => string };

type TableCellAction = TableCellActionWithIcon | TableCellActionWithRenderAction;

export type TableCellActionEvent = {actionId: string, row: number | string}
export type SubTableCellActionEvent = {actionId: string, subTableContent: Record<string, any>}

export interface TableHeaderVM {
    columnHeader: TableColumnHeader;
    column: TableColumn;
    columnSearch: string | null;
    filters: Filter[] | null;
    columnWidth: number | null;
    className: string;
}

type ResolvedTableCellAction = Omit<TableCellAction, 'title' | 'icon' | 'condition'> & { 
    title: string
    icon: string
    label: string
    condition?: boolean
 };

export type RowContent = {
    label: string
    value: (string | number | boolean | Date)[]
    tooltip?: TooltipConfig
    actions?: ResolvedTableCellAction[]
    renderRef?: TableColumn['renderRef']
}

@Component({
    selector: 'evc-table',
    template: `
        <div
            class="table-cont"
            #tableCont
            (resized)="layoutTable()"
        >
            <div class="overlay"></div>
            @if (tableState$ | async; as tableState) {
                @if (enrichedScroll && showScrollAbove && tableOverflowsX) {
                    <div class="scrollbar-wrapper-x top">
                        <div
                            class="sync-scroll-x"
                            (scroll)="syncScroll(scrollAbove, 'x')"
                            (mouseenter)="setScrollSource($event)" 
                            (mouseleave)="setScrollSource($event)" 
                            #scrollAbove
                        >
                            <div [style.width]="(tableWidth$ | async) + 'px'"></div>
                        </div>
                    </div>
                }
            }
            <div 
                class="table-wrapper"
                (scroll)="syncScroll(tableWrapper, 'both')"
                (mouseenter)="setScrollSource($event)" 
                (mouseleave)="setScrollSource($event)" 
                (window:resize)="updateTableWrapperRect(); layoutTable();"
                [class.no-scrolling]="!scrollable"
                [class.box-shadow-rows]="boxShadow"
                #tableWrapper
            >
                <table 
                    [class.resizeable]="resizeable"
                    #tableRef
                    (resized)="layoutTable()"
                >
                    <tbody class="sticky-top">
                        <tr class="table-header-row">
                            <!-- optional table header for bulk actions -->
                            <th
                                *ngIf="((bulkActionOptions$ | async) || []).length > 0"
                                class="leading"
                            >
                            </th>
                            <!-- main table headers -->
                            @for (header of tableHeaders$ | async; track $index) {
                                <th
                                    *ngIf="resizeable"
                                    [class.has-min-width]="!header.column.config?.noMinWidth"
                                    [class.leading]="header.column.config?.leading"
                                    [class.trailing]="header.column.config?.trailing"
                                    [style.width.px]="header.column.config?.defaultWidth ? header.column.config?.defaultWidth : null"
                                    resizableColumn
                                    (columnWidth)="newColumnWidth$.next({columnId: header.column.id, width: $event})"
                                    [style.min-width.px]="header.columnWidth"
                                >
                                    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                                </th>

                                <th 
                                    *ngIf="!resizeable"
                                    [class.has-min-width]="!header.column.config?.noMinWidth"
                                    [class.leading]="header.column.config?.leading"
                                    [class.trailing]="header.column.config?.trailing"
                                    [style.width.px]="header.column.config?.defaultWidth ? header.column.config?.defaultWidth : null"
                                >
                                    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                                </th>

                                <!-- table header content -->
                                <ng-template #headerTemplate>
                                    <table-header
                                        [columnHeader]="header.columnHeader"
                                        [sortByKey]="sortBy$ | async"
                                        [sortOrder]="sortDirection$ | async"
                                        [isDragging]="false"
                                        [columnSearch]="header.columnSearch"
                                        [tableWrapper]="tableWrapper"
                                        [filters]="header.filters"
                                        [noSort]="header.column.config?.noSort"
                                        [noSearch]="header.column.config?.noSearch"
                                        [squished]="header.column.config?.squished"
                                        [icon]="header.column.config?.icon"
                                        [hoverInfo]="header.column.config?.hoverInfo"
                                        (onSort)="updateSortBy($event)"
                                        (onColumnSearch)="searchInColumn$.next({columnId: header.column.id, search: $event})"
                                        (onFilterSelection)="newFilterSelection$.next($event)"
                                        (onFilterReset)="newFilterSelection$.next({id: $event.id, value: undefined})"
                                    >
                                    </table-header>
                                </ng-template>
                            }
                            <!-- optional table header for subrow toggle / modal -->
                            <th
                                *ngIf="subrowPlaceholder || modal"
                                class="trailing"
                            >
                            </th>
                        </tr>
                    </tbody>
                    <ng-container *ngIf="!(tableState$ | async)?.showPreloader">
                        @if (tableMatrix$ | async; as tableMatrix) {
                            @if (tableMatrix.isGrouped) {
                                @for (group of tableMatrix.matrix; track $index) {
                                    @for (row of group; track i; let i = $index) {
                                        <tbody evc-table-row
                                            [row]="row"
                                            [tableHeaders]="tableHeaders$ | async"
                                            [groupIndex]="i"
                                            [groupSize]="group.length"
                                            [syncItem]="syncItem$ | async"
                                            [canSync]="syncActive !== null"
                                            [hasBulkActions]="((bulkActionOptions$ | async) || []).length > 0"
                                            [toggledBulkActions]="bulkActionRows$ | async"                                       
                                            [toggleSubrowIcons]="toggleSubrowIcons"
                                            [hasModal]="modal"
                                            [modalTemplateRef]="templateRef"
                                            (onBulkActionToggled)="toggleBulkAction$.next($event)"
                                            (onCellAction)="onCellAction.emit($event)"
                                            (onSubTableAction)="onSubTableAction($event.event, $event.subTable)"
                                            (onToggleSubrow)="toggleSubrow$.next($event)"
                                            (onSyncRow)="syncRow($event)"
                                        ></tbody>
                                    }
                                }
                            } @else {
                                @for (row of tableMatrix.matrix; track $index) {
                                    <tbody evc-table-row
                                        [row]="row"
                                        [tableHeaders]="tableHeaders$ | async"
                                        [syncItem]="syncItem$ | async"
                                        [canSync]="syncActive !== null"
                                        [hasBulkActions]="((bulkActionOptions$ | async) || []).length > 0"
                                        [toggledBulkActions]="bulkActionRows$ | async"                                       
                                        [toggleSubrowIcons]="toggleSubrowIcons"
                                        [hasModal]="modal"
                                        [modalTemplateRef]="templateRef"
                                        (onBulkActionToggled)="toggleBulkAction$.next($event)"
                                        (onCellAction)="onCellAction.emit($event)"
                                        (onSubTableAction)="onSubTableAction($event.event, $event.subTable)"
                                        (onToggleSubrow)="toggleSubrow$.next($event)"
                                        (onSyncRow)="syncRow($event)"
                                    ></tbody>
                                }
                            }
                        }
                    </ng-container>
                </table>

                @if (tableState$ | async; as tableState) {
                    @if (enrichedScroll && showScrollBeside && tableOverflowsY) {
                        <div class="scrollbar-wrapper-y">
                            <div
                                class="sync-scroll-y"
                                (scroll)="syncScroll(scrollBeside, 'y')"
                                (mouseenter)="setScrollSource($event)" 
                                (mouseleave)="setScrollSource($event)" 
                                #scrollBeside
                            >
                                <div [style.height]="(tableHeight$ | async) + 'px'"></div>
                            </div>
                        </div>
                    }
                }

            <!-- S T A T E S -->
            @if (tableState$ | async; as state) {
                @if (tableStateTemplateRef() !== null) {
                    <div class="state-container p-32">
                        <ng-container *ngTemplateOutlet="tableStateTemplateRef(); context: {$implicit: state.refInfo, message: state.message, icon: state.icon}"></ng-container>
                    </div>
                } @else {
                    @if (state.message || state.showPreloader) {
                        <div class="state-container p-32">
                            @if (state.message || state.icon) {
                                <div class="flex-row align-items-center justify-content-center">
                                    @if (state.icon) {
                                        <span class="material-icon">{{ state.icon }}</span>
                                    }
                                    @if (state.message) {
                                        <p class="p-16">{{ state.message }}</p>
                                    }
                                </div>
                            }
                            @if (state.showPreloader) {
                                <div class="text-center">
                                    <app-preloader [type]="'squares'"/>
                                </div>
                            }
                        </div>
                    }
                }
            }
            </div>
            @if (tableState$ | async; as tableState) {
                @if (enrichedScroll && showScrollBelow && tableOverflowsX) {
                    <div class="scrollbar-wrapper-x bottom">
                        <div
                            class="sync-scroll-x"
                            (scroll)="syncScroll(scrollBelow, 'x')"
                            (mouseenter)="setScrollSource($event)" 
                            (mouseleave)="setScrollSource($event)" 
                            #scrollBelow
                        >
                            <div [style.width]="(tableWidth$ | async) + 'px'"></div>
                        </div>
                    </div>
                }
            }
        </div>

        <!-- P A G I N A T I O N -->
        @if (maxPages > 1 && (page$ | async); as currentPage) {
            <div
                class="flex-row align-items-center justify-content-center pb-16 pt-16 pagination"
                [class.bulk-actions-toggled]="((bulkActionRows$ | async) ?? []).length > 0"
            >
                <button 
                    class="btn-chevron chevron-left" 
                    (click)="nextPage$.next(currentPage - 1)"
                    [disabled]="currentPage === 1"
                ></button>
                <input 
                    #inputRef
                    type="number"
                    min="1"
                    [max]="maxPages"
                    [value]="currentPage"
                    (input)="nextPage$.next(inputRef.valueAsNumber)"
                >
                <p class="copy pl-16"> {{ 'DASHBOARD.TABLE.OF_PAGES' | translate }} {{ maxPages }}</p>
                <button 
                    class="btn-chevron chevron-right" 
                    (click)="nextPage$.next(currentPage + 1)"
                    [disabled]="currentPage === maxPages"
                ></button>
            </div>
        }

        <!-- Bulk Actions -->
        @if (bulkActionsVM$ | async; as baVM) {
            <evc-table-bulk-panel
                [options]="baVM.actions"
                [selectedIds]="baVM.selectedRows"
                (selectedIdsChange)="setBulkActionRows($event)"
                (onAction)="emitBulkAction($event)"
            />
        }
    `,
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeInUpAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(calc(-100% + 10px))', opacity: 0 }),
                animate('.25s ease-out',
                    style({ transform: 'translateY(-100%)', opacity: 1 })
                )
            ]),
            transition(':leave', [
                style({ transform: 'translateY(-100%)', opacity: 1 }),
                animate('.25s ease-in',
                    style({ transform: 'translateY(calc(-100% + 10px))', opacity: 0 })
                )
            ])
        ]),
        trigger('changeHeight', [
            transition(':enter', [
                style({ height: '0px' }),
                animate('.2s ease-out', style({ height: '50px' }))
            ]),
            transition(':leave', [
                style({ height: '50px' }),
                animate('.2s ease-in', style({ height: '0px' }))
            ])
        ])
    ]
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    private readonly _destroying$ = new Subject<void>();
    // ElementRefs needed for synched scroll elements (enriched scroll)
    private _tableRef = viewChild<ElementRef>('tableRef')
    private _tableCont = viewChild<ElementRef>('tableCont');
    private _tableWrapper = viewChild<ElementRef>('tableWrapper');
    private _scrollAbove = viewChild<ElementRef>('scrollAbove');
    private _scrollBelow = viewChild<ElementRef>('scrollBelow');
    private _scrollBeside = viewChild<ElementRef>('scrollBeside');
    // current scroll positions
    public scrollLeft$ = new BehaviorSubject<number>(0);
    public scrollTop$ = new BehaviorSubject<number>(0);
    // dimensions of nested table
    tableHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    tableWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    // whether table overflows on x/y axis as indicator for scrollbar
    tableOverflowsX: boolean = false;
    tableOverflowsY: boolean = false;
    // dimensions of bounding wrapper
    tableWrapperRect: {wrapperStart: number, wrapperEnd: number} | undefined;
    // active scroll source element
    private _scrollSourceEl$ = new BehaviorSubject<Element | undefined>(undefined);
    // timer ref for Firefox scrollbar fix
    private _FFTimer: any;

    @ContentChild(TemplateRef) templateRef!: TemplateRef<any>;
    /* I N P U T S */
    // needs id to use state management
    @Input() tableId: string | undefined;
    // Columns
    public columns$ = new BehaviorSubject<TableColumn[]>([]);
    @Input() set columns(columns: TableColumn[] | null) {
        this.columns$.next(columns?.sort((a, b) => a.id - b.id) ?? []);
        // set initial sortBy (is overwritten when "defaultSortByKey" is set)
        if (columns && this.sortBy$.getValue() === '') {
            const columnKeys = columns.flatMap((column) => column.keys);
            if (!columnKeys || columnKeys.length == 0) return
            this.sortBy$.next(columnKeys[0]!.key)
        }
    }
    // set sortBy key
    @Input() set defaultSortByKey(key: string | null) {
        if (key) this.sortBy$.next(key)
    };
    // set sort direction
    @Input() set defaultSortDirection(direction: 'asc' | 'desc' | null) {
        if (direction) this.sortDirection$.next(direction)
    };
    // columns with additional attr
    public tableHeaders$: Observable<TableHeaderVM[]>;
    // Rows / Entries - subrows or subTable can be added to each row
    private _rows$ = new BehaviorSubject<Record<string, any & {
        subrows?: Record<string, any>,
        subTableEntries?: any[],
        subTableColumns?: TableColumn[],
        subTableConfig?: {
            fromCol: number,
            colSpan: number,
            defaultSortByKey?: string,
            defaultSortDirection?: 'asc' | 'desc'
        }
    }>[]>([]);
    /**
     * Array of table rows, properties mapped to keys of table columns. Add the "subrows" property to allow for 
     * collapsible subrows. The subrows should be an array of objects with the same keys as the main rows.
     * Add an "id" property to better reference entry when using bulk actions or to better control subrows toggled status when data changes.
     * "id" defaults to the index of the row in the array and will be overwritten with new data.
     */
    @Input() set rows(rows: Record<string, any & {
        subrows?: Record<string, any>
        id?: number
    }>[] | null) {
        // only reset toggled subrows if parent does not keep track of ids
        const rowsHaveId = rows && !rows.some((row) => row['id'] == undefined);
        if (!rowsHaveId) {
            // reset toggled bulk action and subrows
            this.setBulkActionRows$.next([]);
            this.toggleSubrow$.next(null);
        }
        // reset page to 1
        this.nextPage$.next(1);
        // set initial index as fixed id in this table
        const indexedRows = [...(rows ?? [])].map((row, index) => {
            row['_row_id'] = row['id'] != undefined ? row['id'] : index;
            return row
        });
        this._rows$.next(indexedRows);
    }
    /** optionally group entries by key of sortBy, resulting in tbody containing grouped rows */
    public groupBySortingKey = input<boolean>(false);
    /** matrix of formatted rows, containing either grouped (flag "isGrouped" = true) or single rows */
    public tableMatrix$: Observable<TableResultMatrix>;
    // column filters
    public filters$: Observable<Filter[] | null>;
    // sets new filter values
    public newFilterSelection$ = new BehaviorSubject<{id: string, value: any}>({id: '', value: null});
    // accumulates all selected filter values
    private _selectedFilterValues$: Observable<{[id: string]: any}>;
    // resizeable - whether columns can be resized
    @Input() resizeable: boolean = true;
    // scrollable - whether table can be scrolled or fit into parent
    @Input() scrollable: boolean = true;
    // enrichedScroll - will constantly show synced scroll bars selected sides of the tableWrapper
    @Input() enrichedScroll: boolean = false;
    // showScrollAbove - (enrichedScroll = true) will not show scroll bar above if set to false
    @Input() showScrollAbove: boolean = true;
    // showScrollBelow - (enrichedScroll = true) will not show scroll bar below if set to false
    @Input() showScrollBelow: boolean = true;
    // showScrollBeside - (enrichedScroll = true) will not show scroll bar beside if set to false
    @Input() showScrollBeside: boolean = true;
    // modal - will add a '+' at the end of each row with custom content
    @Input() modal: boolean = false;
    // bulkActions - array of available bulk actions, default none, rows can be selected if set
    public bulkActionOptions$ = new BehaviorSubject<BulkSelectAction[]>([]);
    @Input() set bulkActions(options: BulkSelectAction[] | null) {
        this.bulkActionOptions$.next(options ?? [])
    }
    // toggle state of single row
    public toggleBulkAction$ = new BehaviorSubject<number | string | null>(null);
    @Input() set toggleForBulkAction(id: number | string | null) {
        if (id == null) return
        this.toggleBulkAction$.next(id)
    }
    // overwrites all currently selected bulk action rows
    public setBulkActionRows$ = new BehaviorSubject<(number | string)[] | null>(null);
    @Input() set setForBulkAction(ids: (number | string)[] | null) {
        this.setBulkActionRows$.next(ids)
    }
    // all rows
    public bulkActionRows$: Observable<(number | string)[]>;
    // helps handling bulk action data in template
    public bulkActionsVM$: Observable<{
        actions: BulkSelectAction[],
        selectedRows: (number | string)[]
    }>;
    public showBulkActions: boolean = false;
    /**
     * Toggle subrows by row id. Set to null to reset all toggled subrows.
     */
    public toggleSubrow$ = new BehaviorSubject<number | null>(null);
    // whether a placeholder in the table header for subrows should be added
    public subrowPlaceholder: boolean = false;
    // all currently toggled subrows
    private _toggledSubrows$: Observable<number[]>;
    // set of icons to be displayed instead of the default chevron to toggle subrows [inactive, active]
    @Input() toggleSubrowIcons: [string, string] | null = null
    // state - 'loading' 'error' 'success'
    public parentState$ = new BehaviorSubject<'loading' | 'error' | 'success' | null>(null);
    @Input() set state(state: 'loading' | 'error' | 'success' | null) {
        this.parentState$.next(state)
    }
    // internal state (e.g. after filtering or sorting)
    private _tableState$: Observable<'empty' | 'success' | null>;
    // controls text, icon and preloader
    public tableState$: Observable<{
        icon?: string,
        message?: string,
        showPreloader?: boolean,
        // additional information for states of table, needed when table states are handled in parent component
        refInfo: {
            type: 'empty' | 'error' | 'loading' | null,
            hasSearch: boolean,
            hasFilters: boolean
        }
    } | null>;
    /** TemplateRef for handling table states in parent component */
    public tableStateTemplateRef = input<TemplateRef<any> | null>(null);
    // amount of entries per page, set to null to disable pagination
    @Input() perPage: number | null = 100;
    // requests new page
    public nextPage$ = new BehaviorSubject<number>(1);
    // current page
    public page$: Observable<number>;
    // sets the current page
    @Input() set currentPage(page: number | null) {
        if (page != null) this.nextPage$.next(page);
    }
    // whether items should be synced / table rows clickable
    @Input() syncActive: 'sessions' | 'errors' | null = null;
    // active items to sync between table and timeline
    public syncItem$ = new BehaviorSubject<number | null>(null);
    @Input() set syncItem(item: Record<string, any> | null) {
        if (!item) {
            this.syncItem$.next(null);
            return;
        }
        const toCompare = this.syncActive == 'sessions' 
            ? ['connectorId', 'startDate'] 
            : ['connectorId', 'date', 'errorCode', 'errorModelInterpretation'];
        const row = this._rows$.value.find(row =>
            toCompare.every(attr => row[attr] === item[attr])
        );
        this.syncItem$.next(row ? row['_row_id'] : null);
    }
    // max num of pages
    public maxPages: number = 1;
    // general search string
    private _searchQuery$ = new BehaviorSubject<string | null>(null)
    @Input() set searchQuery(search: string | null) {
        this._searchQuery$.next(search && search.length > 0 ? search : null)
    }
    // disable filter creation when rows > threshold
    @Input() disableFiltersThreshold: null | number = null;

    // adds box-shadow to each table row (+ slight padding to the container to show overflowing shadow)
    @Input() boxShadow: boolean = false;

    public searchInColumn$ = new BehaviorSubject<{columnId: TableColumn['id'], search: string | null} | null>(null);
    // holds all column widths, either updated directly or through table repo
    private _columnWidths$ = new BehaviorSubject<{[id: TableColumn['id']]: number | null}>({});
    public newColumnWidth$ = new BehaviorSubject<{columnId: TableColumn['id'], width: number | null}>({columnId: -1, width: null });
    // timer to keep track of when to renew layout
    private layoutTimer: ReturnType<typeof setTimeout> | null = null;

    /* O U T P U T S */

    // onBulkAction
    @Output() onBulkAction = new EventEmitter<{action: string, rows: (number | string)[]}>();
    // emits when new selection for bulk action is made
    @Output() onBulkActionSelectionChange = new EventEmitter<(number | string)[]>()
    // emits when elements in action cell are clicked
    @Output() onCellAction = new EventEmitter<TableCellActionEvent>();
    // emits when action elements of subtable are triggered
    @Output() onSubTableCellAction = new EventEmitter<SubTableCellActionEvent>();

    // emits when page changed
    @Output() currentPageChange = new EventEmitter<number>();
    // emits when row is selected (active)
    @Output() syncItemChange = new EventEmitter<Record<string, any> | null>();
    // emits all currently visible data in table after fitlers apply
    @Output() onNewDataFiltered = new EventEmitter<any[]>();


    public sortBy$ = new BehaviorSubject<string>('');
    public sortDirection$ = new BehaviorSubject<'asc' | 'desc'>('asc');

    constructor(
        public tablesRepo: tablesRepository,
        private _stateHelper: StateHelperService,
        private _translate: TranslateService,
        private _renderer: Renderer2
    ) {
        this.page$ = this.nextPage$.pipe(
            distinctUntilChanged(),
            map((newPage) => {
                // apply bounds (min 1, max = maxPages)
                newPage = newPage > this.maxPages 
                    ? this.maxPages 
                    : newPage < 1 
                        ? 1
                        : newPage;

                // reset to first page if NaN
                newPage = isNaN(newPage) ? 1 : newPage;

                // emit new page to parents
                this.currentPageChange.emit(newPage);

                return newPage
            }),
            shareReplay()
        )

        // collect all toggled rows for bulk actions
        this.bulkActionRows$ = this.toggleBulkAction$.pipe(
            scan(
                (all: (number | string)[], current: number | string | null) => {
                    if (current == null) return all
                    return all.includes(current)
                        ? all.filter(x => x !== current) // remove from array
                        : [...all, current]
                }, []
            ),
            tap(this.onBulkActionSelectionChange),
            shareReplay()
        )

        this.scrollLeft$.pipe(
            takeUntilDestroyed(),
            throttleTime(10),
            withLatestFrom(this._scrollSourceEl$),
            tap(([value, source]) => {
                if (!this.enrichedScroll) return;
                const scrollEls = [
                    this._scrollAbove()?.nativeElement,
                    this._tableWrapper()?.nativeElement,
                    this._scrollBeside()?.nativeElement,
                    this._scrollBelow()?.nativeElement
                ].filter(el => el && el !== source);
                scrollEls.forEach(el => el.scrollLeft = value);
            })
        ).subscribe();

        this.scrollTop$.pipe(
            takeUntilDestroyed(),
            throttleTime(10),
            withLatestFrom(this._scrollSourceEl$),
            tap(([value, source]) => {
                if (!this.enrichedScroll) return;
                const scrollEls = [
                    this._scrollAbove()?.nativeElement,
                    this._tableWrapper()?.nativeElement,
                    this._scrollBeside()?.nativeElement,
                    this._scrollBelow()?.nativeElement
                ].filter(el => el && el !== source);
                scrollEls.forEach(el => el.scrollTop = value);
            })
        ).subscribe();

        // handles set events
        this.setBulkActionRows$.pipe(
            takeUntil(this._destroying$),
            withLatestFrom(this.bulkActionRows$),
            tap(([set, current]) => {
                if (set == null) return;

                // either toggle all entries from set which are not currently selected
                // or toggle all current rows
                const toToggle = set.length > 0 
                    ? set?.filter((id) => !current.includes(id))
                    : current;

                toToggle?.forEach((id) => {
                    this.toggleBulkAction$.next(id)
                })
            })
        ).subscribe()

        // collect all toggled subrows
        this._toggledSubrows$ = this.toggleSubrow$.pipe(
            scan(
                (all: number[], current: number | null) => {
                    if (current == null) return [] // reset all
                    return all.includes(current)
                        ? all.filter(x => x !== current) // remove from array
                        : [...all, current]
                }, []
            )
        )

        // combine all selected filters in a key value pair obj
        this._selectedFilterValues$ = this.newFilterSelection$.pipe(
            // filter out empty filters (empty string as filter key)
            filter((filter) => filter.id.length > 0),
            // reduce calls
            debounceTime(10),
            // reset to first page
            tap(() => this.nextPage$.next(1)),
            map((newSelection) => {
                let obj: any = new Object();
                obj[newSelection.id] = newSelection.value;
                return obj
            }),
            scan((acc, curr) => Object.assign({}, acc, curr), {}),
            startWith({}),
            shareReplay()
        )

        // resets page with new searches
        combineLatest({
            globalSearch: this._searchQuery$,
            search: this.searchInColumn$
        }).pipe(
            takeUntil(this._destroying$),
            filter(({globalSearch, search}) => globalSearch !== null || search !== null),
            tap(_ => this.nextPage$.next(1)),
        ).subscribe()

        // combine all column widths in a key value pair obj
        this.newColumnWidth$.pipe(
            takeUntil(this._destroying$),
            map((newWidth) => {
                let obj: any = new Object();
                obj[newWidth.columnId] = newWidth.width;
                return obj
            }),
            tap((singleWidth) => {
                // update in store if available
                if (this.tableId) {
                    this.tablesRepo.updateSingleWidth(this.tableId, singleWidth)
                }
            }),
            scan((acc, curr) => Object.assign({}, acc, curr), {}),
            tap((columnWidths) => {
                // update accumulated value in component store if repo is not available
                if (!this.tableId) {
                    this._columnWidths$.next(columnWidths)
                }
            })
        ).subscribe()

        // create filters based on given values in table
        // TODO: Only create filters (or even single filter) once user opens filter dropdown
        this.filters$ = combineLatest([
            this.columns$,
            this._rows$,
            this._selectedFilterValues$
        ]).pipe(
            map(([columns, rows, selectedFilterValues]) => {

                if (this.disableFiltersThreshold !== null && rows.length > this.disableFiltersThreshold) return [];

                const allKeys = columns
                    .filter((col) => !col.config || !col.config.noFilter)
                    .flatMap((col) => col.keys)

                const filters = allKeys.map((keyObj) => {
                    if (!keyObj) return;

                    const uniqueAndFilteredValues = rows.reduce((acc: any[], row) => {
                        const value = row[keyObj.key];
                        if (value && !acc.includes(value)) {
                            acc.push(value);
                        }
                        return acc;
                    }, [] as any[]);

                    let filter: any = {
                        id: keyObj.key,
                        label: keyObj.title
                    }

                    // create filter options with empty values
                    switch (keyObj.type) {
                        case 'string': case 'enum':
                            if (uniqueAndFilteredValues.length == 0) return;
                            filter['options'] = uniqueAndFilteredValues.map((value) => {
                                return {
                                    label: value.toString(),
                                    value: value.toString()
                                }
                            });
                            filter['type']  = 'select-multiple';
                            filter['value'] = [];
                            break;
                        case 'date':
                            if (uniqueAndFilteredValues.length == 0) return;
                            const timestamps = uniqueAndFilteredValues.map((date) => {
                                return new Date(date).getTime()
                            });
                            const minDate = new Date(Math.min(...timestamps));
                            const maxDate = new Date(Math.max(...timestamps));

                            filter['options']   = [{value: minDate}, {value: maxDate}]
                            filter['type']      = 'date-range';
                            break;
                        case 'boolean':
                            if (uniqueAndFilteredValues.length == 0) return;
                            filter['options'] = [
                                {
                                    label: 'True',
                                    value: 'true'
                                },
                                {
                                    label: 'False',
                                    value: 'false'
                                }
                            ];
                            filter['type'] = 'select-single-radio';
                            break;
                        case 'number':
                            if (uniqueAndFilteredValues.length == 0) return;
                            const min = Math.floor(Math.min(...uniqueAndFilteredValues) * 100) / 100;
                            const max = Math.ceil(Math.max(...uniqueAndFilteredValues) * 100) / 100;
                            const diff = Math.floor(max - min);
                            const diffLength = diff.toString().length;
                            // set step to 10 to the power of with diffLength - 3
                            const step = diffLength > 2 ? Math.pow(10, diffLength - 3) : 1;

                            if (max - min <= step) return;

                            filter['rangePickerOptions'] = {
                                min,
                                max,
                                step,
                                firstStart: min,
                                secondStart: max
                            };
                            filter['type'] = 'range';
                            filter['value'] = [];
                            break;
                        case 'array':
                            if (uniqueAndFilteredValues.length == 0) return;
                            let set = new Set<any>();
                            uniqueAndFilteredValues.forEach((innerArray) => {
                              innerArray.forEach((value: any) => set.add(value));
                            });
                            const uniqueValuesArray = Array.from(set).sort();
                            filter['options'] = uniqueValuesArray.map((value) => {
                                return {
                                    label: value.toString(),
                                    value: value.toString()
                                }
                            });
                            filter['type']  = 'select-multiple';
                            filter['value'] = [];
                            break;
                    }

                    // if available, overwrite with current selection
                    const activeSelection = selectedFilterValues[keyObj.key];
                    filter['value'] = activeSelection !== undefined ? activeSelection : filter['value'];

                    // if only one option to filter - don't show filter
                    if (filter.options?.length <= 1) return;
                    
                    return filter
                })
                const nonEmptyFilters = filters.filter((filter) => filter);
                const uniqueAndNonEmptyFilters = Array.from(
                    new Map(nonEmptyFilters.map((filter) => [filter.id, filter])).values()
                );
                return uniqueAndNonEmptyFilters;
            })
        );

        const throttledSearch$ = this.searchInColumn$.pipe(
            // throttle inputs
            debounceTime(200),
            // start with null again, else this subject would not emit until first 
            // debounceTimer is completed, resulting in a rendering delay of the table
            startWith(null)
        )

        // applies search, filters and sorts before paginating
        const filteredRows$ = combineLatest({
            columns: this.columns$,
            rows: this._rows$,
            globalSearch: this._searchQuery$,
            search: throttledSearch$,
            sortBy: this.sortBy$,
            sortDirection: this.sortDirection$,
            filters: this.filters$
        }).pipe(
            map(({columns, rows, globalSearch, search, sortBy, sortDirection, filters}) => {
                const selectedFilters = filters?.filter((filter) => {
                    return filter.value !== undefined && filter.value.length > 0
                })

                if (selectedFilters) {
                    rows = rows.filter((row) => {
                        // apply filters
                        return selectedFilters.every((filter) => {
                            const rowValue = row[filter.id];
                            let match = false;

                            switch (filter.type) {
                                case 'select-multiple': case 'select-single-radio':
                                    if (Array.isArray(rowValue)) {
                                        match = filter.value.every((value: any) => rowValue.includes(value));
                                    } else {
                                        match = filter.value.includes(rowValue);
                                    }
                                    break;
                                case 'date-range': case 'date-time-range':
                                    const interval = {
                                        start: new Date(filter.value[0]), 
                                        end: new Date(filter.value[1])
                                    };
                                    match = isWithinInterval(new Date(rowValue), interval);
                                    break;
                                case 'range':
                                    match = (rowValue >= filter.value[0] && filter.value[1] >= rowValue);
                                    break;
                            }
                            return match
                        })
                    })
                }

                // apply global search over all columns
                if (globalSearch) {
                    // filter through all columns
                    rows = rows.filter((row) => columns.some((column) => {
                        let cellString = '';
                        let value = column.keys?.map((keyObj) => row[keyObj.key]);

                        if (column) {
                            cellString = this.getCellContent(column, value ?? []);
                            cellString = this.stripHTML(cellString);
                        }

                        return cellString.toLowerCase().includes(globalSearch.toLowerCase());
                    }))
                }

                // apply search
                if (search && search.search) {
                    let searchColumn = columns.find((column) => column.id == search.columnId);
                    // filter through single column
                    rows = rows.filter((row) => {
                        let cellString = '';
                        let value = searchColumn?.keys?.map((keyObj) => row[keyObj.key]);

                        if (searchColumn) {
                            cellString = this.getCellContent(searchColumn, value ?? []);
                            cellString = this.stripHTML(cellString);
                        }

                        return cellString.toLowerCase().includes(search.search!.toLowerCase());
                    })
                }

                // apply sorting
                if (sortBy && sortDirection) {
                    const fullKey = columns.flatMap((column) => column.keys)
                        .find((key) => key?.key == sortBy)
                    const sort = new Sort();
                    rows.sort(sort.startSort(sortBy, sortDirection, fullKey?.type, '', 'firstAvailable'))
                }

                // emit filtered rows to parent
                this.onNewDataFiltered.emit(rows);

                return rows
            })
        )

        // internal state based on results length
        this._tableState$ = filteredRows$.pipe(
            map((results) => {
                return results.length > 0 ? 'success' : 'empty'
            })
        )

        // defines textcontent
        this.tableState$ = combineLatest({
            parentState: this.parentState$,
            tableState: this._tableState$,
            newLang: this._translate.onLangChange.pipe(startWith(null))
        }).pipe(
            withLatestFrom(this._selectedFilterValues$),
            withLatestFrom(this._searchQuery$),
            withLatestFrom(this.searchInColumn$),
            map(([[[{parentState, tableState}, selectedFilters], searchQuery], searchInColumn]) => {
                const hasSearch = searchQuery !== null || searchInColumn !== null;
                const hasFilters = selectedFilters && Object.keys(selectedFilters).filter((key) => key.length > 0).length > 0;

                if (parentState == 'error') {
                    return {
                        icon: 'warning',
                        message: this._translate.instant('TABLES.ERROR_RETRIEVING_DATA'),
                        showPreloader: false,
                        refInfo: {
                            type: 'error',
                            hasSearch,
                            hasFilters
                        }
                    }
                }
                if (parentState == 'loading') {
                    return {
                        showPreloader: true,
                        refInfo: {
                            type: 'loading',
                            hasSearch,
                            hasFilters
                        }
                    }
                }
                if (parentState == null || parentState == 'success') {
                    if (tableState == 'empty') {
                        return {
                            refInfo: {
                                type: 'empty',
                                hasSearch,
                                hasFilters
                            },
                            icon: 'manage_search',
                            message: hasFilters && hasSearch 
                                ? this._translate.instant('TABLES.NO_ENTRIES.WITH_FILTERS_AND_SEARCH')
                                : hasFilters 
                                    ? this._translate.instant('TABLES.NO_ENTRIES.WITH_FILTERS')
                                    : hasSearch
                                        ? this._translate.instant('TABLES.NO_ENTRIES.WITH_SEARCH')
                                        : this._translate.instant('TABLES.NO_ENTRIES.DEFAULT')
                        }
                    }
                }
                return {
                    refInfo: {
                        type: null,
                        hasSearch,
                        hasFilters
                    }
                }
            })
        )

        // paginates rows before creating matrix
        const paginatedRows$ = combineLatest({
            rows: filteredRows$,
            page: this.page$
        }).pipe(
            tap(({ rows }) => this.maxPages = Math.ceil(rows.length / (this.perPage ?? rows.length))),
            map(({ rows, page }) =>
                this.perPage !== null
                    ? rows.slice((page - 1) * this.perPage, page * this.perPage)
                    : rows
            )
        )

        // helper for different TableCellAction Types
        const isTableCellActionWithIcon = (action: TableCellAction): action is TableCellActionWithIcon => {
            return (action as TableCellActionWithIcon).icon !== undefined
        }

        // returns array of prepared strings of requested row 
        const getRowContent = (row: Record<string, any>, columns: TableColumn[], isSubrow?: boolean, groupIndex?: number, groupSize?: number): RowContent[] => {
            let rowContent: RowContent[] = [];
            columns.forEach((column) => {
                // html to be displayed in table
                let label: string;
                // original value
                let value = column.keys ? column.keys.map((cell) => row[cell.key]) : [];
                // optional tooltip config
                let tooltipConfig = column.config?.tooltip
                    ? structuredClone(column.config?.tooltip) : undefined;

                label = this.getCellContent(column, value, isSubrow, groupIndex, groupSize);

                // TODO: Remove hard coded rules in this global component!
                // > Should be handled through a provided render fn for tooltips

                // optional tooltip
                if (tooltipConfig && label && value) {
                    if (!tooltipConfig.text) {
                        tooltipConfig.text = label;
                        
                        // max-{n} rules
                        if (tooltipConfig.type && tooltipConfig.type.startsWith('max-')) {
                            const numString = tooltipConfig.type.substring(4);
                            const num = Number(numString);
                            if (isNaN(num)) return;
                            tooltipConfig.text = label.length > num ? label : undefined;
                            label = label.length > num ? label.substring(0, num) + "..." : label;
                        }

                        // fixable by rule
                        if (tooltipConfig.type === 'fixable-by') {
                            let result = '';
                            if (value[0] == 'Yes') {
                                result += this._translate.instant('DETAILS_VIEW.ERRORS.COLUMNS.CHARGING');
                            }
                            tooltipConfig.text = result;
                        }

                        // autorestart rule
                        if (tooltipConfig.type === 'autorestart') {
                            let result = '';
                            if (value[0] == 'Yes') {
                                result += this._translate.instant('COMMON.YES');
                            } else {
                                result += this._translate.instant('COMMON.NO');
                            }
                            tooltipConfig.text = result;
                        }

                        // category rule
                        if (tooltipConfig.type === 'category') {
                            if (value[0] === 'update') tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.UPDATE');
                            if (value[0] === 'information') tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.INFORMATION');
                            if (value[0] === 'warning') tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.WARNING');
                            if (value[0] === 'error') tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.ERROR');
                        }

                        // persistent rule
                        if (tooltipConfig.type === 'persistent') {
                            if (value[0]) tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.PERSISTENT');
                            if (!value[0]) tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.DISMISSIBLE');
                        }

                        // activated rule
                        if (tooltipConfig.type === 'activated') {
                            if (value[0]) tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.ACTIVE');
                            if (!value[0]) tooltipConfig.text = this._translate.instant('NOTIFICATIONS_VIEW.FORM.INACTIVE');
                        }
                    };
                }

                // optional cell actions
                let cellActions: (TableCellAction & {label: string})[] = [];
                if (column.actions) {
                    // evaluate condition if function
                    cellActions = column.actions.map(action => {
                        const evaluate = (prop: any, value: any) => 
                            prop instanceof Function ? prop(value) : prop;
                    
                        const title = evaluate(action.title, value);
                        const condition = evaluate(action.condition, value);
                    
                        const label = isTableCellActionWithIcon(action)
                            ? `<span class="material-icon">${evaluate(action.icon, value)}</span>`
                            : action.renderAction(value);
                    
                        return { ...action, title, condition, label };
                    });
                }

                rowContent.push({
                    label: label, 
                    value: value, 
                    tooltip: tooltipConfig, 
                    actions: cellActions as ResolvedTableCellAction[],
                    renderRef: column.renderRef
                })
            })

            return rowContent
        }

        // pre-format cell values and create result matrix for single rows
        const renderMatrix$ = combineLatest({
            columns: this.columns$,
            rows: paginatedRows$,
            groupBy: this.sortBy$
        }).pipe(
            distinctUntilChanged(),
            map(({columns, rows, groupBy}) => {
                let resultMatrix: [RowMeta, ...RowContent[]][] = [];

                rows.forEach((row) => {
                    // start with config at index 0
                    let rowMeta: RowMeta = {
                        id: row['_row_id'],
                        // 'raw' subrow data in config will be rendered in next observable if subrows are toggled
                        subrowsEntries: row['subrows'],
                        // if row has entry 'bulkActionDisabled: true' store that info here to prevent ba for this row
                        bulkActionDisabled: row['bulkActionDisabled'] ? row['bulkActionDisabled'] : false,
                        groupIdentifier: groupBy ? row[groupBy] : null
                    };

                    if (row['subTableEntries'] && row['subTableColumns'] && row['subTableConfig']) {
                        const subTableConfig = row['subTableConfig'];
                        rowMeta.subTable = {
                            entries: row['subTableEntries'],
                            columns: row['subTableColumns'],
                            config: {
                                fromCol: Array(subTableConfig.fromCol).fill(0),
                                colSpan: subTableConfig.colSpan,
                                defaultSortByKey: subTableConfig.defaultSortByKey,
                                defaultSortDirection: subTableConfig.defaultSortDirection
                            }
                        }
                    }

                    // add placeholder in header when subrows exist
                    if (rowMeta.subTable || rowMeta.subrowsEntries) {
                        this.subrowPlaceholder = true;
                    }

                    resultMatrix.push([rowMeta, ...getRowContent(row, columns, false)])
                })

                return resultMatrix
            })
        )

        // group by sortBy key and pre-format cell values to create result matrix for grouped rows
        const groupedRenderMatrix$ = combineLatest({
            columns: this.columns$,
            rows: paginatedRows$,
            groupBy: this.sortBy$
        }).pipe(
            map(({columns, rows, groupBy}) => {
                // group by sortBy key
                const groups = rows.reduce((acc: Record<string, Record<string, any[]>[]>, row) => {
                    const groupIdentifier = row[groupBy];
                    if (!acc[groupIdentifier]) {
                        acc[groupIdentifier] = [];
                    }
                    acc[groupIdentifier].push(row);
                    return acc;
                }, {});

                // accumulate grouped rows for display in table
                let resultMatrix: [RowMeta, ...RowContent[]][][] = [];
                // create matrix for each group
                const rowGroups = Object.values(groups);
                rowGroups.forEach((rows, groupIndex) => {
                    let rowMatrix: [RowMeta, ...RowContent[]][] = [];

                    rows.forEach((row: Record<string, any>, rowIndex) => {
                        // start with config at index 0
                        let rowMeta: RowMeta = {
                            id: row['_row_id'],
                            // 'raw' subrow data in config will be rendered in next observable if subrows are toggled
                            subrowsEntries: row['subrows'],
                            // if row has entry 'bulkActionDisabled: true' store that info here to prevent ba for this row
                            bulkActionDisabled: row['bulkActionDisabled'] ? row['bulkActionDisabled'] : false
                        };
    
                        if (row['subTableEntries'] && row['subTableColumns'] && row['subTableConfig']) {
                            const subTableConfig = row['subTableConfig'];
                            rowMeta.subTable = {
                                entries: row['subTableEntries'],
                                columns: row['subTableColumns'],
                                config: {
                                    fromCol: Array(subTableConfig.fromCol).fill(0),
                                    colSpan: subTableConfig.colSpan,
                                    defaultSortByKey: subTableConfig.defaultSortByKey,
                                    defaultSortDirection: subTableConfig.defaultSortDirection
                                }
                            }
                        }

                        // add placeholder in header when subrows exist
                        if (rowMeta.subTable || rowMeta.subrowsEntries) {
                            this.subrowPlaceholder = true;
                        }
    
                        rowMatrix.push([rowMeta, ...getRowContent(row, columns, false, rowIndex, rows.length)])
                    })

                    resultMatrix.push(rowMatrix)
                })

                return resultMatrix
            })
        );

        // decide which matrix to use based on groupBy state
        const useMatrix$ = iif(
            () => this.groupBySortingKey() === true,
            groupedRenderMatrix$,
            renderMatrix$
        ).pipe(
            map((matrix) => {
                return {
                    matrix,
                    isGrouped: this.groupBySortingKey()
                }
            })
        );

        // combine all observables to create final table matrix
        this.tableMatrix$ = combineLatest({
            tableContent: useMatrix$,
            toggledSubrows: this._toggledSubrows$
        }).pipe(
            withLatestFrom(this.columns$),
            map(([{tableContent, toggledSubrows}, columns]) => {
                const { matrix, isGrouped } = tableContent;

                /** Returns row with content of subrows if toggled */
                const toggleSubrows = (row: [RowMeta, ...RowContent[]]) => {
                    const rowToggled = toggledSubrows.includes(row[0].id);
                    row[0].rowToggled = rowToggled;

                    // only prepare subrow rendering once selected and not prepared before
                    if (rowToggled && row[0].subrowsEntries && !row[0].subrows) {
                        row[0].subrows = row[0].subrowsEntries.map((entry) => getRowContent(entry, columns, true))
                    }

                    return row
                }

                if (isGrouped) {
                    const groupMatrix = matrix as [RowMeta, ...RowContent[]][][];
                    const returnGroupMatrix = groupMatrix.map((group) => {
                        return group.map((row) => toggleSubrows(row))
                    });

                    return {
                        isGrouped: true,
                        matrix: returnGroupMatrix
                    }
                } else {
                    const flatMatrix = matrix as [RowMeta, ...RowContent[]][];
                    const returnFlatMatrix = flatMatrix.map((row) => toggleSubrows(row));

                    return {
                        isGrouped: false,
                        matrix: returnFlatMatrix
                    }
                }
            })
        )

        // create VM for tableHeaders
        this.tableHeaders$ = combineLatest([
            this.columns$,
            this.filters$,
            this.searchInColumn$,
            this._columnWidths$
        ]).pipe(
            withLatestFrom(this.tableMatrix$),
            map(([[columns, filters, searchColumn, columnWidth], tableMatrix]) => {
                return columns.filter((column) => {
                    if (column.config?.hideWhenEmpty && (!tableMatrix?.matrix || tableMatrix.matrix.length == 0)) return false;
                    return true;
                }).map((column) => {
                    const clonedColumn = { ...column, keys: column.keys?.filter((key) => !key.hidden) };
                    const canBeSearched = clonedColumn.keys?.some((keyObj) => keyObj.type !== 'date');
                    const keys = clonedColumn.keys?.map((keyObj) => keyObj.key);
                    const matchingFilters = filters?.filter((filter) => keys?.includes(filter.id));
                    const columnSearch = searchColumn?.columnId == clonedColumn.id ? searchColumn.search : null;
                    const colWidth = columnWidth[clonedColumn.id];
                    // currently the classname is not utilized
                    const className = '';

                    return {
                        columnHeader: {
                            title: clonedColumn.title,
                            keys: clonedColumn.keys ?? [],
                            canBeSearched
                        },
                        column: clonedColumn,
                        columnSearch,
                        filters: matchingFilters ?? [],
                        columnWidth: colWidth,
                        className
                    }
                })
            })
        );

        // combine for template
        this.bulkActionsVM$ = combineLatest({
            actions: this.bulkActionOptions$,
            selectedRows: this.bulkActionRows$
        })
    }

    ngOnInit(): void {
        if (this.tableId) {
            // manually update value of subject in comp from repo if tableId is set
            this.tablesRepo.getTableWidthUpdates(this.tableId).pipe(
                takeUntil(this._destroying$),
                tap(this._columnWidths$)
            ).subscribe()
        };
    }

    ngAfterViewInit(): void {
        if (this.enrichedScroll) {
            this.updateTableWrapperRect()
            this.layoutTable()

            // keep scrollbars visible on firefox by constantly updating position
            if (window.navigator.userAgent.includes('Firefox')) {
                const scrollKeeperFF = () => {
                    this._FFTimer = setTimeout(() => {
                        const updatableScrollEls = [
                            this._scrollAbove()?.nativeElement,
                            this._scrollBeside()?.nativeElement,
                            this._scrollBelow()?.nativeElement
                        ].filter(el => el);
                        updatableScrollEls.forEach((el) => {
                            const scrollDirection = el.classList.contains('sync-scroll-x') ? 'scrollLeft' : 'scrollTop';
                            el[scrollDirection] = el[scrollDirection] + 1 // triggers visibility of scrollbar
                            el[scrollDirection] = el[scrollDirection] - 1 // instantly return to initial position
                        });
                        scrollKeeperFF()
                    }, 455)
                }
                scrollKeeperFF()
            }
        }
    }

    ngOnChanges() {
        this.layoutTable();
    }

    // provide more data for tunneled subTable events
    public onSubTableAction(event: TableCellActionEvent, subTable: RowMeta['subTable']) {
        const entry = subTable?.entries.find((entry) => entry._row_id == event.row);
        delete entry._row_id
        this.onSubTableCellAction.emit({actionId: event.actionId, subTableContent: entry})
    }

    // get the html content displayed for a certain value
    public getCellContent(column: TableColumn, value: any[], isSubrow?: boolean, groupIndex?: number, groupSize?: number): string {
        // default date formatting
        if (column.keys && column.keys[0].type == 'date' && value[0]) {
            const date = new Date(value[0]);
            value[0] = formatDate(date, 'MMM d, y, HH:mm', 'en');
        }
            
        // default cell renderer
        const baseRenderer = (): string => {
            if (!column.keys || value[0] == null) return '-';
            return value.join('<br>');
        }

        let label: string = '';

        if (column.renderCell !== undefined) {
            const rowInfo: RowInfo = {};
            if (isSubrow != null)   rowInfo.isSubrow = isSubrow;
            if (groupIndex != null) rowInfo.groupIndex = groupIndex;
            if (groupSize != null)  rowInfo.groupSize = groupSize;
            rowInfo.sortBy = this.sortBy$.getValue();
            rowInfo.sortDirection = this.sortDirection$.getValue();
            rowInfo.isGroup = groupSize != null && groupIndex != null;

            try {
                // try to call render fn of column
                label = column.renderCell(value, rowInfo)
            } catch (error) {
                console.warn('[evc-table] renderCell Fn of "'+column.title+'" failed: \n', error)
                // return default format on error
                label = baseRenderer()
            }
        } else if (column.keys) {
            label = baseRenderer()
        }

        return label;
    }

    public updateSortBy(sortInfo: { key: string, direction: 'asc' | 'desc' | null }) {
        let { key, direction } = sortInfo;
        
        const currentSortBy = this.sortBy$.getValue();
        const currentSortDirection = this.sortDirection$.getValue();

        let sortDirection: 'asc' | 'desc';
        if (direction) {
            // set passed direction
            sortDirection = direction;
            // clear sorting if same attribute and direction as previously
            if (currentSortBy == key && currentSortDirection == sortDirection) {
                key = '';
                sortDirection = 'desc';
            }
        } else {
            // toggle sortOrder by default
            sortDirection = currentSortBy == key && currentSortDirection == 'desc' ? 'asc' : 'desc';
        }

        this.sortBy$.next(key)
        this.sortDirection$.next(sortDirection)
    }

    public syncRow(id: number) {
        this._rows$.pipe(
            take(1),
            withLatestFrom(this.syncItem$),
            tap(([rows, item]) => {
                const selectedRow = rows.find(row => row['_row_id'] == id);
                if (item == id) {
                    this.syncItem$.next(null);
                    this.syncItemChange.emit(null);
                    return;
                }
                this.syncItem$.next(id);
                this.syncItemChange.emit(selectedRow);
            })
        ).subscribe();
    }

    // helper function that removes everything html related to only show the actual content
    public stripHTML(html: string): string {
        const regex = /<[^>]*>/g;
        const textContent = html.replace(regex, '');
        return textContent.trim();
    }

    public setScrollSource(event: MouseEvent) {
        if (!this.enrichedScroll) return
        switch (event.type) {
            case 'mouseenter':
                this._scrollSourceEl$.next(event.target as Element ?? undefined)
                break;
            case 'mouseleave':
                this._scrollSourceEl$.next(undefined)
                break;
        }
    }

    // updates start and end of wrapping container for further calculations
    public updateTableWrapperRect() {
        if (!this._tableWrapper()) return;
        let rect: DOMRect = this._tableWrapper()?.nativeElement.getBoundingClientRect(),
            tolerance = 80;

        this.tableWrapperRect = {
            wrapperStart: rect.x + tolerance,
            wrapperEnd: rect.x + rect.width - tolerance
        }
    }

    // syncs scroll position of external scroll bars and table
    public syncScroll(sourceEl: Element, axis: 'x' | 'y' | 'both', threshold: number = 10) {
        if (!this.enrichedScroll) return
        if (sourceEl !== this._scrollSourceEl$.getValue()) return
        // only set requested axis, or both
        const wantedX = axis === 'x' || axis === 'both';
        const wantedY = axis === 'y' || axis === 'both';
        const newScrollLeft = wantedX ? sourceEl.scrollLeft : undefined;
        const newScrollTop = wantedY ? sourceEl.scrollTop : undefined;
    
        // check if the difference exceeds the threshold
        const scrollLeftChanged = newScrollLeft !== undefined && Math.abs(newScrollLeft - this.scrollLeft$.getValue()) >= threshold;
        const scrollTopChanged = newScrollTop !== undefined && Math.abs(newScrollTop - this.scrollTop$.getValue()) >= threshold;

        if (scrollLeftChanged) {
            this.scrollLeft$.next(newScrollLeft)
        }

        if (scrollTopChanged) {
            this.scrollTop$.next(newScrollTop)
        }
    }

    public setBulkActionRows(event: (number | string)[] | null | undefined) {
        if (!event) return;
        this.setBulkActionRows$.next(event as number[]);
    }

    public emitBulkAction(event: {action: string, selectedIds: (number | string)[]}) {
        this.onBulkAction.emit({
            action: event.action,
            rows: event.selectedIds as number[]
        })
    }

    public layoutTable() {
        const getElement = (selector: string): HTMLElement | null => {
            return this._tableCont()?.nativeElement.querySelector(selector);
        };
    
        const calculateTotalWidth = (selector: string): number => {
            const elements = this._tableCont()?.nativeElement.querySelectorAll(selector) as NodeListOf<HTMLElement>;
            return Array.from(elements).reduce((totalWidth, element) => totalWidth + element.offsetWidth, 0);
        };

        // get relevant table elems
        const container = this._tableCont()?.nativeElement;
        const wrapper = this._tableWrapper()?.nativeElement;
        const table = this._tableRef()?.nativeElement;
        const overlay = container?.querySelector('.overlay') as HTMLElement;
        if (!container || !wrapper || !table || !overlay) return;
        
        const headerHeight = getElement('.sticky-top')?.offsetHeight || 0;
        const leadingWidth = calculateTotalWidth('th.leading');
        const trailingWidth = calculateTotalWidth('th.trailing');

        // set width and height for enriched scrolling
        this.tableWidth$.next(table.offsetWidth - leadingWidth - trailingWidth - 1);
        this.tableHeight$.next(table.offsetHeight - headerHeight - 1);

        // check table overflow for X and Y axes
        this.tableOverflowsX = this.tableWidth$.value > (wrapper.offsetWidth || 0 - leadingWidth - trailingWidth);
        this.tableOverflowsY = this.tableHeight$.value > (wrapper.offsetHeight || 0 - headerHeight);
        
        // update grid structure based on overflow state
        const scrollAbove = this.enrichedScroll && this.showScrollAbove && this.tableOverflowsX;
        const scrollBelow = this.enrichedScroll && this.showScrollBelow && this.tableOverflowsX;
        const scrollBeside = this.enrichedScroll && this.showScrollBeside && this.tableOverflowsY;
        
        this._renderer.setStyle(container, 'grid-template-rows', `${scrollAbove ? '18px' : '0px'} 1fr ${scrollBelow ? '18px' : '0px'}`);
        this._renderer.setStyle(container, 'grid-template-columns', scrollBeside ? '1fr 18px' : '1fr 0px');

        // timeout of zero to wait for grid changes
        setTimeout(() => {
            // height of the table header
            const headerHeight = getElement('.sticky-top')?.offsetHeight || 0;
            // width of all leading / trailing elems
            const notEmpty = Array.from(table.children).some((child: any) => !child.classList.contains('sticky-top'));
            const leadingWidth = notEmpty ? calculateTotalWidth('th.leading') : 0;
            const trailingWidth = notEmpty ? calculateTotalWidth('th.trailing') : 0;

            // scrollbar information
            const scrollbarTop = getElement('.scrollbar-wrapper-x.top');
            const scrollbarTopHeight = scrollbarTop?.offsetHeight || 0;
            const scrollbarBottom = getElement('.scrollbar-wrapper-x.bottom');
            const scrollbarBottomHeight = scrollbarBottom?.offsetHeight || 0;
            const scrollbarBeside = getElement('.scrollbar-wrapper-y');
            const scrollBarBesideWidth = calculateTotalWidth('.scrollbar-wrapper-y');

            // calc overlay / scrollbar dimensions
            const top = headerHeight + scrollbarTopHeight;
            const left = leadingWidth
            const height = container.offsetHeight - headerHeight - scrollbarTopHeight - scrollbarBottomHeight;
            const width = container.offsetWidth - leadingWidth - trailingWidth - scrollBarBesideWidth;
            
            // overlay
            this._renderer.setStyle(overlay, 'top', `${top}px`);
            this._renderer.setStyle(overlay, 'left', `${left}px`);
            this._renderer.setStyle(overlay, 'width', `${width - 2}px`);
            this._renderer.setStyle(overlay, 'height', `${height - 2}px`);
            // scrollbars
            if (scrollbarTop && scrollbarBottom) {
                this._renderer.setStyle(scrollbarTop, 'left', `${left - 1}px`);
                this._renderer.setStyle(scrollbarTop, 'width', `${width}px`);
                this._renderer.setStyle(scrollbarBottom, 'left', `${left - 1}px`);
                this._renderer.setStyle(scrollbarBottom, 'width', `${width}px`);
            }
            if (scrollbarBeside) {
                this._renderer.setStyle(scrollbarBeside, 'top', `${top - 1}px`);
                this._renderer.setStyle(scrollbarBeside, 'height', `${height}px`);
            }
        }, 0)
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined)
        this._destroying$.complete()
        window.clearTimeout(this._FFTimer)
    }
}
