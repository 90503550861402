import { Component, Inject, inject } from "@angular/core";
import {
    catchError,
    combineLatest,
    distinctUntilChanged,
    EMPTY,
    filter,
    from,
    map,
    Observable,
    pairwise,
    ReplaySubject,
    retry,
    share,
    take,
    tap,
} from "rxjs";
import { appRepository } from "./core/stores/app.repository";
import { Router } from "@angular/router";
import { NotificationService } from "./core/app-services";
import { CustomersConfig } from "./core/auth-backend/models";
import { overviewRepository } from "./core/stores/overview.repository";
import { KeywordLoggingService, UserService } from "./core/data-backend/data-services";
import { CustomersConfigService } from "./core/auth-backend/auth-services";
import { RoleTypes } from "./core/data-backend/models";
import { AppAuthService } from "./core/app-services/app-auth.service";
import { DOCUMENT } from "@angular/common";
import { GlobalService } from "./core/app-services/global.service";
import { detailsRepository } from "./core/stores/details.repository";
import { AppUpdateService } from "./core/app-services/app-update.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import packageJson from "package.json";

@Component({
    selector: "app-root",
    template: `
        @if (stateVM$ | async; as states) {
            @if (states.isCustomerSelected && states.isUserSet && states.isLoggedIn && states.isAppReady) {
                <app-navigation></app-navigation>
                <router-outlet></router-outlet>
                <evc-support-overlay/>
                <evc-meter-value-estimator
                    [open]="globalService.meterValueEstOpen$ | async"
                    [stations]="(overviewRepo.allStationLocations$ | async)?.data ?? []"
                    [selectedStationId]="detailsRepo.stationId$ | async"
                    (openChange)="globalService.toggleMeterValueEstimator($event)"
                />
                <evc-private-modals/>
            } @else {
                <app-login-screen
                    [isLoggedIn]="states.isLoggedIn"
                    [isCustomerSelected]="states.isCustomerSelected"
                    [isUserSet]="states.isUserSet"
                    [customers]="customers"
                    [appReady]="states.isAppReady"
                    (customerSelected)="setCustomer($event)"
                    (logoutClicked)="appAuthService.logout()"
                />
            }

            <app-customer-variables/>
            <global-modals/>
        }
    `,
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    readonly brandName: string = environment.brandName;
    customers: any[] = [];
    private readonly availableLanguages = environment.availableLanguagesList;
    readonly ngAppStageName: string = process.env.NG_APP_STAGE_NAME

    // combines and handles all user states
    public stateVM$: Observable<{
        isCustomerSelected: boolean,
        isUserSet: boolean,
        isLoggedIn: boolean,
        isAppReady: boolean
    }>;

    constructor(
        public appAuthService: AppAuthService,
        public appUpdateService: AppUpdateService,
        public globalService: GlobalService,
        public overviewRepo: overviewRepository,
        public detailsRepo: detailsRepository,
        public translate: TranslateService,
        private _customersConfigService: CustomersConfigService,
        private _userService: UserService,
        private _notificationService: NotificationService,
        private _keywordLoggingService: KeywordLoggingService,
        private _router: Router,
        private _appRepo: appRepository,
        @Inject(DOCUMENT) private _document: Document
    ) {
        console.log(`%cYou're boosting availability with ${environment.brandName}: v${packageJson.version}`, "color: #4895ef;");
        console.log(`%cappID ${environment.appId}`, 'color: #4895ef;')

        // add google tag manager script if env is dev
        if (this.ngAppStageName === 'int') {
            // TODO: Get GMT ID from config/env
            const scriptContent = `
                window.dataLayer = window.dataLayer || [];
        
                (function (w, d, s, l, i) {
                    w[l] = w[l] || []; w[l].push({
                        'gtm.start':
                            new Date().getTime(), event: 'gtm.js'
                    }); var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-TDKHDMHC');
            `;

            const scriptElement = this._document.createElement('script');
            scriptElement.type = 'text/javascript';
            scriptElement.text = scriptContent;

            this._document.head.appendChild(scriptElement);
        }

        // provide available languages to translate services
        translate.addLangs(this.availableLanguages);
        // always keep en as default
        translate.setDefaultLang(this.availableLanguages[0]);

        const isCustomerSelected$ = combineLatest({
            selectedCustomerId: this._appRepo.selectedCustomerId$,
            currentUser: this._appRepo.currentUser$
        }).pipe(
            map(({selectedCustomerId, currentUser}) => selectedCustomerId !== null && selectedCustomerId !== "" && currentUser !== null)
        );

        const isUserSet$ = this._appRepo.currentUser$.pipe(
            tap((user) => {
                // set user language if set and matching available languages
                if (user && user.language && this.availableLanguages.includes(user.language)) {
                    if (user.language == translate.currentLang) return;
                    translate.use(user.language);
                    console.log(`%cLanguage set to "${user.language}" based on user settings`, 'color: #4895ef;');
                } else {
                    // set default if no user is set, user has no language set or lang is not available in app
                    if (this.availableLanguages[0] == translate.currentLang) return;
                    translate.use(this.availableLanguages[0]);  
                    console.log(`%cLanguage set to "${this.availableLanguages[0]}" as default`, 'color: #4895ef;');
                }
            }),
            map((user) => user !== null),
            share({connector: () => new ReplaySubject(1)})
        );

        // true when all fonts are loaded
        const isAppReady$ = from(inject(DOCUMENT).fonts.ready).pipe(
            map((event) => event && event.status == 'loaded')
        );

        this.stateVM$ = combineLatest({
            isCustomerSelected: isCustomerSelected$,
            isUserSet: isUserSet$,
            isLoggedIn: this.appAuthService.isLoggedIn$,
            isAppReady: isAppReady$
        }).pipe(
            share({connector: () => new ReplaySubject(1)})
        );

        // state machine listens to changes in customer, current user or logged-in status
        this.stateVM$.pipe(
            takeUntilDestroyed(),
            distinctUntilChanged((prev, current) => {
                // only execute if any state changed
                return prev.isCustomerSelected === current.isCustomerSelected
                    && prev.isUserSet === current.isUserSet
                    && prev.isLoggedIn === current.isLoggedIn
            }),
            filter(({isLoggedIn}) => isLoggedIn),
            tap(({isCustomerSelected, isUserSet, isLoggedIn, isAppReady}) => {
                // always refresh currentUser when app refreshes, to catch changes in permissions, tenants, etc.
                // only refresh if user and customer are currently set
                if (isCustomerSelected && isUserSet) {
                    this._userService.getCurrentUser().pipe(
                        take(1),
                        tap((user) => {
                            this._appRepo.updateUser(user);
                        })
                    ).subscribe()
                }

                if (!isCustomerSelected) {
                    this._customersConfigService.getCustomersConfig().pipe(
                        retry(5),
                        take(1),
                        tap((customersConfig) => this._handleCustomersConfig(customersConfig)),
                    ).subscribe();
                }
            }),
        ).subscribe();

        // handle setting and removing of postLoginURL
        this.appAuthService.isLoggedIn$.pipe(
            takeUntilDestroyed(),
            pairwise(),
            tap(([prev, current]) => {
                // user is trying to log in, store postLoginURL
                if (!prev) {
                    const hasState = sessionStorage.getItem('postLoginURL') !== null;
                    // only store postLoginURL if user is not logged in and is not on root view
                    if (!hasState && window.location.pathname !== '/') {
                        sessionStorage.setItem('postLoginURL', window.location.pathname)
                    }
                }
                // user is logging out, delete postLoginURL
                if (prev && !current) {
                    sessionStorage.removeItem('postLoginURL');
                }
            })
        ).subscribe()
    }

    private _handleCustomersConfig(customersConfig: CustomersConfig) {
        this._appRepo.updateCustomersConfig(customersConfig);
        // else we'll either show an error, set a single available customer or show selection
        if (!customersConfig.customers || customersConfig.customers.length === 0) {
            this._appRepo.updateSelectedCustomer(null);
            this._notificationService.showLocalizedInfo('APP_ERRORS.WILL_LOGOUT');
            this._notificationService.showLocalizedWarning(
                'APP_ERRORS.NO_CUSTOMER_ASSIGNED.TEXT',
                'APP_ERRORS.NO_CUSTOMER_ASSIGNED.TITLE',
            );
            setTimeout(() => {
                this.appAuthService.logout();
            }, 4000);
        } else if (customersConfig.customers.length === 1) {
            // POST single customer to BE
            this.setCustomer(customersConfig.customers[0]);
        } else {
            // pass customers to selection
            this.customers = customersConfig.customers;
        }
    }

    setCustomer(customer: any) {
        this._appRepo.updateSelectedCustomer(customer);
        console.log(`%ccustomerID ${customer.identifier}`, 'color: #4895ef;');
        this._appRepo.applyStoredStates(customer.identifier);
        // make sure to remove last user
        this._appRepo.updateUser(null);

        // get user profile
        this._userService.getCurrentUser().pipe(
            retry(5),
            take(1),
            tap((user) => {
                // show info and log user out if no tenant is assigned
                const userIsAdmin = user.roles.indexOf(RoleTypes.UserAdmin) > -1;
                if ((user.tenants === undefined || user.tenants.length === 0) && !userIsAdmin) {
                    this._notificationService.showLocalizedInfo('APP_ERRORS.WILL_LOGOUT');
                    this._notificationService.showLocalizedWarning(
                        'APP_ERRORS.NO_TENANT_ASSIGNED.TEXT',
                        'APP_ERRORS.NO_TENANT_ASSIGNED.TITLE'
                    );
                    setTimeout(() => {
                        this.appAuthService.logout();
                    }, 4000);
                } else {
                    // update repo with new user
                    this._appRepo.updateUser(user);
                    
                    // increment refetch revision to refetch all data
                    this.overviewRepo.incrementRefetchRev();

                    // check if postLoginURL is set
                    const postLoginUrl = sessionStorage.getItem('postLoginURL');
                    if (postLoginUrl) {
                        // apply postLoginURL and remove from store
                        const urlFragments = postLoginUrl.split('/').filter((x) => x !== '')
                        this._router.navigate(urlFragments);
                        sessionStorage.removeItem('postLoginURL')
                    } else if (user.roles.length == 1 && user.roles.includes(RoleTypes.UserAdmin)) {
                        this._router.navigate(["/admin-users"]);
                    } else {
                        this._router.navigate(["/overview"]);
                    }
                }
            }),
        ).subscribe();

        // send message to traffic logger
        this._keywordLoggingService.logTraffic({ "body": { keyword: "logins" } }).pipe(
            take(1),
            catchError(_ => EMPTY),
        ).subscribe();
    }
}
