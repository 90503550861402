/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthBaseService } from '../auth-base-service';
import { AuthApiConfiguration } from '../auth-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addCustomer } from '../fn/admin/add-customer';
import { AddCustomer$Params } from '../fn/admin/add-customer';
import { ApiResponse } from '../models/api-response';
import { Customer } from '../models/customer';
import { deleteCustomer } from '../fn/admin/delete-customer';
import { DeleteCustomer$Params } from '../fn/admin/delete-customer';
import { deleteCustomerFromUser } from '../fn/admin/delete-customer-from-user';
import { DeleteCustomerFromUser$Params } from '../fn/admin/delete-customer-from-user';
import { deleteUser } from '../fn/admin/delete-user';
import { DeleteUser$Params } from '../fn/admin/delete-user';
import { getCustomer } from '../fn/admin/get-customer';
import { GetCustomer$Params } from '../fn/admin/get-customer';
import { getCustomers } from '../fn/admin/get-customers';
import { GetCustomers$Params } from '../fn/admin/get-customers';
import { getUser } from '../fn/admin/get-user';
import { GetUser$Params } from '../fn/admin/get-user';
import { getUsers } from '../fn/admin/get-users';
import { GetUsers$Params } from '../fn/admin/get-users';
import { registerUser } from '../fn/admin/register-user';
import { RegisterUser$Params } from '../fn/admin/register-user';
import { updateCustomer } from '../fn/admin/update-customer';
import { UpdateCustomer$Params } from '../fn/admin/update-customer';
import { updateCustomerAdminRole } from '../fn/admin/update-customer-admin-role';
import { UpdateCustomerAdminRole$Params } from '../fn/admin/update-customer-admin-role';
import { updateUser } from '../fn/admin/update-user';
import { UpdateUser$Params } from '../fn/admin/update-user';
import { User } from '../models/user';


/**
 * User administration
 */
@Injectable({ providedIn: 'root' })
export class AdminService extends AuthBaseService {
  constructor(config: AuthApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateCustomerAdminRole()` */
  static readonly UpdateCustomerAdminRolePath = '/admin/users/{uuid}/customerAdmin/';

  /**
   * Change admin state on a customer for a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerAdminRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerAdminRole$Response(params: UpdateCustomerAdminRole$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'detail'?: string;
}>> {
    return updateCustomerAdminRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Change admin state on a customer for a user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomerAdminRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerAdminRole(params: UpdateCustomerAdminRole$Params, context?: HttpContext): Observable<{
'detail'?: string;
}> {
    return this.updateCustomerAdminRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'detail'?: string;
}>): {
'detail'?: string;
} => r.body)
    );
  }

  /** Path part for operation `deleteCustomerFromUser()` */
  static readonly DeleteCustomerFromUserPath = '/admin/users/{uuid}/deleteCustomer/';

  /**
   * Delete a customer from a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerFromUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteCustomerFromUser$Response(params: DeleteCustomerFromUser$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'detail'?: string;
}>> {
    return deleteCustomerFromUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete a customer from a user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerFromUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteCustomerFromUser(params: DeleteCustomerFromUser$Params, context?: HttpContext): Observable<{
'detail'?: string;
}> {
    return this.deleteCustomerFromUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'detail'?: string;
}>): {
'detail'?: string;
} => r.body)
    );
  }

  /** Path part for operation `getUser()` */
  static readonly GetUserPath = '/admin/users/:uuid/';

  /**
   * get an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: GetUser$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return getUser(this.http, this.rootUrl, params, context);
  }

  /**
   * get an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: GetUser$Params, context?: HttpContext): Observable<User> {
    return this.getUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `deleteUser()` */
  static readonly DeleteUserPath = '/admin/users/:uuid/';

  /**
   * Delete an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: DeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return deleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: DeleteUser$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.deleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `updateUser()` */
  static readonly UpdateUserPath = '/admin/users/:uuid/';

  /**
   * Updates an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: UpdateUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return updateUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates an existing user from the customer authorization backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: UpdateUser$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.updateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `getUsers()` */
  static readonly GetUsersPath = '/admin/users/';

  /**
   * get existing users from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: GetUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<User>>> {
    return getUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * get existing users from the customer authorization backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: GetUsers$Params, context?: HttpContext): Observable<Array<User>> {
    return this.getUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<User>>): Array<User> => r.body)
    );
  }

  /** Path part for operation `registerUser()` */
  static readonly RegisterUserPath = '/admin/users/';

  /**
   * Add a new user to the customer authorization backend.
   *
   * /TODO fill me
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser$Response(params: RegisterUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return registerUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a new user to the customer authorization backend.
   *
   * /TODO fill me
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser(params: RegisterUser$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.registerUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `getCustomer()` */
  static readonly GetCustomerPath = '/admin/customers/:uuid/';

  /**
   * Get an existing customer from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomer$Response(params: GetCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<Customer>> {
    return getCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Get an existing customer from the customer authorization backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomer(params: GetCustomer$Params, context?: HttpContext): Observable<Customer> {
    return this.getCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<Customer>): Customer => r.body)
    );
  }

  /** Path part for operation `deleteCustomer()` */
  static readonly DeleteCustomerPath = '/admin/customers/:uuid/';

  /**
   * Delete an existing customer from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer$Response(params: DeleteCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return deleteCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete an existing customer from the customer authorization backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer(params: DeleteCustomer$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.deleteCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `updateCustomer()` */
  static readonly UpdateCustomerPath = '/admin/customers/:uuid/';

  /**
   * Updates an existing customer in the authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomer$Response(params: UpdateCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return updateCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates an existing customer in the authorization backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomer(params: UpdateCustomer$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.updateCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `getCustomers()` */
  static readonly GetCustomersPath = '/admin/customers/';

  /**
   * get existing customers from the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Response(params?: GetCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Customer>>> {
    return getCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * get existing customers from the customer authorization backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers(params?: GetCustomers$Params, context?: HttpContext): Observable<Array<Customer>> {
    return this.getCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>): Array<Customer> => r.body)
    );
  }

  /** Path part for operation `addCustomer()` */
  static readonly AddCustomerPath = '/admin/customers/';

  /**
   * Add a new customer to the customer authorization backend.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCustomer$Response(params: AddCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return addCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Add a new customer to the customer authorization backend.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCustomer(params: AddCustomer$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.addCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

}
