import { Component, input, output } from '@angular/core';

export const BUTTON_COLORS = [
    "primary", // white on purple
    "secondary", // purple on white
    "tertiary", // white on grey
] as const;

export type ButtonColor = (typeof BUTTON_COLORS)[number];

export const BUTTON_VARIANTS = ['outline', 'flat'] as const;

export type ButtonVariant = (typeof BUTTON_VARIANTS)[number];

/**
 * Simple button component with optional icon.
 * Defaults to a secondary button (white bg and purple text + border).
 * You can overwrite CSS variables to change the appearance:
 *  - `--btn-[variant]-bg-color`
 *  - `--btn-[variant]-txt-color`
 *  - `--btn-[variant]-border-color`
 *  - `--btn-[variant]-bg-hover-color`
 *  - `--btn-[variant]-txt-hover-color`
 *  - `--btn-[variant]-border-hover-color`
 *  - `--btn-border-width`
 *  - `--icon-size`
 * 
 * Usage:
 * 
 * ```html
 * <button evc-button (click)="someMethod()">Click me</button>
 * <button 
 *     evc-button
 *     style="font-size: 1.5rem; --icon-size: 18px;"
 *     icon="sync"
 *     [iconSpinOnLoading]="true"
 *     [loading]="someSignal()"
 *     [disabled]="(condition$ | async) === true"
 *     [color]="someSignal() ? 'primary' : 'secondary'"
 *     (click)="someMethod()"
 * >{{ 'SOME.BUTTON.LABEL' | translate }}</button>
 * ```
 */
@Component({
  selector: 'button[evc-button]',
  template: `
    @if (icon()) {
        <span class="material-icon" [class.spin]="iconSpinOnLoading() && loading()">{{icon()}}</span>
    }
    <span><ng-content></ng-content></span>
  `,
  styleUrl: './button.component.scss',
  host: {
    '[class.primary]': 'color() === "primary"',
    '[class.secondary]': 'color() === "secondary"',
    '[class.tertiary]': 'color() === "tertiary"',
    '[class.outline]': 'variant() === "outline"',
    '[class.flat]': 'variant() === "flat"',
    '[class.w-100]': 'fullWidth()',
    '[class.no-wrap]': 'nowrap()',
    '[class.loading]': 'loading()',
  },
})
export class ButtonComponent {
    /** optional material icon, for example: "sync" */
    icon = input<string>();
    /**
     * Possible colors:
     * - primary: white on purple
     * - secondary: purple on white (default)
     * - tertiary: white on grey
     */
    color = input<ButtonColor>("secondary");
    /**
     * Possible variants:
     * - outline (default)
     * - flat
     */
    variant = input<ButtonVariant>("outline");
    /** whether the button should take the full width */
    fullWidth = input<boolean>(false);
    /** set true to prevent line breaks in the button text */
    nowrap = input<boolean>(false);
    /** set true to enable a spinning animation for the icon if loading is true */
    iconSpinOnLoading = input<boolean>(false);
    /** set true to enable a loading animation (and disable the control) */
    loading = input<boolean>(false);
}
