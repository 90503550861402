/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApiResponse } from '../models/api-response';
import { logTraffic } from '../fn/keyword-logging/log-traffic';
import { LogTraffic$Params } from '../fn/keyword-logging/log-traffic';


/**
 * Basic logging
 */
@Injectable({ providedIn: 'root' })
export class KeywordLoggingService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `logTraffic()` */
  static readonly LogTrafficPath = '/trafficLogger/';

  /**
   * Log keywords for tool utilization analysis.
   *
   * The traffic logger logs log lines with information contained in the requests in order to later analyze utilization of the tool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logTraffic()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logTraffic$Response(params?: LogTraffic$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return logTraffic(this.http, this.rootUrl, params, context);
  }

  /**
   * Log keywords for tool utilization analysis.
   *
   * The traffic logger logs log lines with information contained in the requests in order to later analyze utilization of the tool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logTraffic$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logTraffic(params?: LogTraffic$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.logTraffic$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

}
