import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, map, takeUntil, tap } from 'rxjs';
import { ChargingStation, Connector } from 'src/app/core/data-backend/models';
import { Filter } from 'src/app/core/stores/station-filters.repository';

export interface TableColumnHeader {
    title: string;
    // all keys of this table column
    keys: {
        key: string,
        title: string
    }[];
    // shows search input, needs handling by parent
    canBeSearched?: boolean;
}

@Component({
    selector: 'table-header',
    template: `
        <ng-container
            *ngIf="vm$ | async as vm"
        >
            @if (!icon || (icon && !icon.only)) {
                <div
                    class="table-header-wrapper"
                    [class.squished-left]="squished == 'left'"
                    [class.squished-right]="squished == 'right'"
                >
                    <div
                        [class.no-sort]="noSort"
                        [attr.data-order]="vm.sortingByKeyInColumn ? vm.sortOrder : 'none'"
                        (click)="!noSort ? handleSort(vm.columnHeader) : ''"
                        class="table-header"
                    >
                        <div 
                            *ngIf="icon"
                            [class]="'icon '+icon.type+' '+icon.size"
                            [style.backgroundColor]="icon.color"
                        ></div>

                        <span [innerHTML]="vm.columnHeader.title"></span>

                        <span 
                            *ngIf="hoverInfo"
                            class="material-icon hover-info"
                            [tooltip]="hoverInfo"
                            [size]="'small'"
                        >
                            info
                        </span>

                        <button
                            *ngIf="vm.filters.length > 0"
                            class="toggle-filters"
                            [class.open]="(filterDropdownOpen$ | async) ?? false"
                            [class.active]="vm.hasActiveFilters"
                            (click)="toggleDropdown('filter'); $event.stopPropagation()"
                        >
                            <span 
                                class="material-icon"
                            >filter_alt</span>
                        </button>
                    </div>
                    <div class="column-search">
                        <input 
                            *ngIf="vm.columnHeader.canBeSearched && noSearch != true"
                            [placeholder]="'COMMON.SEARCH' | translate" 
                            type="text" 
                            [value]="vm.columnSearch"
                            (input)="emitSearchValue($event)"
                        >
                        <span class="search-icon">search</span>
                        <button 
                            class="delete-button"
                            tabindex="-1" 
                            (click)="resetInput()"
                        >close</button>
                    </div>
                    <app-dropdown-button
                        size="small"
                        type="custom-column"
                        bodyClass="no-scroll"
                        [open]="(sortDropdownOpen$ | async) ?? false"
                        (onClose)="toggleDropdown('sort', false)"
                    >
                        <ng-container body>
                            <div class="drop-cont">
                                <p class="title pb-16">{{ 'TABLES.SORT_COLUMN' | translate }}</p>
                                @for (key of vm.columnHeader.keys; track key) {
                                    <div class="flex-row align-items-center">
                                        {{ key.title }}
                                        <span                                     
                                            (click)="handleSort(vm.columnHeader, key.key, 'asc')"
                                            [attr.data-order-asc]="vm.sortByKey == key.key && vm.sortOrder == 'asc'" 
                                            class="material-icon"
                                        >expand_more</span>
                                        <span                                     
                                            (click)="handleSort(vm.columnHeader, key.key, 'desc')"
                                            [attr.data-order-desc]="vm.sortByKey == key.key && vm.sortOrder == 'desc'"
                                            class="material-icon"
                                        >expand_more</span>
                                        <br>
                                    </div>
                                }
                            </div>
                        </ng-container>
                    </app-dropdown-button>

                    <app-dropdown-button
                        size="small"
                        type="custom-column"
                        bodyClass="no-scroll"
                        [open]="(filterDropdownOpen$ | async) ?? false"
                        [hasInteractiveContent]="true"
                        (onClose)="toggleDropdown('filter', false)"
                    >
                        <ng-container body>
                            <div class="drop-cont filters-dropdown">
                                <p class="title">{{ 'TABLES.FILTER_COLUMN' | translate }}</p>
                                <filter-select
                                    *ngFor="let filter of vm.filters"
                                    [keepPosition]="true"
                                    [activeFilter]="filter"
                                    [showDeleteButton]="false"
                                    [pseudoDisable]="true"
                                    (onSelectionChange)="onFilterSelection.emit({id: filter.id, value: $event})"
                                    (onResetFilter)="onFilterReset.emit($event)"
                                >
                                </filter-select>
                            </div>
                        </ng-container>
                    </app-dropdown-button>
                </div>
            } @else {
                <div class="table-header-wrapper">
                    <div class="table-header only-icon">
                        <div 
                            [class]="'icon '+icon.type+' '+icon.size"
                            [class.only]="icon.only"
                            [style.backgroundColor]="icon.color"
                        ></div>
                    </div>
                </div>
            }
        </ng-container>
    `,
    styleUrls: ['./table-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderComponent implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();

    private _columnHeader$ = new BehaviorSubject<TableColumnHeader | null>(null);
    @Input() set columnHeader(value: TableColumnHeader | null) {
        this._columnHeader$.next(value)
    };
    private _sortByKey$ = new BehaviorSubject<string | null>(null)
    @Input() set sortByKey(value: string | null) {
        this._sortByKey$.next(value)
    };
    private _sortOrder$ = new BehaviorSubject<'asc' | 'desc' | null>(null)
    @Input() set sortOrder(value: 'asc' | 'desc' | null) {
        this._sortOrder$.next(value)
    };
    @Input() isDragging: boolean = false;

    // value of search in column, can be updated by parent
    private _columnSearch$ = new BehaviorSubject<string | null>(null);
    @Input() set columnSearch(search: string | null) {
        this._columnSearch$.next(search)
    }

    // available filters in dropdown of this column
    private _filters$ = new BehaviorSubject<Filter[]>([]);
    @Input() set filters(filters: Filter[] | null) {
        if (filters) this._filters$.next(filters)
    }

    // whether sorting should be enabled or not
    @Input() noSort?: boolean = undefined;

    // whether search should be enabled or not
    @Input() noSearch?: boolean = undefined;

    // whether the header controls should be squished over the available width
    @Input() squished?: 'right' | 'left' = undefined;

    // icon w settings that should be displayed in front of the header
    @Input() icon?: {type: string, color?: string, size?: 'default' | 'large', only: boolean} = undefined;

    // if set, a yellow info icon is visible in the header, displaying info content in a tooltip
    @Input() hoverInfo?: string;

    // parent component ref to listen to scroll events
    @Input() tableWrapper: HTMLElement | undefined;

    @Output() onSort = new EventEmitter<{key: string, direction: 'asc' | 'desc' | null}>();
    @Output() onColumnSearch = new EventEmitter<string | null>();
    @Output() onFilterSelection = new EventEmitter<{id: string, value: any}>();
    @Output() onFilterReset = new EventEmitter<Filter>();

    public sortDropdownOpen$ = new BehaviorSubject<boolean>(false);
    public filterDropdownOpen$ = new BehaviorSubject<boolean>(false);
    private _unlistenEvents: (() => void)[] = [];

    public vm$: Observable<{
        columnHeader: TableColumnHeader,
        // true if keyInArray or keyInConnector is used as sortBy
        sortingByKeyInColumn: boolean,
        sortByKey: keyof ChargingStation | keyof Connector | string | null,
        sortOrder: 'asc' | 'desc' | null,
        filters: Filter[],
        hasActiveFilters: boolean,
        columnSearch: string | null
    } | null>;

    constructor(
        private _renderer: Renderer2,
        private _elRef: ElementRef
    ) {
        // create vm
        this.vm$ = combineLatest({
            columnHeader: this._columnHeader$,
            sortByKey: this._sortByKey$,
            sortOrder: this._sortOrder$,
            filters: this._filters$,
            columnSearch: this._columnSearch$
        }).pipe(
            map(({ columnHeader, sortByKey, sortOrder, filters, columnSearch }) => {
                if (!columnHeader) return null

                const keysValues = columnHeader.keys.map((key) => key.key);

                const sortingByKeyInColumn = sortByKey !== null ? keysValues.includes(sortByKey) : false;

                // check if at least one of the matching active filters has actual values
                let hasActiveFilters = filters.filter((filter) => filter.value && filter.value.length > 0).length > 0;

                return {
                    columnHeader,
                    sortByKey,
                    sortOrder,
                    columnSearch,
                    filters,
                    sortingByKeyInColumn: sortingByKeyInColumn,
                    hasActiveFilters: hasActiveFilters
                }
            })
        )

        this.sortDropdownOpen$.pipe(
            takeUntil(this._destroying$),
            tap((isOpen) => {
                if (isOpen) this.filterDropdownOpen$.next(false)
            })
        ).subscribe()

        this.filterDropdownOpen$.pipe(
            takeUntil(this._destroying$),
            tap((isOpen) => {
                if (isOpen) this.sortDropdownOpen$.next(false)
            })
        ).subscribe()

        combineLatest([
            this.sortDropdownOpen$,
            this.filterDropdownOpen$
        ]).pipe(
            takeUntil(this._destroying$),
            tap(([sortOpen, filterOpen]) => {
                if (sortOpen || filterOpen) {
                    // set listeners to clicks outside dropdown and scroll events on table wrapper
                    this._unlistenEvents.push(
                        this._renderer.listen('document', 'mousedown', (event) => {
                            if (!this._elRef.nativeElement.contains(event.target)) {
                                this.filterDropdownOpen$.next(false)
                                this.sortDropdownOpen$.next(false)
                            }
                        }),
                        this._renderer.listen(this.tableWrapper, 'scroll', (event) => {
                            this.filterDropdownOpen$.next(false)
                            this.sortDropdownOpen$.next(false)
                        })
                    )
                } else {
                    this._unlistenEvents.forEach((unlistener) => unlistener())
                    this._unlistenEvents = []
                }
            })
        ).subscribe()
    }

    public handleSort(column: TableColumnHeader, key?: string, direction?: 'asc' | 'desc') {
        if (!key) {          
            if (column.keys.length == 1) {
                key = column.keys[0].key
            } else {
                this.toggleDropdown('sort')
            }
        }

        if (key) {
            this.onSort.emit({ key, direction: direction ? direction : null });
            this.toggleDropdown('sort', false)
        }
    }

    public toggleDropdown(target: 'sort' | 'filter', forcedState?: boolean) {
        if (target == 'sort') {
            this.sortDropdownOpen$.next(forcedState ?? !this.sortDropdownOpen$.getValue())
        } else {
            this.filterDropdownOpen$.next(forcedState ?? !this.filterDropdownOpen$.getValue())
        }
    }

    public emitSearchValue(event: Event) {
        this.onColumnSearch.emit((event?.target as HTMLInputElement).value)
    }

    public resetInput() {
        this.onColumnSearch.emit(null);
        this._columnSearch$.next(null);
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
