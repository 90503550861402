/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AdditionResponseValue } from '../models/addition-response-value';
import { ApiResponse } from '../models/api-response';
import { changeVisibilityOfChargingStations } from '../fn/station/change-visibility-of-charging-stations';
import { ChangeVisibilityOfChargingStations$Params } from '../fn/station/change-visibility-of-charging-stations';
import { ChargingSession } from '../models/charging-session';
import { ChargingStation } from '../models/charging-station';
import { deleteAdditions } from '../fn/station/delete-additions';
import { DeleteAdditions$Params } from '../fn/station/delete-additions';
import { deleteOverwrites } from '../fn/station/delete-overwrites';
import { DeleteOverwrites$Params } from '../fn/station/delete-overwrites';
import { deleteRestarts } from '../fn/station/delete-restarts';
import { DeleteRestarts$Params } from '../fn/station/delete-restarts';
import { DeleteRestartsResponse } from '../models/delete-restarts-response';
import { deleteSchedules } from '../fn/station/delete-schedules';
import { DeleteSchedules$Params } from '../fn/station/delete-schedules';
import { DeleteSchedulesResponse } from '../models/delete-schedules-response';
import { EnergyMeterValue } from '../models/energy-meter-value';
import { Error } from '../models/error';
import { GenericOcppEvents } from '../models/generic-ocpp-events';
import { getAggregatedOcppMessagesOfChargingStation } from '../fn/station/get-aggregated-ocpp-messages-of-charging-station';
import { GetAggregatedOcppMessagesOfChargingStation$Params } from '../fn/station/get-aggregated-ocpp-messages-of-charging-station';
import { getAuthorizationsOfChargingStation } from '../fn/station/get-authorizations-of-charging-station';
import { GetAuthorizationsOfChargingStation$Params } from '../fn/station/get-authorizations-of-charging-station';
import { getChargingSessionsOfChargingStation } from '../fn/station/get-charging-sessions-of-charging-station';
import { GetChargingSessionsOfChargingStation$Params } from '../fn/station/get-charging-sessions-of-charging-station';
import { getChargingStation } from '../fn/station/get-charging-station';
import { GetChargingStation$Params } from '../fn/station/get-charging-station';
import { getChargingStations } from '../fn/station/get-charging-stations';
import { GetChargingStations$Params } from '../fn/station/get-charging-stations';
import { getErrorsOfChargingStation } from '../fn/station/get-errors-of-charging-station';
import { GetErrorsOfChargingStation$Params } from '../fn/station/get-errors-of-charging-station';
import { getGenericOcppEventsOfChargingStation } from '../fn/station/get-generic-ocpp-events-of-charging-station';
import { GetGenericOcppEventsOfChargingStation$Params } from '../fn/station/get-generic-ocpp-events-of-charging-station';
import { getHealthIndexExplanationsOfChargingStation } from '../fn/station/get-health-index-explanations-of-charging-station';
import { GetHealthIndexExplanationsOfChargingStation$Params } from '../fn/station/get-health-index-explanations-of-charging-station';
import { getHealthIndexValuesOfChargingStation } from '../fn/station/get-health-index-values-of-charging-station';
import { GetHealthIndexValuesOfChargingStation$Params } from '../fn/station/get-health-index-values-of-charging-station';
import { getLoadBalancingOfChargingStation } from '../fn/station/get-load-balancing-of-charging-station';
import { GetLoadBalancingOfChargingStation$Params } from '../fn/station/get-load-balancing-of-charging-station';
import { getMeterValue } from '../fn/station/get-meter-value';
import { GetMeterValue$Params } from '../fn/station/get-meter-value';
import { getOcppConfigurationOfChargingStation } from '../fn/station/get-ocpp-configuration-of-charging-station';
import { GetOcppConfigurationOfChargingStation$Params } from '../fn/station/get-ocpp-configuration-of-charging-station';
import { getOcppMessagesTypesOfChargingStation } from '../fn/station/get-ocpp-messages-types-of-charging-station';
import { GetOcppMessagesTypesOfChargingStation$Params } from '../fn/station/get-ocpp-messages-types-of-charging-station';
import { getOnIdleRestarts } from '../fn/station/get-on-idle-restarts';
import { GetOnIdleRestarts$Params } from '../fn/station/get-on-idle-restarts';
import { getOverallStatesOfChargingStation } from '../fn/station/get-overall-states-of-charging-station';
import { GetOverallStatesOfChargingStation$Params } from '../fn/station/get-overall-states-of-charging-station';
import { getRestartEventsOfChargingStation } from '../fn/station/get-restart-events-of-charging-station';
import { GetRestartEventsOfChargingStation$Params } from '../fn/station/get-restart-events-of-charging-station';
import { getSchedules } from '../fn/station/get-schedules';
import { GetSchedules$Params } from '../fn/station/get-schedules';
import { getStatusNotificationsOfChargingStation } from '../fn/station/get-status-notifications-of-charging-station';
import { GetStatusNotificationsOfChargingStation$Params } from '../fn/station/get-status-notifications-of-charging-station';
import { getTicketsOfChargingStation } from '../fn/station/get-tickets-of-charging-station';
import { GetTicketsOfChargingStation$Params } from '../fn/station/get-tickets-of-charging-station';
import { HealthIndexExplanation } from '../models/health-index-explanation';
import { HealthIndexValue } from '../models/health-index-value';
import { LoadBalancingPeriod } from '../models/load-balancing-period';
import { MasterDataOverwrite } from '../models/master-data-overwrite';
import { OcppAuthorizationV1 } from '../models/ocpp-authorization-v-1';
import { OcppAuthorizationV2 } from '../models/ocpp-authorization-v-2';
import { OcppConfiguration } from '../models/ocpp-configuration';
import { OcppMessageAggregation } from '../models/ocpp-message-aggregation';
import { OcppMessageEvent } from '../models/ocpp-message-event';
import { OcppStatusNotificationV1 } from '../models/ocpp-status-notification-v-1';
import { OcppStatusNotificationV2 } from '../models/ocpp-status-notification-v-2';
import { OverallStatus } from '../models/overall-status';
import { OverviewMetaInfo } from '../models/overview-meta-info';
import { readAdditions } from '../fn/station/read-additions';
import { ReadAdditions$Params } from '../fn/station/read-additions';
import { readOverwrites } from '../fn/station/read-overwrites';
import { ReadOverwrites$Params } from '../fn/station/read-overwrites';
import { resetChargingStations } from '../fn/station/reset-charging-stations';
import { ResetChargingStations$Params } from '../fn/station/reset-charging-stations';
import { ResetResponse } from '../models/reset-response';
import { Restart } from '../models/restart';
import { restartChargingStationsBySchedule } from '../fn/station/restart-charging-stations-by-schedule';
import { RestartChargingStationsBySchedule$Params } from '../fn/station/restart-charging-stations-by-schedule';
import { restartChargingStationsManually } from '../fn/station/restart-charging-stations-manually';
import { RestartChargingStationsManually$Params } from '../fn/station/restart-charging-stations-manually';
import { RestartEvent } from '../models/restart-event';
import { RestartSchedule } from '../models/restart-schedule';
import { Ticket } from '../models/ticket';
import { writeAdditions } from '../fn/station/write-additions';
import { WriteAdditions$Params } from '../fn/station/write-additions';
import { writeOverwrites } from '../fn/station/write-overwrites';
import { WriteOverwrites$Params } from '../fn/station/write-overwrites';


/**
 * All Charging Station related endpoints
 */
@Injectable({ providedIn: 'root' })
export class StationService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getChargingStations()` */
  static readonly GetChargingStationsPath = '/chargingStations/';

  /**
   * Gets list of charging stations.
   *
   * Fetch a paginated list of charging stations, optionally for a specific date
   * The returned list of charging stations is basically paginated. Only if latMin, latMax, lonMin and lonMax are set, the complete result list is returned. In that case the pagination and sorting parameters are ignored. In addition, the charging stations contain only the attributes needed for the display in the map.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStations$Response(params?: GetChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}>> {
    return getChargingStations(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets list of charging stations.
   *
   * Fetch a paginated list of charging stations, optionally for a specific date
   * The returned list of charging stations is basically paginated. Only if latMin, latMax, lonMin and lonMax are set, the complete result list is returned. In that case the pagination and sorting parameters are ignored. In addition, the charging stations contain only the attributes needed for the display in the map.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStations(params?: GetChargingStations$Params, context?: HttpContext): Observable<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}> {
    return this.getChargingStations$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
}>): {
'stations'?: Array<ChargingStation>;
'meta'?: OverviewMetaInfo;
} => r.body)
    );
  }

  /** Path part for operation `resetChargingStations()` */
  static readonly ResetChargingStationsPath = '/chargingStations/reset/';

  /**
   * Resets a list of charging stations.
   *
   * Send a OCPP reset command to a list of charging stations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetChargingStations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetChargingStations$Response(params: ResetChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetResponse>> {
    return resetChargingStations(this.http, this.rootUrl, params, context);
  }

  /**
   * Resets a list of charging stations.
   *
   * Send a OCPP reset command to a list of charging stations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetChargingStations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetChargingStations(params: ResetChargingStations$Params, context?: HttpContext): Observable<ResetResponse> {
    return this.resetChargingStations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetResponse>): ResetResponse => r.body)
    );
  }

  /** Path part for operation `changeVisibilityOfChargingStations()` */
  static readonly ChangeVisibilityOfChargingStationsPath = '/chargingStations/changeVisibility/';

  /**
   * Changes the visibility of a list of charging stations.
   *
   * The list of charging stations will be shown or hidden for the current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeVisibilityOfChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeVisibilityOfChargingStations$Response(params: ChangeVisibilityOfChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<ApiResponse>> {
    return changeVisibilityOfChargingStations(this.http, this.rootUrl, params, context);
  }

  /**
   * Changes the visibility of a list of charging stations.
   *
   * The list of charging stations will be shown or hidden for the current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeVisibilityOfChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeVisibilityOfChargingStations(params: ChangeVisibilityOfChargingStations$Params, context?: HttpContext): Observable<ApiResponse> {
    return this.changeVisibilityOfChargingStations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApiResponse>): ApiResponse => r.body)
    );
  }

  /** Path part for operation `getChargingStation()` */
  static readonly GetChargingStationPath = '/chargingStations/{stationId}/';

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation$Response(params: GetChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<ChargingStation>> {
    return getChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets a single charging station.
   *
   * Fetch a single charging stations, optionally for a specific date
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingStation(params: GetChargingStation$Params, context?: HttpContext): Observable<ChargingStation> {
    return this.getChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChargingStation>): ChargingStation => r.body)
    );
  }

  /** Path part for operation `getHealthIndexValuesOfChargingStation()` */
  static readonly GetHealthIndexValuesOfChargingStationPath = '/chargingStations/{stationId}/healthIndexValues/';

  /**
   * Gets health index values of charging station.
   *
   * Fetch the health index values of a specific charging station, optionally for a specific date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHealthIndexValuesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthIndexValuesOfChargingStation$Response(params: GetHealthIndexValuesOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<HealthIndexValue>>> {
    return getHealthIndexValuesOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets health index values of charging station.
   *
   * Fetch the health index values of a specific charging station, optionally for a specific date
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHealthIndexValuesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthIndexValuesOfChargingStation(params: GetHealthIndexValuesOfChargingStation$Params, context?: HttpContext): Observable<Array<HealthIndexValue>> {
    return this.getHealthIndexValuesOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HealthIndexValue>>): Array<HealthIndexValue> => r.body)
    );
  }

  /** Path part for operation `getHealthIndexExplanationsOfChargingStation()` */
  static readonly GetHealthIndexExplanationsOfChargingStationPath = '/chargingStations/{stationId}/healthIndexExplanations/';

  /**
   * Gets health index explanations of charging station.
   *
   * Fetch the health index explanations of a specific charging station, optionally for a specific date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHealthIndexExplanationsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthIndexExplanationsOfChargingStation$Response(params: GetHealthIndexExplanationsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<HealthIndexExplanation>>> {
    return getHealthIndexExplanationsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets health index explanations of charging station.
   *
   * Fetch the health index explanations of a specific charging station, optionally for a specific date
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getHealthIndexExplanationsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthIndexExplanationsOfChargingStation(params: GetHealthIndexExplanationsOfChargingStation$Params, context?: HttpContext): Observable<Array<HealthIndexExplanation>> {
    return this.getHealthIndexExplanationsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<HealthIndexExplanation>>): Array<HealthIndexExplanation> => r.body)
    );
  }

  /** Path part for operation `getAggregatedOcppMessagesOfChargingStation()` */
  static readonly GetAggregatedOcppMessagesOfChargingStationPath = '/chargingStations/{stationId}/ocppMessageAggregations/';

  /**
   * Gets aggregated messages of charging station.
   *
   * Fetch the aggregated messages of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAggregatedOcppMessagesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregatedOcppMessagesOfChargingStation$Response(params: GetAggregatedOcppMessagesOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OcppMessageAggregation>>> {
    return getAggregatedOcppMessagesOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets aggregated messages of charging station.
   *
   * Fetch the aggregated messages of a specific charging station, optionally for a specific time interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAggregatedOcppMessagesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAggregatedOcppMessagesOfChargingStation(params: GetAggregatedOcppMessagesOfChargingStation$Params, context?: HttpContext): Observable<Array<OcppMessageAggregation>> {
    return this.getAggregatedOcppMessagesOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OcppMessageAggregation>>): Array<OcppMessageAggregation> => r.body)
    );
  }

  /** Path part for operation `getOcppMessagesTypesOfChargingStation()` */
  static readonly GetOcppMessagesTypesOfChargingStationPath = '/chargingStations/{stationId}/ocppMessageEvents/';

  /**
   * Gets OCPP message occurrences by type for a charging station.
   *
   * Fetch OCPP message occurrences. The response includes the type and time of occurrence for each message, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOcppMessagesTypesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppMessagesTypesOfChargingStation$Response(params: GetOcppMessagesTypesOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OcppMessageEvent>>> {
    return getOcppMessagesTypesOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets OCPP message occurrences by type for a charging station.
   *
   * Fetch OCPP message occurrences. The response includes the type and time of occurrence for each message, optionally for a specific time interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOcppMessagesTypesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppMessagesTypesOfChargingStation(params: GetOcppMessagesTypesOfChargingStation$Params, context?: HttpContext): Observable<Array<OcppMessageEvent>> {
    return this.getOcppMessagesTypesOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OcppMessageEvent>>): Array<OcppMessageEvent> => r.body)
    );
  }

  /** Path part for operation `getChargingSessionsOfChargingStation()` */
  static readonly GetChargingSessionsOfChargingStationPath = '/chargingStations/{stationId}/chargingSessions/';

  /**
   * Gets charging sessions of a charging station.
   *
   * Fetch the charging sessions of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChargingSessionsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingSessionsOfChargingStation$Response(params: GetChargingSessionsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChargingSession>>> {
    return getChargingSessionsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets charging sessions of a charging station.
   *
   * Fetch the charging sessions of a specific charging station, optionally for a specific time interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChargingSessionsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChargingSessionsOfChargingStation(params: GetChargingSessionsOfChargingStation$Params, context?: HttpContext): Observable<Array<ChargingSession>> {
    return this.getChargingSessionsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChargingSession>>): Array<ChargingSession> => r.body)
    );
  }

  /** Path part for operation `getErrorsOfChargingStation()` */
  static readonly GetErrorsOfChargingStationPath = '/chargingStations/{stationId}/errors/';

  /**
   * Gets errors of a charging station.
   *
   * Fetch the errors of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErrorsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorsOfChargingStation$Response(params: GetErrorsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Error>>> {
    return getErrorsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets errors of a charging station.
   *
   * Fetch the errors of a specific charging station, optionally for a specific time interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getErrorsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorsOfChargingStation(params: GetErrorsOfChargingStation$Params, context?: HttpContext): Observable<Array<Error>> {
    return this.getErrorsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Error>>): Array<Error> => r.body)
    );
  }

  /** Path part for operation `getOverallStatesOfChargingStation()` */
  static readonly GetOverallStatesOfChargingStationPath = '/chargingStations/{stationId}/overallStates/';

  /**
   * Gets overall state history of a charging station.
   *
   * Fetch the overall states of a specific charging station, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOverallStatesOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverallStatesOfChargingStation$Response(params: GetOverallStatesOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OverallStatus>>> {
    return getOverallStatesOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets overall state history of a charging station.
   *
   * Fetch the overall states of a specific charging station, optionally for a specific time interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOverallStatesOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOverallStatesOfChargingStation(params: GetOverallStatesOfChargingStation$Params, context?: HttpContext): Observable<Array<OverallStatus>> {
    return this.getOverallStatesOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OverallStatus>>): Array<OverallStatus> => r.body)
    );
  }

  /** Path part for operation `getOcppConfigurationOfChargingStation()` */
  static readonly GetOcppConfigurationOfChargingStationPath = '/chargingStations/{stationId}/ocppConfiguration/';

  /**
   * Gets latest ocpp configuration of a charging station.
   *
   * Fetch latest ocpp configuration of a specific charging station
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOcppConfigurationOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppConfigurationOfChargingStation$Response(params: GetOcppConfigurationOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OcppConfiguration>>> {
    return getOcppConfigurationOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets latest ocpp configuration of a charging station.
   *
   * Fetch latest ocpp configuration of a specific charging station
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOcppConfigurationOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOcppConfigurationOfChargingStation(params: GetOcppConfigurationOfChargingStation$Params, context?: HttpContext): Observable<Array<OcppConfiguration>> {
    return this.getOcppConfigurationOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OcppConfiguration>>): Array<OcppConfiguration> => r.body)
    );
  }

  /** Path part for operation `getAuthorizationsOfChargingStation()` */
  static readonly GetAuthorizationsOfChargingStationPath = '/chargingStations/{stationId}/ocppAuthorizations/';

  /**
   * Gets authorization events of a charging station.
   *
   * Fetch authorizations of a specific charging station
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuthorizationsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthorizationsOfChargingStation$Response(params: GetAuthorizationsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(OcppAuthorizationV1 | OcppAuthorizationV2)>>> {
    return getAuthorizationsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets authorization events of a charging station.
   *
   * Fetch authorizations of a specific charging station
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuthorizationsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthorizationsOfChargingStation(params: GetAuthorizationsOfChargingStation$Params, context?: HttpContext): Observable<Array<(OcppAuthorizationV1 | OcppAuthorizationV2)>> {
    return this.getAuthorizationsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(OcppAuthorizationV1 | OcppAuthorizationV2)>>): Array<(OcppAuthorizationV1 | OcppAuthorizationV2)> => r.body)
    );
  }

  /** Path part for operation `getStatusNotificationsOfChargingStation()` */
  static readonly GetStatusNotificationsOfChargingStationPath = '/chargingStations/{stationId}/ocppStatusNotifications/';

  /**
   * Gets status notification events of a charging station.
   *
   * Fetch status notification of a specific charging station
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStatusNotificationsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatusNotificationsOfChargingStation$Response(params: GetStatusNotificationsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(OcppStatusNotificationV1 | OcppStatusNotificationV2)>>> {
    return getStatusNotificationsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets status notification events of a charging station.
   *
   * Fetch status notification of a specific charging station
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStatusNotificationsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStatusNotificationsOfChargingStation(params: GetStatusNotificationsOfChargingStation$Params, context?: HttpContext): Observable<Array<(OcppStatusNotificationV1 | OcppStatusNotificationV2)>> {
    return this.getStatusNotificationsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(OcppStatusNotificationV1 | OcppStatusNotificationV2)>>): Array<(OcppStatusNotificationV1 | OcppStatusNotificationV2)> => r.body)
    );
  }

  /** Path part for operation `getGenericOcppEventsOfChargingStation()` */
  static readonly GetGenericOcppEventsOfChargingStationPath = '/chargingStations/{stationId}/genericOcppEvents/';

  /**
   * Miscellaneous ocpp events of a charging station.
   *
   * Fetch ocpp events of a specific charging station that are not fetchable via other endpoints
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGenericOcppEventsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenericOcppEventsOfChargingStation$Response(params: GetGenericOcppEventsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GenericOcppEvents>>> {
    return getGenericOcppEventsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Miscellaneous ocpp events of a charging station.
   *
   * Fetch ocpp events of a specific charging station that are not fetchable via other endpoints
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGenericOcppEventsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGenericOcppEventsOfChargingStation(params: GetGenericOcppEventsOfChargingStation$Params, context?: HttpContext): Observable<Array<GenericOcppEvents>> {
    return this.getGenericOcppEventsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GenericOcppEvents>>): Array<GenericOcppEvents> => r.body)
    );
  }

  /** Path part for operation `getLoadBalancingOfChargingStation()` */
  static readonly GetLoadBalancingOfChargingStationPath = '/chargingStations/{stationId}/loadBalancingPeriods/';

  /**
   * Gets load balancing events of a charging station.
   *
   * Fetch load balancing information of a specific charging station
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLoadBalancingOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoadBalancingOfChargingStation$Response(params: GetLoadBalancingOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LoadBalancingPeriod>>> {
    return getLoadBalancingOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets load balancing events of a charging station.
   *
   * Fetch load balancing information of a specific charging station
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLoadBalancingOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLoadBalancingOfChargingStation(params: GetLoadBalancingOfChargingStation$Params, context?: HttpContext): Observable<Array<LoadBalancingPeriod>> {
    return this.getLoadBalancingOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LoadBalancingPeriod>>): Array<LoadBalancingPeriod> => r.body)
    );
  }

  /** Path part for operation `getRestartEventsOfChargingStation()` */
  static readonly GetRestartEventsOfChargingStationPath = '/restarts/{stationId}/restartEvents/';

  /**
   * Gets restart events of charging station.
   *
   * Fetch the history of last restarts of a specific charging station, optionally for a specific date interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRestartEventsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestartEventsOfChargingStation$Response(params: GetRestartEventsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RestartEvent>>> {
    return getRestartEventsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets restart events of charging station.
   *
   * Fetch the history of last restarts of a specific charging station, optionally for a specific date interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRestartEventsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRestartEventsOfChargingStation(params: GetRestartEventsOfChargingStation$Params, context?: HttpContext): Observable<Array<RestartEvent>> {
    return this.getRestartEventsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RestartEvent>>): Array<RestartEvent> => r.body)
    );
  }

  /** Path part for operation `getOnIdleRestarts()` */
  static readonly GetOnIdleRestartsPath = '/restarts/getOnIdleRestarts/';

  /**
   * Retrieves pending onIdle restarts in the restarts index for a list of charging stations.
   *
   * Retrieves Restarts of RestartStatus "pending" of mode manual and onIdle in the restarts index for a list of charging stations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnIdleRestarts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnIdleRestarts$Response(params?: GetOnIdleRestarts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Restart>>> {
    return getOnIdleRestarts(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves pending onIdle restarts in the restarts index for a list of charging stations.
   *
   * Retrieves Restarts of RestartStatus "pending" of mode manual and onIdle in the restarts index for a list of charging stations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOnIdleRestarts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnIdleRestarts(params?: GetOnIdleRestarts$Params, context?: HttpContext): Observable<Array<Restart>> {
    return this.getOnIdleRestarts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Restart>>): Array<Restart> => r.body)
    );
  }

  /** Path part for operation `getSchedules()` */
  static readonly GetSchedulesPath = '/restarts/getSchedules/';

  /**
   * Retrieves restart schedules in the restart schedules index for a list of charging stations.
   *
   * Retrieves restart schedules in the restart schedules index for a list of charging stations. Each station can only have one schedule associated with it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedules$Response(params?: GetSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RestartSchedule>>> {
    return getSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves restart schedules in the restart schedules index for a list of charging stations.
   *
   * Retrieves restart schedules in the restart schedules index for a list of charging stations. Each station can only have one schedule associated with it.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchedules(params?: GetSchedules$Params, context?: HttpContext): Observable<Array<RestartSchedule>> {
    return this.getSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RestartSchedule>>): Array<RestartSchedule> => r.body)
    );
  }

  /** Path part for operation `restartChargingStationsManually()` */
  static readonly RestartChargingStationsManuallyPath = '/restarts/manualRestart/';

  /**
   * Creates entries in the restarts index for a list of charging stations to be restarted.
   *
   * Creates entries of RestartStatus "pending" of mode manual and onIdle restart in the restarts index according to the type of restart selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart either immediately or when the station is not charging (onIdle).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restartChargingStationsManually()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsManually$Response(params: RestartChargingStationsManually$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetResponse>> {
    return restartChargingStationsManually(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates entries in the restarts index for a list of charging stations to be restarted.
   *
   * Creates entries of RestartStatus "pending" of mode manual and onIdle restart in the restarts index according to the type of restart selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart either immediately or when the station is not charging (onIdle).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restartChargingStationsManually$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsManually(params: RestartChargingStationsManually$Params, context?: HttpContext): Observable<ResetResponse> {
    return this.restartChargingStationsManually$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetResponse>): ResetResponse => r.body)
    );
  }

  /** Path part for operation `restartChargingStationsBySchedule()` */
  static readonly RestartChargingStationsBySchedulePath = '/restarts/scheduledRestart/';

  /**
   * Creates entries in the restarts schedule index for a list of charging stations to be restarted.
   *
   * Creates entries for restart schedules (weekly or daily) according to the weekdays selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart according to schedule.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `restartChargingStationsBySchedule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsBySchedule$Response(params: RestartChargingStationsBySchedule$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetResponse>> {
    return restartChargingStationsBySchedule(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates entries in the restarts schedule index for a list of charging stations to be restarted.
   *
   * Creates entries for restart schedules (weekly or daily) according to the weekdays selected for a list of charging stations. The restarts worker lambda later processes and triggers the respective restart according to schedule.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `restartChargingStationsBySchedule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  restartChargingStationsBySchedule(params: RestartChargingStationsBySchedule$Params, context?: HttpContext): Observable<ResetResponse> {
    return this.restartChargingStationsBySchedule$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetResponse>): ResetResponse => r.body)
    );
  }

  /** Path part for operation `deleteSchedules()` */
  static readonly DeleteSchedulesPath = '/restarts/deleteSchedules/';

  /**
   * Deletes entries in the restart schedules index for a list of charging stations.
   *
   * Deletes entries for restart schedules (weekly or daily) for a list of charging stations. The restarts worker lambda will not process any schedule for these stations anymore.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSchedules$Response(params?: DeleteSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteSchedulesResponse>> {
    return deleteSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes entries in the restart schedules index for a list of charging stations.
   *
   * Deletes entries for restart schedules (weekly or daily) for a list of charging stations. The restarts worker lambda will not process any schedule for these stations anymore.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSchedules(params?: DeleteSchedules$Params, context?: HttpContext): Observable<DeleteSchedulesResponse> {
    return this.deleteSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteSchedulesResponse>): DeleteSchedulesResponse => r.body)
    );
  }

  /** Path part for operation `deleteRestarts()` */
  static readonly DeleteRestartsPath = '/restarts/deleteRestarts/';

  /**
   * Deletes entries in the restarts index for a list of charging stations.
   *
   * Deletes entries for onIdle restarts for a list of charging stations. The restarts worker lambda will not process this onIdle restart anymore.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRestarts()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRestarts$Response(params?: DeleteRestarts$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteRestartsResponse>> {
    return deleteRestarts(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes entries in the restarts index for a list of charging stations.
   *
   * Deletes entries for onIdle restarts for a list of charging stations. The restarts worker lambda will not process this onIdle restart anymore.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRestarts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRestarts(params?: DeleteRestarts$Params, context?: HttpContext): Observable<DeleteRestartsResponse> {
    return this.deleteRestarts$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteRestartsResponse>): DeleteRestartsResponse => r.body)
    );
  }

  /** Path part for operation `readOverwrites()` */
  static readonly ReadOverwritesPath = '/overwrites/';

  /**
   * Read overwrite values for all or given charging stations.
   *
   * Reads master data overwrite values from master_data_overwrites_customer in DynamoDB. Can be filtered by field names and stations IDs.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readOverwrites()` instead.
   *
   * This method doesn't expect any request body.
   */
  readOverwrites$Response(params?: ReadOverwrites$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MasterDataOverwrite>>> {
    return readOverwrites(this.http, this.rootUrl, params, context);
  }

  /**
   * Read overwrite values for all or given charging stations.
   *
   * Reads master data overwrite values from master_data_overwrites_customer in DynamoDB. Can be filtered by field names and stations IDs.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readOverwrites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readOverwrites(params?: ReadOverwrites$Params, context?: HttpContext): Observable<Array<MasterDataOverwrite>> {
    return this.readOverwrites$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterDataOverwrite>>): Array<MasterDataOverwrite> => r.body)
    );
  }

  /** Path part for operation `writeOverwrites()` */
  static readonly WriteOverwritesPath = '/overwrites/';

  /**
   * Writes an overwrite value for one or more charging stations.
   *
   * Creates or replaces a master data overwrite values in master_data_overwrites_customer in DynamoDB for a given list of station IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `writeOverwrites()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  writeOverwrites$Response(params: WriteOverwrites$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return writeOverwrites(this.http, this.rootUrl, params, context);
  }

  /**
   * Writes an overwrite value for one or more charging stations.
   *
   * Creates or replaces a master data overwrite values in master_data_overwrites_customer in DynamoDB for a given list of station IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `writeOverwrites$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  writeOverwrites(params: WriteOverwrites$Params, context?: HttpContext): Observable<void> {
    return this.writeOverwrites$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteOverwrites()` */
  static readonly DeleteOverwritesPath = '/overwrites/';

  /**
   * Deletes all or given field's overwrite values of given charging stations.
   *
   * Deletes master data overwrite values from master_data_overwrites_customer in DynamoDB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOverwrites()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOverwrites$Response(params: DeleteOverwrites$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteOverwrites(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes all or given field's overwrite values of given charging stations.
   *
   * Deletes master data overwrite values from master_data_overwrites_customer in DynamoDB
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOverwrites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOverwrites(params: DeleteOverwrites$Params, context?: HttpContext): Observable<void> {
    return this.deleteOverwrites$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `readAdditions()` */
  static readonly ReadAdditionsPath = '/additions/';

  /**
   * Read additional values for all or given charging stations.
   *
   * Reads additional charging station values from DynamoDB. Can be filtered by stations IDs.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readAdditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  readAdditions$Response(params?: ReadAdditions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdditionResponseValue>>> {
    return readAdditions(this.http, this.rootUrl, params, context);
  }

  /**
   * Read additional values for all or given charging stations.
   *
   * Reads additional charging station values from DynamoDB. Can be filtered by stations IDs.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readAdditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readAdditions(params?: ReadAdditions$Params, context?: HttpContext): Observable<Array<AdditionResponseValue>> {
    return this.readAdditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdditionResponseValue>>): Array<AdditionResponseValue> => r.body)
    );
  }

  /** Path part for operation `writeAdditions()` */
  static readonly WriteAdditionsPath = '/additions/';

  /**
   * Upserts an additional value for a charging station.
   *
   * Creates or replaces a key-value pair for ChargingStation.additions in DynamoDB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `writeAdditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  writeAdditions$Response(params: WriteAdditions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return writeAdditions(this.http, this.rootUrl, params, context);
  }

  /**
   * Upserts an additional value for a charging station.
   *
   * Creates or replaces a key-value pair for ChargingStation.additions in DynamoDB
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `writeAdditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  writeAdditions(params: WriteAdditions$Params, context?: HttpContext): Observable<void> {
    return this.writeAdditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteAdditions()` */
  static readonly DeleteAdditionsPath = '/additions/';

  /**
   * Deletes an additional value of a charging station.
   *
   * Deletes a key-value pair for ChargingStation.additions in DynamoDB
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAdditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdditions$Response(params: DeleteAdditions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAdditions(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes an additional value of a charging station.
   *
   * Deletes a key-value pair for ChargingStation.additions in DynamoDB
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAdditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAdditions(params: DeleteAdditions$Params, context?: HttpContext): Observable<void> {
    return this.deleteAdditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getTicketsOfChargingStation()` */
  static readonly GetTicketsOfChargingStationPath = '/chargingStations/{stationId}/tickets/';

  /**
   * Gets ticket information of a charging station from a ticket system.
   *
   * Gets information of all tickets that are found in the tenant's specified ticket system for a selected charging station.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTicketsOfChargingStation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTicketsOfChargingStation$Response(params: GetTicketsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Ticket>>> {
    return getTicketsOfChargingStation(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets ticket information of a charging station from a ticket system.
   *
   * Gets information of all tickets that are found in the tenant's specified ticket system for a selected charging station.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTicketsOfChargingStation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTicketsOfChargingStation(params: GetTicketsOfChargingStation$Params, context?: HttpContext): Observable<Array<Ticket>> {
    return this.getTicketsOfChargingStation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Ticket>>): Array<Ticket> => r.body)
    );
  }

  /** Path part for operation `getMeterValue()` */
  static readonly GetMeterValuePath = '/chargingStations/{stationId}/meterValuesEstimation/';

  /**
   * Gets energy meter values for a single charging station for the meter value estimator.
   *
   * Fetch the last energy meter values before the selected date for each connector of a single charging station. If no date is selected, it fetches the last value received. The values are used in the meter value estimator feature.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeterValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeterValue$Response(params: GetMeterValue$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EnergyMeterValue>>> {
    return getMeterValue(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets energy meter values for a single charging station for the meter value estimator.
   *
   * Fetch the last energy meter values before the selected date for each connector of a single charging station. If no date is selected, it fetches the last value received. The values are used in the meter value estimator feature.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMeterValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMeterValue(params: GetMeterValue$Params, context?: HttpContext): Observable<Array<EnergyMeterValue>> {
    return this.getMeterValue$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnergyMeterValue>>): Array<EnergyMeterValue> => r.body)
    );
  }

}
