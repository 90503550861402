/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addFilterSets } from '../fn/filter-set/add-filter-sets';
import { AddFilterSets$Params } from '../fn/filter-set/add-filter-sets';
import { deleteFilterSet } from '../fn/filter-set/delete-filter-set';
import { DeleteFilterSet$Params } from '../fn/filter-set/delete-filter-set';
import { FilterSet } from '../models/filter-set';
import { FilterSetStationsCounts } from '../models/filter-set-stations-counts';
import { getFilterSet } from '../fn/filter-set/get-filter-set';
import { GetFilterSet$Params } from '../fn/filter-set/get-filter-set';
import { getFilterSets } from '../fn/filter-set/get-filter-sets';
import { GetFilterSets$Params } from '../fn/filter-set/get-filter-sets';
import { retrieveCount } from '../fn/filter-set/retrieve-count';
import { RetrieveCount$Params } from '../fn/filter-set/retrieve-count';
import { SharedFilterSet } from '../models/shared-filter-set';
import { subscribeFilterSet } from '../fn/filter-set/subscribe-filter-set';
import { SubscribeFilterSet$Params } from '../fn/filter-set/subscribe-filter-set';
import { updateFilterSet } from '../fn/filter-set/update-filter-set';
import { UpdateFilterSet$Params } from '../fn/filter-set/update-filter-set';


/**
 * Filtersets for Charging Stations
 */
@Injectable({ providedIn: 'root' })
export class FilterSetService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFilterSets()` */
  static readonly GetFilterSetsPath = '/filterSets/';

  /**
   * Return list of filter sets.
   *
   * Return list of filter sets for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterSets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterSets$Response(params?: GetFilterSets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>> {
    return getFilterSets(this.http, this.rootUrl, params, context);
  }

  /**
   * Return list of filter sets.
   *
   * Return list of filter sets for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterSets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterSets(params?: GetFilterSets$Params, context?: HttpContext): Observable<Array<(FilterSet | SharedFilterSet)>> {
    return this.getFilterSets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>): Array<(FilterSet | SharedFilterSet)> => r.body)
    );
  }

  /** Path part for operation `addFilterSets()` */
  static readonly AddFilterSetsPath = '/filterSets/';

  /**
   * Add list of filter sets.
   *
   * Add list of filter sets for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFilterSets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFilterSets$Response(params?: AddFilterSets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>> {
    return addFilterSets(this.http, this.rootUrl, params, context);
  }

  /**
   * Add list of filter sets.
   *
   * Add list of filter sets for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFilterSets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFilterSets(params?: AddFilterSets$Params, context?: HttpContext): Observable<Array<(FilterSet | SharedFilterSet)>> {
    return this.addFilterSets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(FilterSet | SharedFilterSet)>>): Array<(FilterSet | SharedFilterSet)> => r.body)
    );
  }

  /** Path part for operation `getFilterSet()` */
  static readonly GetFilterSetPath = '/filterSets/{filtersetid}/';

  /**
   * Return one filter set.
   *
   * Return one filter set with given filter set id for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterSet$Response(params: GetFilterSet$Params, context?: HttpContext): Observable<StrictHttpResponse<(FilterSet | SharedFilterSet)>> {
    return getFilterSet(this.http, this.rootUrl, params, context);
  }

  /**
   * Return one filter set.
   *
   * Return one filter set with given filter set id for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterSet(params: GetFilterSet$Params, context?: HttpContext): Observable<(FilterSet | SharedFilterSet)> {
    return this.getFilterSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<(FilterSet | SharedFilterSet)>): (FilterSet | SharedFilterSet) => r.body)
    );
  }

  /** Path part for operation `updateFilterSet()` */
  static readonly UpdateFilterSetPath = '/filterSets/{filtersetid}/';

  /**
   * Update one filter set.
   *
   * Update one filter set with given filter set id for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFilterSet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFilterSet$Response(params: UpdateFilterSet$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return updateFilterSet(this.http, this.rootUrl, params, context);
  }

  /**
   * Update one filter set.
   *
   * Update one filter set with given filter set id for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFilterSet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFilterSet(params: UpdateFilterSet$Params, context?: HttpContext): Observable<string> {
    return this.updateFilterSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteFilterSet()` */
  static readonly DeleteFilterSetPath = '/filterSets/{filtersetid}/';

  /**
   * Delete one filter set.
   *
   * Delete the one filter set with the given filter set id for the user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFilterSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFilterSet$Response(params: DeleteFilterSet$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deleteFilterSet(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete one filter set.
   *
   * Delete the one filter set with the given filter set id for the user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFilterSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFilterSet(params: DeleteFilterSet$Params, context?: HttpContext): Observable<string> {
    return this.deleteFilterSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `subscribeFilterSet()` */
  static readonly SubscribeFilterSetPath = '/filterSets/{filtersetid}/subscription/';

  /**
   * (Un)Subscribe to one filter set.
   *
   * Subscribe or unsubscribe to one filter set with given filter set id for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscribeFilterSet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscribeFilterSet$Response(params: SubscribeFilterSet$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return subscribeFilterSet(this.http, this.rootUrl, params, context);
  }

  /**
   * (Un)Subscribe to one filter set.
   *
   * Subscribe or unsubscribe to one filter set with given filter set id for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscribeFilterSet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscribeFilterSet(params: SubscribeFilterSet$Params, context?: HttpContext): Observable<string> {
    return this.subscribeFilterSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `retrieveCount()` */
  static readonly RetrieveCountPath = '/filterSets/retrieveCounts/';

  /**
   * Get count of stations contained in filterset.
   *
   * Compute the number of stationIds that match the conditions given in the filterset definition.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveCount$Response(params: RetrieveCount$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FilterSetStationsCounts>>> {
    return retrieveCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Get count of stations contained in filterset.
   *
   * Compute the number of stationIds that match the conditions given in the filterset definition.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `retrieveCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  retrieveCount(params: RetrieveCount$Params, context?: HttpContext): Observable<Array<FilterSetStationsCounts>> {
    return this.retrieveCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FilterSetStationsCounts>>): Array<FilterSetStationsCounts> => r.body)
    );
  }

}
