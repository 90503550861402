import { Component, computed, input, model, output } from '@angular/core';

export const TEXTAREA_STYLES = ['outline', 'inset'] as const;
export type TextareaStyle = typeof TEXTAREA_STYLES[number];

let uniqueId = 0;

/**
 * Simple textarea component
 * 
 * TODO: consider ControlValueAccessor for ReactiveForms
 * 
 * Usage:
 * 
 * ```html
 * <evc-textarea label="Name" placeholder="Enter your name" [(value)]="name" />
 * <evc-textarea
 *     placeholder="Enter your phone"
 *     [rows]="5"
 *     [disabled]="(condition$ | async) === true"
 *     [value]="someSignal()"
 *     (valueChange)="someChange($event)"
 * />
 * ```
 */
@Component({
  selector: 'evc-textarea',
  template: `
    <textarea
        [id]="inputId"
        class="w-100"
        [class.labeled]="label() !== undefined"
        [class.resizable]="resizable()"
        [ngClass]="variant()"
        [placeholder]="placeholder()"
        [disabled]="disabled()"
        [rows]="rows()"
        [(ngModel)]="value"
        [defaultValue]="defaultValue()"
        (blur)="blur.emit($event)"
        (focus)="focus.emit($event)"
    >{{ defaultValue() }}</textarea>
    @if (label() !== undefined) {
        <label [for]="inputId">{{label()}}</label>
    }
  `,
  styleUrl: './textarea.component.scss'
})
export class TextareaComponent {
    readonly inputId = 'evc-textarea-' + uniqueId++;
    readonly label = input<string>();
    readonly placeholder = input<string>('');
    /** number of rows of the txtarea. defaults to 3 */
    readonly rows = input<number>(3);
    readonly value = model<string>('');
    readonly defaultValue = input<string>('');
    readonly disabled = input<boolean>(false);
    readonly resizable = input<boolean>(true);
    /** 
     * Possible values:
     *  - `outline` (default)
     *  - `inset`
     */
    readonly variant = input<TextareaStyle>('outline');

    /** `true` if the value is different from the default value */
    readonly dirty = computed(() => this.value() !== this.defaultValue());

    readonly blur = output<FocusEvent>();
    readonly focus = output<FocusEvent>();

    reset(): void {
        this.value.set(this.defaultValue());
    }
}
