import { Injectable, inject } from "@angular/core";
import { SelectRangeOptions } from "src/app/shared/quick-filters/select-range/select-range.component";
import { addEntities, deleteEntities, entitiesPropsFactory, getEntity, hasEntity, selectAllEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { createStore, select, setProp, withProps } from "@ngneat/elf";
import { localStorageStrategy, persistState } from "@ngneat/elf-persist-state";
import { overviewRepository } from "./overview.repository";
import { ReplaySubject, combineLatest, map, pipe, share, startWith, take, tap } from "rxjs";
import { joinRequestResult } from "@ngneat/elf-requests";
import { TranslateService } from "@ngx-translate/core";
import { LocalizedStateNameService } from "../app-services/localized-state-name.service";
import { EnumeratedState } from "../helpers/state-helper.service";
import { Alert, ChargingStation, Connector, FilterOption, FilterSet, SharedFilterSet } from "../data-backend/models";


/*
*   Quick Filters to choose from
*   options depend on type of filter!
*   - select-multiple, select-single & select-single-radio:
*     Array of: {label: string, value: string} + additional properties
*   - range: lower and upper bounds, only numbers:
*     Object of options for slider (min, max, step, val of first and second handle)
*   - daterange: no options
*/
export interface Filter {
    id: keyof ChargingStation | keyof Connector | string
    label: string
    options?: Array<{ label: string | number, value: string | number }>
    rangePickerOptions?: SelectRangeOptions
    type: 'date-range' | 'date-time-range' | 'range' | 'select-multiple' | 'select-single-radio' | 'select-single'
    required?: boolean, // can optionally be set to express that this filter is required
    value: any // selected value/s, can be overwritten by BE
    inactiveValues?: any // option/s overwritten by BE, can be selected but has no
}

// active filter stored
export interface FilterValue {
    id: string,
    value: any
}

// available filter variables
const { filterVariablesEntitiesRef, withFilterVariablesEntities } = entitiesPropsFactory('filterVariables');
// all filter options for currently selected filter keys (no filters applied)
const { baseFilterOptionsEntitiesRef, withBaseFilterOptionsEntities } = entitiesPropsFactory('baseFilterOptions');
// available filter options with currently selected filters
const { combineableFilterOptionsEntitiesRef, withCombineableFilterOptionsEntities } = entitiesPropsFactory('combineableFilterOptions');
const { filterSetsEntitiesRef, withFilterSetsEntities } = entitiesPropsFactory('filterSets');
const { alertsEntitiesRef, withAlertsEntities } = entitiesPropsFactory('alerts');
const tableFilterStore = createStore(
    { name: 'tableFilters' },
    withFilterVariablesEntities<{key: FilterOption['key'], label: string}, 'key'>({idKey: 'key'}),
    withBaseFilterOptionsEntities<FilterOption, 'key'>({idKey: 'key'}),
    withCombineableFilterOptionsEntities<FilterOption, 'key'>({ idKey: 'key' }),
    withFilterSetsEntities<FilterSet | SharedFilterSet, 'filterSetId'>({ idKey: 'filterSetId' }),
    withAlertsEntities<Alert, 'alertId'>({idKey: 'alertId'})
)

// store for active filter values, separate from fetched data to allow storing in localStorage
const { filterValuesEntitiesRef, withFilterValuesEntities } = entitiesPropsFactory('filterValues');
const activeFiltersStore = createStore(
    {name: 'activeFilters'},
    // keep track of activeFilterSet, does not handle the application of the set -> see applyFilterSet()
    withProps<{ activeFilterSetId: FilterSet['filterSetId'] | SharedFilterSet['filterSetId'] | null }>({activeFilterSetId: null}),
    // active filter values
    withFilterValuesEntities<FilterValue>()
)

const exceptedFilterStore = createStore(
    { name: 'exceptions' },
    withProps<{ exceptions: string[] }>({ exceptions: [] })
)

persistState(activeFiltersStore, {
    key: 'activeTableFilter',
    storage: localStorageStrategy
});

persistState(exceptedFilterStore, {
    key: 'exceptions',
    storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class stationFiltersRepository {
    private _overviewRepo = inject(overviewRepository);
    private _translate = inject(TranslateService);
    private _localizedStateName = inject(LocalizedStateNameService);

    /**
     * Returns human readable name of filter, either from mapping or defaults to 
     * capitalizing first letter and adding spaces where camelCase is found
     * @param key - key of filter to get name of
     * @returns human readable name of filter
     */
    getFilterName(key: string): string {
        const translateMapping = `VAR_NAMES.${key}`;
        const frontendName = this._translate.instant(translateMapping);
        // provide translated varName, if not found destructure camelCased key
        return frontendName !== translateMapping ? frontendName : key[0].toUpperCase() + key.substring(1).replace(/(?=[A-Z][a-z])/g, ' ');
    }

    getBaseFilterOption(key: string): FilterOption | undefined {
        return tableFilterStore.query(getEntity(key, {ref: baseFilterOptionsEntitiesRef}))
    }

    /**
     * Adds a single filter to the active selection
     * @param key - key of filter to add
     * @param value - value of new filter (may be null, resulting in listed filter without active value)
     */
    addFilter(key: string, value: any) {
        // value is nullable, resulting in non-active stored filter, nulled value has to be filtered out before requesting
        if (!activeFiltersStore.query(hasEntity(key, { ref: filterValuesEntitiesRef }))) {
            // check if filter is range and if a restricted range should be applied
            if (value == null || value == undefined) {
                // check if filter is already added to baseFilters
                const baseFilterOption = this.getBaseFilterOption(key);
                // get optional restricted range
                const rangeRestrictions = this.getRangeRestrictions(key as keyof ChargingStation | keyof Connector);
                // only set value if we already know the baseFilterOptions, else keep it null for now
                // the value for range restricted will be set later in the updateBaseFilterOptions method
                if (baseFilterOption && rangeRestrictions) {
                    value = rangeRestrictions
                }
            }
            // adding the filter
            activeFiltersStore.update(addEntities({ id: key, value: value }, { ref: filterValuesEntitiesRef }))
            // update pageNum back to 1
            this._overviewRepo.updatePagination(1)
        }
    }

    /**
     * Deletes one or more filters from the active selection
     * @param key - filter key(s) to delete
     */
    deleteFilters(key: string | string[]) {
        activeFiltersStore.update(deleteEntities(key, { ref: filterValuesEntitiesRef }))
        // update pageNum back to 1
        this._overviewRepo.updatePagination(1)
    }

    /**
     * Helper function to check wether filter is in current active selection
     * @param key - filter key to check
     * @returns true if filter is in current active selection
     */
    hasFilter(key: string): boolean {
        return activeFiltersStore.query(hasEntity(key, { ref: filterValuesEntitiesRef }));
    }

    /**
     * Helper function to get value of filter in current active selection
     * @param key - filter key to get value of
     * @returns FilterValue object of the filter, if it is in the active selection
     */
    getFilterValue(key: string): FilterValue | undefined {
        return activeFiltersStore.query(getEntity(key, { ref: filterValuesEntitiesRef }))
    }

    /**
     * Sets new value for a filter in the active selection
     * @param key filter key
     * @param value new value for filter
     */
    updateFilterValue(key: string, value: any) {
        activeFiltersStore.update(updateEntities(key, { value: value }, { ref: filterValuesEntitiesRef }))

        // update pageNum back to 1
        this._overviewRepo.updatePagination(1)
    }
   
    /**
     * Delets all filters from the active selection
     */
    deleteAllFilters() {
        let allKeys = activeFiltersStore.getValue().filterValuesIds;
        activeFiltersStore.update(deleteEntities(allKeys, { ref: filterValuesEntitiesRef }))
        this.updateActiveFilterSetId(null);
        this._overviewRepo.updatePagination(1)
    }

    /**
     * Resets all active filters to their default nulled values
     */
    resetAllFilters() {
        this.updateActiveFilterSetId(null);
        let activeFilterIds = activeFiltersStore.getValue().filterValuesIds;
        this.baseFilters$.pipe(
            take(1),
            tap((availableFilers) => {
                activeFilterIds.forEach((filterId) => {
                    const filter = availableFilers?.find((filter) => filter.id === filterId);
                    if (filter) this.updateFilterValue(filterId, this.getEmptyFilterValue(filter))
                })
            })
        ).subscribe()
    }
    
    /**
     * Transforms FilterOption[] to Filter[] for use in the frontend
     * @returns Array of transformed Filter objects of the currently active filters
     */
    public transformFilter = () => pipe(
        map((filterOptions: FilterOption[]) => {
            if (filterOptions == null) return null
            return filterOptions.map((res) => {
                let label = res.key ? this.getFilterName(res.key) : '';
                let filter: Filter | null = { id: res.key, label: label } as Filter;
                // map server's backend options to frontend quick filter types
                switch (res.filterType) {
                    case 'category':
                        filter['type'] = 'select-multiple';
                        filter['options'] = res.options
                            .filter((option) => option.toString().length > 0)
                            .map((option) => ({ label: option, value: option }));
                        break
                    case 'boolean':
                        filter['type'] = 'select-single-radio';
                        filter['options'] = res.options
                            .filter((option) => option.toString().length > 0)
                            .map((option) => ({ label: option, value: option }));
                        break
                    case 'range':
                        let min = res.options[0],
                            max = res.options[1];
                        if (typeof (min) === 'number' && typeof (max) === 'number' && !(min === 0 && max === 0) && (min !== max)) {
                            filter['type'] = 'range';
                            const isCoordinate = this.getRangeType(res.key as keyof ChargingStation | keyof Connector) == 'coordinates';
                            const diff = Math.floor(max - min);
                            const diffLength = diff.toString().length;
                            let step = isCoordinate 
                                ? 0.000001
                                : diffLength > 2 ? Math.pow(10, diffLength - 3) : 1;
                            
                            if (diff < 100 && !isCoordinate) {
                                min = Math.floor(min * 100) / 100;
                                max = Math.ceil(max * 100) / 100;
                            } else {
                                min = Math.floor(min);
                                max = Math.ceil(max);
                            }

                            let [firstStart, secondStart] = [min, max];
                            const rangeException = this.getRangeRestrictions(res.key as keyof ChargingStation | keyof Connector);
                            if (rangeException) {
                                [firstStart, secondStart] = rangeException
                            }

                            filter['rangePickerOptions'] = {
                                min, max, step,
                                firstStart,
                                secondStart, 
                                unit: this.getUnit(res.key as keyof ChargingStation | keyof Connector),
                                type: this.getRangeType(res.key as keyof ChargingStation | keyof Connector)
                            };

                            filter['value'] = [firstStart, secondStart]
                        } else {
                            filter = null
                        }
                        break
                    case 'date_range':
                        filter['type'] = 'date-range';
                        break
                }

                return filter
            }).filter((x: Filter | null) => x !== null) as Filter[]
        })
    )

    /**
     * Updates the set of available filter variables (keys) in the table filter store.
     * Maps the keys to human readable names and updates the store.
     * @param value - Array of filter keys to update
     */
    public updateFilterVariables = (value: Array<FilterOption['key']>) => {
        const keysWithLabel = (value ?? []).map((filterVariable) => ({
            key: filterVariable,
            label: this.getFilterName(filterVariable)
        })).sort((a, b) => {
            // sorts alphabetically, but keeps strings starting with numbers at the end of the list
            const aStartsWithNum = /^\d/.test(a.label);
            const bStartsWithNum = /^\d/.test(b.label);

            if (aStartsWithNum && bStartsWithNum) {
                return a.label.localeCompare(b.label);
            } else if (aStartsWithNum) {
                return 1;
            } else if (bStartsWithNum) {
                return -1;
            } else {
                return a.label.localeCompare(b.label);
            }
        });

        tableFilterStore.update(
            setEntities(keysWithLabel, {ref: filterVariablesEntitiesRef})
        )
    }
    
    /**
     * All available filter variables (keys) for the table filters.
     * @returns Array of filter keys and elf request status
     */
    public filterVariables$ = tableFilterStore.pipe(
        selectAllEntities({ref: filterVariablesEntitiesRef}),
        joinRequestResult(['filterVariables'])
    )

    /**
     * Polling status of filterVariables$
     */
    public filterVariablesPolling$ = this.filterVariables$.pipe(
        map(({isLoading}) => isLoading)
    )

    /**
     * Updates base filter options in the table filter store. Base Filter Options contain
     * the full FilterOption object of a selected filterVariable.
     * @param {FilterOption[]} filterOptions - An array of FilterOption objects.
     */
    public updateBaseFilterOptions(filterOptions: FilterOption[]) {
        filterOptions.forEach((filterOption) => {
            const hasFilter = tableFilterStore.query(hasEntity(filterOption.key, {ref: baseFilterOptionsEntitiesRef}));
            if (hasFilter) {
                tableFilterStore.update(
                    updateEntities(filterOption.key, filterOption, {ref: baseFilterOptionsEntitiesRef})
                )
            } else {
                tableFilterStore.update(
                    addEntities(filterOption, {ref: baseFilterOptionsEntitiesRef})
                )

                // check if current activeFilters selection has range filters that need to be restricted
                // if so, check if there are already values assigned, else set restricted range as default value
                const entryInActiveSelection = activeFiltersStore.query(getEntity(filterOption.key, {ref: filterValuesEntitiesRef}));
                if (entryInActiveSelection && (entryInActiveSelection.value == undefined || entryInActiveSelection.value == null)) {
                    const restrictedRange = this.getRangeRestrictions(filterOption.key as keyof ChargingStation | keyof Connector);
                    this.updateFilterValue(filterOption.key, restrictedRange);
                }
            }
        })
    }

    /**
     * Deletes base filter options from the table filter store.
     * @param filterOptionKeys 
     */
    public deleteBaseFilterOptions(filterOptionKeys: Array<FilterOption['key']>) {
        tableFilterStore.update(
            deleteEntities(filterOptionKeys, {ref: baseFilterOptionsEntitiesRef})
        )
    }

    // all filter options for currently selected filter keys (no filters applied)
    public baseFilterOptions$ = tableFilterStore.pipe(
        selectAllEntities({ref: baseFilterOptionsEntitiesRef}),
        joinRequestResult(['baseFilters'])
    )

    public baseFilterOptionsPolling$ = this.baseFilterOptions$.pipe(
        map(({isLoading}) => isLoading)
    )

    // Filter[] from FilterOption[], mapped to correct filter type
    public baseFilters$ = this.baseFilterOptions$.pipe(
        // extract data from RequestResult
        map(({data}) => data),
        // create Filter objects
        this.transformFilter()
    )

    // all combineable filters with current active selection
    updateCombineableFilterOptions(value: FilterOption[]) {
        tableFilterStore.update(
            setEntities(value, { ref: combineableFilterOptionsEntitiesRef })
        )
    }

    // raw filter options of combineable filters with current active selection
    combineableFilterOptions$ = tableFilterStore.pipe(
        selectAllEntities({ ref: combineableFilterOptionsEntitiesRef }),
        joinRequestResult(['combineableFilterOptions'])
    )

    // Filter obj of combineable filters with current active selection
    combineableFilters$ = this.combineableFilterOptions$.pipe(
        map(({ data }) => data),
        this.transformFilter()
    )

    // update filter exceptions
    setFilterExceptions(exceptions: string[]) {
        exceptedFilterStore.update(setProp('exceptions', exceptions))
    }

    // get array of excepted filter keys
    getFilterExceptions(): string[] {
        return exceptedFilterStore.getValue().exceptions;
    }

    // observable returning array of excepted filter keys
    public filterExceptions$ = exceptedFilterStore.pipe(
        select(({exceptions}) => exceptions)
    )

    // all selected filter values 
    activeFilterValues$ = activeFiltersStore.pipe(
        selectAllEntities({ ref: filterValuesEntitiesRef })
    )

    // get fetch status from combineable filters
    combineableFiltersPolling$ = combineLatest({
        request: this.combineableFilterOptions$,
        activeFilters: this.activeFilterValues$
    }).pipe(
        map(({request, activeFilters}) => {
            // if no filters are set, the request will be marked as "loading" by elf, because we started the request pipe but returned before any
            // request started (check pipe in overview-cache.service, where updated values are fetched ~ l 300)
            // thus the filters are only really fetching when any filter is set and the request state is loading
            const anyFiltersActive = activeFilters.some((filterV) => filterV.value !== undefined && filterV.value !== null && filterV.value.length > 0);
            return request.isLoading && anyFiltersActive
        })
    )

    /**
     * selected filters, mapped with selected values
    */
    mappedActiveFilters$ = combineLatest([
        this.activeFilterValues$,
        this.combineableFilters$,
        this.baseFilters$.pipe(
            startWith([])
        )
    ]).pipe(
        // we need allFilters to find inactiveOptions
        map(([filterValues, combineableFilters, baseFilters]) => {
            let out: Filter[] = [];

            // filters with enum states to be translated
            const enumStates = ["lastOverallState", "lastChargingModelState", "lastErrorState", "lastHeartbeatState"];
            // map other translateable filter values with key and their respective translation mapping
            const translateableFilters: Array<{id: Filter['id'], mapping: {[ogValue: string]: string}}> = [{
                id: 'restartSchedule', 
                mapping: {
                    'Weekly restart': 'DETAILS_VIEW.RESTARTS.WEEKLY_RESTART', 
                    'Daily restart': 'DETAILS_VIEW.RESTARTS.DAILY_RESTART'
                }
            }];
            const translateableFilterKeys = translateableFilters.map(x => x.id);

            const anyFiltersActive = filterValues.some((filterV) => filterV.value !== undefined && filterV.value !== null && filterV.value.length > 0);
            // selectedFilters: from FE
            filterValues.forEach((filterValue) => {
                // filter / availableFilters: from BE
                let filter = baseFilters?.find((filter) => filter.id === filterValue.id);
                if (filter) {

                    // translate filter options if possible
                    if (enumStates.includes(filter.id)) {
                        // use localizedStateName service to translate enum states
                        filter.options?.forEach(option => {
                            option.label = this._localizedStateName.instant(option.label as EnumeratedState);
                        });
                    } else if (translateableFilterKeys.includes(filter.id)) {
                        // use translation mapping for other filter options
                        const translationMapping = translateableFilters.find(x => x.id === filter!.id)?.mapping;
                        if (!translationMapping) return;
                        filter.options?.map((option) => {
                            const matchInMapping = translationMapping[option.value];
                            if (matchInMapping) {
                                option.label = this._translate.instant(matchInMapping);
                            }
                            return option;
                        })
                    }

                    // find filter in combinableFitlers, which holds the actually selectable options
                    // ony look for the combineable filter if any filter values are set
                    const combineableFilter = anyFiltersActive 
                        ? combineableFilters?.find((combFilter) => combFilter.id === filter!.id)
                        : undefined;

                    // compare options from allFilters obj with new combinableFilter obj, add non-overlapping as "inactive"
                    // if current filter cant be found in combinableFilter, flag all no options as inactive
                    if (combineableFilter) {
                        const combineableFilterOptionValues = combineableFilter?.options?.map((option) => option.value);
                        const inactiveOptions = combineableFilterOptionValues 
                            ? filter.options?.filter((option) => combineableFilterOptionValues.indexOf(option.value) == -1)
                            : filter.options; // flag all options as inactive if combineable filter is found but not value is given by the be

                        filter.inactiveValues = [...new Set(inactiveOptions?.map((x) => x.value))];
                    }

                    filter.value = filterValue.value === null || filterValue.value === undefined || filterValue.value.length == 0
                        ? this.getEmptyFilterValue(filter)
                        : filterValue.value;

                    if (filter.type === 'range' && filter.rangePickerOptions) {
                        const values = filterValue.value;
                        const restrictedRange = this.getRangeRestrictions(filter.id as keyof ChargingStation | keyof Connector);
                        let receiveNull = false;
                        if (values !== null && values !== undefined) {
                            filter.rangePickerOptions!.firstStart = values[0] == undefined 
                                ? restrictedRange && restrictedRange[0] !== undefined ? restrictedRange[0] : filter.rangePickerOptions!.min 
                                : typeof (values[0]) == 'number' ? values[0] : JSON.parse(values[0]);
                            filter.rangePickerOptions!.secondStart = values[1] == undefined 
                                ? restrictedRange && restrictedRange[1] !== undefined ? restrictedRange[1] : filter.rangePickerOptions!.max 
                                : typeof (values[1]) == 'number' ? values[1] : JSON.parse(values[1]);
                            receiveNull = values[2] == undefined ? false : typeof values[2] == 'number' ? false : JSON.parse(values[2])
                        }
                        filter.value = [filter.rangePickerOptions!.firstStart, filter.rangePickerOptions!.secondStart, receiveNull];
                    }

                    out.push(filter)
                } else {
                    // baseFilters are still fetching at this point, so we're returning a placeholder until values are present
                    out.push({id: filterValue.id, value: filterValue.value, label: this.getFilterName(filterValue.id), type: 'select-multiple', options: []})
                }
            })
            return out
        }),
        share({ connector: () => new ReplaySubject(1) })
    )

    /** 
     * mappedActiveFilters, except ids declared in "filterExceptions"
     */
    mappedActiveExceptedFilters$ = combineLatest({
        mappedActiveFilters: this.mappedActiveFilters$,
        filterExceptions: this.filterExceptions$
    }).pipe(
        map(({mappedActiveFilters, filterExceptions}) => 
            mappedActiveFilters.filter((filter) => !filterExceptions.includes(filter.id))
        )
    )

    // returns correct empty values for filter type
    public getEmptyFilterValue(filter: Filter): any {
        switch (filter.type) {
            case 'select-multiple': case 'range':
                return []
            case 'select-single-radio':
                return ''
            case 'date-range': case 'date-time-range':
                return undefined
        }
    }

    // updates cached dataSets from BE
    updateFilterSets(value: (FilterSet | SharedFilterSet)[]) {
        tableFilterStore.update(
            setEntities(value, { ref: filterSetsEntitiesRef })
        )
    }

    /**
     * Sets the filterSet's list of filters as the active selection. Replaces all currently active filters.
     * @param filterSet - The filterSet to apply to the active selection
     */
    public applyFilterSet(filterSet: FilterSet | SharedFilterSet) {
        // clear currently selected filters
        this.deleteAllFilters()
        // set filterSet as active
        this.updateActiveFilterSetId(filterSet.filterSetId);
        // apply id and value of each filter in set to repo
        filterSet.filterSetDefinition.forEach(({id, value}) => {
            if (id) this.addFilter(id, value);
        })
        this._overviewRepo.setFiltersCollapsed(false)
    }

    // all cached filterSets
    filterSets$ = tableFilterStore.pipe(
        selectAllEntities({ ref: filterSetsEntitiesRef }),
        joinRequestResult(['filterSets'])
    );

    public updateActiveFilterSetId(filterSetId: FilterSet['filterSetId'] | SharedFilterSet['filterSetId'] | null) {
        activeFiltersStore.update(setProp('activeFilterSetId', filterSetId));
    }

    public getActiveFilterSetId() {
        return activeFiltersStore.getValue().activeFilterSetId;
    }

    public getActiveFilterSet() {
        const filterSetId = activeFiltersStore.getValue().activeFilterSetId;
        if (!filterSetId) return null
        return tableFilterStore.state.filterSetsEntities[filterSetId];
    }

    activeFilterSetId$ = activeFiltersStore.pipe(
        select(({ activeFilterSetId }) => activeFilterSetId)
    );

    // updates cached Alerts from BE
    updateAlerts(value: Alert[]) {
        tableFilterStore.update(
            setEntities(value, { ref: alertsEntitiesRef })
        )
    }

    // all cached alerts
    alerts$ = tableFilterStore.pipe(
        selectAllEntities({ ref: alertsEntitiesRef }),
        joinRequestResult(['alerts'])
    )

    /**
     * The function `getUnit` returns the unit of measurement corresponding to a given property ID from
     * a predefined mapping in TypeScript.
     * @param {keyof ChargingStation | keyof Connector} id - The `getUnit` function takes an `id`
     * parameter which can be a key of either `ChargingStation` or `Connector`. The function returns
     * the corresponding unit of measurement for the provided `id`.
     * @returns The `getUnit` function returns the unit of measurement associated with the provided
     * `id`. If the `id` matches a key in the `mapping` object, the corresponding unit of measurement
     * is returned. If the `id` does not match any key in the `mapping` object, an empty string is
     * returned.
     */
    getUnit(id: keyof ChargingStation | keyof Connector): string {
        const mapping: Record<string, string> = {
            connectorPower: ' kW',
            lastChargedEnergy: ' kWh',
            lastHealthIndexValue: '%',
            latitude: '°',
            longitude: '°',
            maxCurrent: ' A',
            maxEvsePower: ' kW',
            maxVoltage: ' V',
            maxPowerLastTwoWeeks: ' W',
            averagePower: ' W',
            averagePowerLastDay: ' W',
            averagePowerLastTwoWeeks: ' W',
            maxPowerLastDay: ' W',
            lastMaxPower: ' W',
            lastAveragePower: ' W'
        };
        if (id in mapping) return mapping[id];
        return '';
    }

    /**
     * Returns the type of range based on the provided id from a predefined
     * mapping.
     * @param {keyof ChargingStation | keyof Connector} id - The `id` parameter in the `getRangeType`
     * function can be either a key of the `ChargingStation` interface or a key of the `Connector`
     * interface.
     * @returns the type of range for the given id, based on the mapping defined in the `mapping` object.
     * If the id matches a key in the `mapping` object, the corresponding range type is returned (e.g.,
     * 'time' for 'lastChargingDuration', 'kW' for power-related values). If the id does not match any 
     * key in the `mapping` object, the function returns `undefined`.
     */
    getRangeType(id: keyof ChargingStation | keyof Connector): SelectRangeOptions['type'] | undefined {
        const mapping: Record<string, SelectRangeOptions['type']> = {
            lastChargingDuration: 'time',
            connectorPower: 'kW',
            maxPowerLastTwoWeeks: 'kW',
            averagePower: 'kW',
            averagePowerLastDay: 'kW',
            averagePowerLastTwoWeeks: 'kW',
            maxPowerLastDay: 'kW',
            lastMaxPower: 'kW',
            lastAveragePower: 'kW',
            averageUsageEnergy: 'kWh',
            latitude: 'coordinates',
            longitude: 'coordinates'
        };
        if (id in mapping) return mapping[id];
        return;
    }

    // returns restricted range, capped by available range
    getRangeRestrictions(id: keyof ChargingStation | keyof Connector): [min: number, max: number] | undefined {
        const mapping: Record<string, [min: number, max: number]> = {
            lastChargingDuration: [0, 86400],
            maxPowerLastTwoWeeks: [0, 1_000_000],
            averagePowerLastTwoWeeks: [0, 1_000_000],
            maxPowerLastDay: [0, 1_000_000],
            lastChargedEnergy: [0, 1_000_000],
            averagePowerLastDay: [0, 1_000_000],
            lastMaxPower: [0, 1_000_000],
            lastAveragePower: [0, 1_000_000],
            maxEvsePower: [0, 400],
            averagePower: [0, 1_000_000]
        };
        if (id in mapping) {
            // check base options
            const baseOption = this.getBaseFilterOption(id);
            const [baseMin, baseMax] = (baseOption?.options ?? []) as number[];
            let [min, max] = mapping[id];
            // only set upper bound of restricted range if it's actually restricting 
            // the max value of the base filter option
            if (baseMax && max > baseMax) {
                max = baseMax
            }

            return [min, max]
        };
        return;
    }
}
