/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChargedAmount } from '../models/charged-amount';
import { ChargersInFailure } from '../models/chargers-in-failure';
import { getChartChargedAmount } from '../fn/dashboard/get-chart-charged-amount';
import { GetChartChargedAmount$Params } from '../fn/dashboard/get-chart-charged-amount';
import { getChartChargersInFailure } from '../fn/dashboard/get-chart-chargers-in-failure';
import { GetChartChargersInFailure$Params } from '../fn/dashboard/get-chart-chargers-in-failure';
import { getLocationsOfChargingStations } from '../fn/dashboard/get-locations-of-charging-stations';
import { GetLocationsOfChargingStations$Params } from '../fn/dashboard/get-locations-of-charging-stations';
import { getNumSessions } from '../fn/dashboard/get-num-sessions';
import { GetNumSessions$Params } from '../fn/dashboard/get-num-sessions';
import { NumSessions } from '../models/num-sessions';
import { StationLocations } from '../models/station-locations';


/**
 * Charging Stations Dashboard Table
 */
@Injectable({ providedIn: 'root' })
export class DashboardService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getChartChargedAmount()` */
  static readonly GetChartChargedAmountPath = '/chargedAmount/';

  /**
   * Gets data for chart 'Charged Amount'.
   *
   * Fetch all necessary data for a chart showing the summed charge amount of all displayed chargers for a given time interval divided into segments of the same length.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChartChargedAmount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargedAmount$Response(params?: GetChartChargedAmount$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChargedAmount>>> {
    return getChartChargedAmount(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets data for chart 'Charged Amount'.
   *
   * Fetch all necessary data for a chart showing the summed charge amount of all displayed chargers for a given time interval divided into segments of the same length.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChartChargedAmount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargedAmount(params?: GetChartChargedAmount$Params, context?: HttpContext): Observable<Array<ChargedAmount>> {
    return this.getChartChargedAmount$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChargedAmount>>): Array<ChargedAmount> => r.body)
    );
  }

  /** Path part for operation `getChartChargersInFailure()` */
  static readonly GetChartChargersInFailurePath = '/chargersInFailure/';

  /**
   * Gets data for chart 'Chargers in Failure'.
   *
   * Fetch all necessary data for a chart showing the amount of chargers with a status "Failure" for a given time interval divided into days.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChartChargersInFailure()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargersInFailure$Response(params?: GetChartChargersInFailure$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChargersInFailure>>> {
    return getChartChargersInFailure(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets data for chart 'Chargers in Failure'.
   *
   * Fetch all necessary data for a chart showing the amount of chargers with a status "Failure" for a given time interval divided into days.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChartChargersInFailure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChartChargersInFailure(params?: GetChartChargersInFailure$Params, context?: HttpContext): Observable<Array<ChargersInFailure>> {
    return this.getChartChargersInFailure$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChargersInFailure>>): Array<ChargersInFailure> => r.body)
    );
  }

  /** Path part for operation `getLocationsOfChargingStations()` */
  static readonly GetLocationsOfChargingStationsPath = '/stationLocations/';

  /**
   * Gets map information of all charging stations.
   *
   * Gets location and ID information from all filtered charging stations, optionally for a specific time interval
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationsOfChargingStations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationsOfChargingStations$Response(params?: GetLocationsOfChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StationLocations>>> {
    return getLocationsOfChargingStations(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets map information of all charging stations.
   *
   * Gets location and ID information from all filtered charging stations, optionally for a specific time interval
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLocationsOfChargingStations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationsOfChargingStations(params?: GetLocationsOfChargingStations$Params, context?: HttpContext): Observable<Array<StationLocations>> {
    return this.getLocationsOfChargingStations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StationLocations>>): Array<StationLocations> => r.body)
    );
  }

  /** Path part for operation `getNumSessions()` */
  static readonly GetNumSessionsPath = '/numSessions/';

  /**
   * Gets number of charging sessions in timeframe aggregated in timesteps.
   *
   * Gets number of charging sessions for a selected timeframe, aggregated in timesteps and zero, short, regular charging sessions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNumSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumSessions$Response(params?: GetNumSessions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NumSessions>>> {
    return getNumSessions(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets number of charging sessions in timeframe aggregated in timesteps.
   *
   * Gets number of charging sessions for a selected timeframe, aggregated in timesteps and zero, short, regular charging sessions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNumSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNumSessions(params?: GetNumSessions$Params, context?: HttpContext): Observable<Array<NumSessions>> {
    return this.getNumSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NumSessions>>): Array<NumSessions> => r.body)
    );
  }

}
