/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ResetResponse } from '../../models/reset-response';

export interface ResetChargingStations$Params {
      body: {

/**
 * Unique identifiers of the Charging Stations
 */
'stationIds'?: Array<string>;
}
}

export function resetChargingStations(http: HttpClient, rootUrl: string, params: ResetChargingStations$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetResponse>> {
  const rb = new RequestBuilder(rootUrl, resetChargingStations.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ResetResponse>;
    })
  );
}

resetChargingStations.PATH = '/chargingStations/reset/';
