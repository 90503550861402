import { transition, trigger, useAnimation } from '@angular/animations';
import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, Observable, catchError, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { NotificationService } from 'src/app/core/app-services';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { StationService } from 'src/app/core/data-backend/data-services';
import { ChargingStation } from 'src/app/core/data-backend/models';
import { fadeIn, fadeOut } from 'src/app/core/helpers/animations';
import { EnumeratedState, StateHelperService } from 'src/app/core/helpers/state-helper.service';
import { overviewRepository } from 'src/app/core/stores/overview.repository';

@Component({
    selector: 'analytics-popup',
    template: `
        <div 
            class="analytics-popup"
            *ngIf="(featuredStation$ | async) as station; else polling"
            [contextMenu]="permService.hasPermission('routes.stationDetails') ? [('COMMON.OPEN_IN_NEW_TAB' | translate)] : []" 
            (onContextOptionSelect)="handleRouting(station.stationId, true)"
            (click)="handleRouting(station.stationId)"
            [@fadeIn]
        >
            <div 
                *ngIf="!(polling$ | async); else pollingInside"
                [@fadeIn]
            >
                <div class="analytics-row">
                    <div *evcHasPermissions="'lastHealthIndexValue'; else plugTile">
                        <div 
                            class="health-index text-left flex-row align-items-center justify-content-start {{ analytics?.healthValue | stateClass }}"
                            [tooltip]="('COMMON.MODELS.HEALTH_INDEX' | translate) + ': ' + (analytics?.healthValue !== null ? analytics?.healthValue + '%' : ' - ')"
                            size="small"
                            toSide="bottom"
                        >
                            <div class="material-icon">
                                {{ analytics?.healthIcon }}
                            </div> 
                            <div class="health-index-value">
                                {{ analytics?.healthValue !== null ? analytics?.healthValue + '%' : ' - ' }}
                            </div>
                        </div>
                        <span>{{ analytics?.lastError }}</span>
                    </div>

                    <div class="flex-column align-items-center justify-content-center">
                        <div class="icon-row">
                            <div 
                                *evcHasPermissions="'lastHeartbeatState'"
                                class="material-icon {{ station.lastHeartbeatState | stateClass }}"
                                [tooltip]="('COMMON.MODELS.HEARTBEAT' | translate) + ': ' + (station.lastHeartbeatState | localizedStateName | async)"
                                size="small"
                                toSide="bottom"
                            >
                                favorite
                            </div> 
                            <div 
                                *evcHasPermissions="'lastChargingModelState'"
                                class="material-icon {{ analytics?.lastChargingModelState | stateClass }}"
                                [tooltip]="('COMMON.MODELS.CHARGING' | translate) + ': ' + (analytics?.lastChargingModelState | localizedStateName | async)"
                                size="small"
                                toSide="bottom"
                            >
                                ev_station
                            </div> 
                            <div 
                                *evcHasPermissions="'lastErrorState'"
                                class="material-icon {{ analytics?.lastErrorState | stateClass }}"
                                [tooltip]="('COMMON.MODELS.ERROR' | translate) + ': ' + (analytics?.lastErrorState | localizedStateName | async)"
                                size="small"
                                toSide="bottom"
                            >
                                warning
                            </div> 
                        </div>
                        <span 
                            *evcHasPermissions="'lastOverallState'"
                            class="state-pill {{ analytics?.lastOverallState | stateClass: 'bg' }}"
                        >
                            {{ analytics?.lastOverallState | localizedStateName | async }}
                        </span>
                    </div>

                    <div>
                        <p>{{ station.address }}</p>
                        <span>
                            {{ station.postalCode ? station.postalCode : ' - ' }}, 
                            {{ station.city ? station.city : ' - ' }},
                            {{ station.countryCode ? station.countryCode : ' - ' }}
                        </span>
                    </div>
                </div>
                <div 
                    class="info-row"
                    [class.reduced-items]="!permService.hasStateModel('lastHealthIndexValue')"
                >
                    <div *evcHasPermissions="'lastHealthIndexValue'">
                        <ng-container *ngTemplateOutlet="plugTile"></ng-container>
                    </div>
                    <div>
                        <p>{{ station.stationId }}</p>
                        <span>{{ station.locationId }}</span>
                    </div>
                    <div>
                        <p>{{ analytics?.lastSessionDate }}</p>
                        <span>{{ 'MAP_VIEW.LAST_SESSION' | translate }}</span>
                    </div>
                    <div>
                        <p class="{{ analytics?.lastChargingModelState | stateClass }}">{{ analytics?.lastSessionStats }}</p>
                        <span>{{ 'MAP_VIEW.LAST_SESSION' | translate }}</span>
                    </div>
                </div>
            </div>
            <!-- Tile for plug info, will be shown instead of HI if permissions are missing -->
            <ng-template #plugTile>
                <div>
                    <p>{{ station.chargerModel }}</p>
                    <span>{{ station.chargerVendor }}</span>
                </div>
            </ng-template>
            <ng-template #pollingInside>
                <ng-container *ngTemplateOutlet="polling"></ng-container>
            </ng-template>
        </div>
        <ng-template #polling>
            <div class="polling-container">
                <div class="analytics-row">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div 
                    class="info-row"
                    [class.reduced-items]="!permService.hasStateModel('lastHealthIndexValue')"
                >
                    <div *evcHasPermissions="'lastHealthIndexValue'"></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </ng-template>
    `,
    styleUrls: ['./analytics-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                useAnimation(fadeIn)
            ])
        ])
    ]
})
export class AnalyticsPopupComponent {
    public featuredStation$: Observable<ChargingStation | null>;
    private _featuredStationId$ = new BehaviorSubject<ChargingStation['stationId'] | null>(null);
    @Input() set stationId(value: ChargingStation['stationId'] | null) {
        this._featuredStationId$.next(value);
    };
    public polling$ = new BehaviorSubject<boolean>(true);

    public analytics: {
        lastSessionDate: string,
        lastSessionStats: string,
        healthIcon: string,
        healthValue: number | null,
        lastError: string,
        lastChargingModelState: EnumeratedState,
        lastErrorState: EnumeratedState,
        lastOverallState: EnumeratedState
    } | null = null

    constructor(
        private _stateHelper: StateHelperService,
        private _overviewRepo: overviewRepository,
        private _notificationService: NotificationService,
        private _stationService: StationService,
        private _router: Router,
        private _translate: TranslateService,
        public permService: PermissionsService
    ) {
        this.featuredStation$ = this._featuredStationId$.pipe(
            withLatestFrom(this._overviewRepo.stations$),
            tap(() => this.polling$.next(true)),
            switchMap(([stationId, cachedStations]) => {
                if (stationId == null) return of(null)
                // try to find station in cache
                const match = cachedStations.data.find((cStation) => cStation.stationId === stationId);
                if (match) return of(match)

                // fetch new station
                return this._stationService.getChargingStation({
                    stationId: stationId
                }).pipe(
                    catchError(() => {
                        this._notificationService.showLocalizedError('MAP_VIEW.ERROR', 'COMMON.ERROR.ONE')
                        return EMPTY
                    })
                )
            }),
            tap((station) => this._setAnalytics(station))
        ) 
    }

    private _setAnalytics(station: ChargingStation | null) {
        if (station) {
            const featuredConnector = this._stateHelper.getConnectorOfLowestState(station, 'lastOverallState');

            this.analytics = {
                lastSessionDate: formatDate(featuredConnector.lastChargingDate, 'LLL dd, yyyy, HH:mm', this._translate.currentLang),
                lastSessionStats: `
                    ${formatDate(featuredConnector.lastChargingDuration, 'HH:mm', 'en')} h 
                    ${ featuredConnector.lastChargedEnergy !== null ? '- ' + featuredConnector.lastChargedEnergy + ' kWh' : ''} 
                `,
                healthIcon: featuredConnector.lastHealthIndexValue == null ? 'gpp_bad' : 'health_and_safety',
                healthValue: featuredConnector.lastHealthIndexValue,
                lastError: featuredConnector.lastError,
                lastChargingModelState: featuredConnector.lastChargingModelState,
                lastErrorState: featuredConnector.lastErrorState,
                lastOverallState: featuredConnector.lastOverallState
            }
        } else {
            this.analytics = null
        }
        this.polling$.next(false)
    }

    // routing in same or new tab, only if user has permission to view stationDetails
    public handleRouting(stationId: ChargingStation['stationId'], newTab: boolean = false) {
        if (!stationId || !this.permService.hasPermission('routes.stationDetails')) return
        const urlTree = this._router.createUrlTree([`/details/${stationId}`]);

        if (newTab) {
            window.open(this._router.serializeUrl(urlTree), '_blank')
        } else {
            this._router.navigateByUrl(urlTree);
        }
    }
}
