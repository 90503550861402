/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ChargingSession } from '../../models/charging-session';

export interface GetChargingSessionsOfChargingStation$Params {

/**
 * Unique identifier of the Charging Station
 */
  stationId: string;

/**
 * The time up to which the list of charging sessions should be displayed
 */
  date?: string;

/**
 * The period in days before 'date' from which all sessions should be returned
 */
  interval?: number;

/**
 * Wether to return all attributes of the charging sessions or a specific subset
 */
  detailed?: boolean;
}

export function getChargingSessionsOfChargingStation(http: HttpClient, rootUrl: string, params: GetChargingSessionsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChargingSession>>> {
  const rb = new RequestBuilder(rootUrl, getChargingSessionsOfChargingStation.PATH, 'get');
  if (params) {
    rb.path('stationId', params.stationId, {});
    rb.query('date', params.date, {});
    rb.query('interval', params.interval, {});
    rb.query('detailed', params.detailed, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ChargingSession>>;
    })
  );
}

getChargingSessionsOfChargingStation.PATH = '/chargingStations/{stationId}/chargingSessions/';
