/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OcppStatusNotificationV1 } from '../../models/ocpp-status-notification-v-1';
import { OcppStatusNotificationV2 } from '../../models/ocpp-status-notification-v-2';

export interface GetStatusNotificationsOfChargingStation$Params {

/**
 * Unique identifier of the Charging Station
 */
  stationId: string;

/**
 * The time up to which the list of status notifications should be displayed
 */
  date?: string;

/**
 * The period in days before 'date' from which all status notifications should be returned
 */
  interval?: number;
}

export function getStatusNotificationsOfChargingStation(http: HttpClient, rootUrl: string, params: GetStatusNotificationsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(OcppStatusNotificationV1 | OcppStatusNotificationV2)>>> {
  const rb = new RequestBuilder(rootUrl, getStatusNotificationsOfChargingStation.PATH, 'get');
  if (params) {
    rb.path('stationId', params.stationId, {});
    rb.query('date', params.date, {});
    rb.query('interval', params.interval, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<(OcppStatusNotificationV1 | OcppStatusNotificationV2)>>;
    })
  );
}

getStatusNotificationsOfChargingStation.PATH = '/chargingStations/{stationId}/ocppStatusNotifications/';
