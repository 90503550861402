/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthBaseService } from '../auth-base-service';
import { AuthApiConfiguration } from '../auth-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomersConfig } from '../models/customers-config';
import { getCustomersConfig } from '../fn/customers-config/get-customers-config';
import { GetCustomersConfig$Params } from '../fn/customers-config/get-customers-config';


/**
 * Customers configuration
 */
@Injectable({ providedIn: 'root' })
export class CustomersConfigService extends AuthBaseService {
  constructor(config: AuthApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCustomersConfig()` */
  static readonly GetCustomersConfigPath = '/customersConfig/';

  /**
   * get config of available customers (identifiers) for the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomersConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersConfig$Response(params?: GetCustomersConfig$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomersConfig>> {
    return getCustomersConfig(this.http, this.rootUrl, params, context);
  }

  /**
   * get config of available customers (identifiers) for the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomersConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomersConfig(params?: GetCustomersConfig$Params, context?: HttpContext): Observable<CustomersConfig> {
    return this.getCustomersConfig$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomersConfig>): CustomersConfig => r.body)
    );
  }

}
