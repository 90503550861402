import { ChangeDetectionStrategy, Component, OnDestroy, Signal, computed } from '@angular/core';
import { BehaviorSubject, catchError, filter, map, Observable, of, shareReplay, Subject, switchMap, takeUntil, tap, zip } from 'rxjs';
import { NotificationService } from '../core/app-services';
import { StationLocations } from '../core/data-backend/models';
import { detailsRepository } from '../core/stores/details.repository';
import { overviewRepository } from '../core/stores/overview.repository';
import { distinctUntilArrayItemChanged } from '@ngneat/elf';
import { stationFiltersRepository } from '../core/stores/station-filters.repository';
import { OverviewCacheService } from '../core/app-services/overview-cache.service';
import { TranslateService } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { filterQueryBuilder } from '../core/helpers/utils.helper';
import { DashboardService } from '../core/data-backend/data-services';

export interface KpiBarVariables {
    mode: string,
    titles: [string, string, string],
    tooltipTexts: [string, string, string],
}
export type KpiBarDataState = 'polling' | 'error' | 'ready' | 'inactive' | null

@Component({
    selector: 'app-kpi-view',
    template: `
        <app-kpi-data-bar
            *evcHasPermissions="'kpiDashboard.dataBars'"
            [kpiBarVariables]="overallKpiVariables()"
            [totalChargers]="this.totalChargers$ | async"
            [chargersAvailable]="this.chargersAvailablePct$ | async"
            [chargersInFailure]="this.chargersInFailure$ | async"
            [totalChargersState]="this.totalChargersState | async"
            [chargersAvailableState]="this.chargersAvailableState | async"
            [chargersInFailureState]="this.chargersInFailureState | async"
        ></app-kpi-data-bar>
        <app-kpi-data-bar
            *evcHasPermissions="'kpiDashboard.dataBars'"
            [kpiBarVariables]="selectedKpiVariables()"
            [totalChargers]="this.selectedChargers$ | async"
            [chargersAvailable]="this.selectedChargersAvailablePct$ | async"
            [chargersInFailure]="this.selectedChargersInFailure$ | async"
            [totalChargersState]="this.selectedChargersState | async"
            [chargersAvailableState]="this.selectedChargersAvailableState | async"
            [chargersInFailureState]="this.selectedChargersInFailureState | async"
        ></app-kpi-data-bar>
        <app-kpi-settings
            [dateRange]="detailsRepo.dateRange$ | async"
            (onDaterangeChange)="detailsRepo.setDateRange($event)"
        ></app-kpi-settings>
        <app-kpi-plots></app-kpi-plots>
    `,
    styleUrls: ['./kpi-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiViewComponent implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();

    // observable that receives the number of overall total chargers
    totalChargers$: Observable<number>;
    totalChargersState: BehaviorSubject<KpiBarDataState> = new BehaviorSubject<KpiBarDataState>(null);

    // observable that receives the number of selected chargers
    selectedChargers$: Observable<number>;
    selectedChargersState: BehaviorSubject<KpiBarDataState> = new BehaviorSubject<KpiBarDataState>(null);

    // observable that receives the number of overall available chargers
    chargersAvailable$: Observable<number>;
    chargersAvailablePct$: Observable<number>;
    chargersAvailableState: BehaviorSubject<KpiBarDataState> = new BehaviorSubject<KpiBarDataState>(null);

    // observable that receives the number of selected available chargers
    selectedChargersAvailable$: Observable<number>;
    selectedChargersAvailablePct$: Observable<number>;
    selectedChargersAvailableState: BehaviorSubject<KpiBarDataState> = new BehaviorSubject<KpiBarDataState>(null);

    // observable that receives the number of overall chargers in failure
    chargersInFailure$: Observable<number>
    chargersInFailureState: BehaviorSubject<KpiBarDataState> = new BehaviorSubject<KpiBarDataState>(null);

    // observable that receives the number of selected chargers in failure
    selectedChargersInFailure$: Observable<number>
    selectedChargersInFailureState: BehaviorSubject<KpiBarDataState> = new BehaviorSubject<KpiBarDataState>(null);

    // variables that will be displayed on the overall data bar
    overallKpiVariables: Signal<KpiBarVariables>;
    // variables that will be displayed on the overall data bar
    selectedKpiVariables: Signal<KpiBarVariables>;
    // attrs that will be excepted from filtering
    filterExceptions: string[] = ['lastOverallState'];

    constructor(
        private _dashboardService: DashboardService,
        private _notificationService: NotificationService,
        private _translate: TranslateService,
        public overviewCacheService: OverviewCacheService,
        public detailsRepo: detailsRepository,
        public overviewRepo: overviewRepository,
        public filtersRepo: stationFiltersRepository
    ) {
        // delete set filters that would influence the KPIs
        this.filtersRepo.deleteFilters(['lastOverallState', 'lastOCPPMessageDate']);

        const newLang = toSignal(this._translate.onLangChange);

        this.overallKpiVariables = computed(() => {
            newLang();
            return {
                mode: this._t('KPI_VIEW.OVERALL'),
                titles: [
                    this._t('KPI_VIEW.TOTAL_STATIONS.TITLE'),
                    this._t('KPI_VIEW.STATIONS_AVAILABLE.TITLE'),
                    this._t('KPI_VIEW.STATIONS_IN_FAILURE.TITLE')
                ],
                tooltipTexts: [
                    this._t('KPI_VIEW.TOTAL_STATIONS.TOOLTIP'),
                    this._t('KPI_VIEW.STATIONS_AVAILABLE.TOOLTIP'),
                    this._t('KPI_VIEW.STATIONS_IN_FAILURE.TOOLTIP')
                ]
            }
        })

        this.selectedKpiVariables = computed(() => {
            const overallTexts = structuredClone(this.overallKpiVariables());
            overallTexts.mode = this._t('KPI_VIEW.SELECTED');
            return overallTexts
        })

        // repo returns all active filters, even newly selected ones with not set value
        // we only want to update data if actual filter values were changed
        const filterValueUpdate$ = filtersRepo.activeFilterValues$.pipe(
            // return a clone of the filters array to allow for manipulations without polluting the state in repo
            map((filters) => structuredClone(filters).filter((filter) => filter.value !== undefined && filter.value !== null && filter.value.length !== 0)),
            distinctUntilArrayItemChanged()
        )

        // daterange - set 'until' value to now and make sure 'from' value is not in the future
        this.detailsRepo.dateRange$.pipe(
            takeUntil(this._destroying$),
            tap((dateRange) => {
                dateRange.until = new Date();
                if (dateRange.from > dateRange.until) {
                    dateRange.from.setDate(dateRange.until.getDate() - 7);
                }
                this.detailsRepo.setDateRange(dateRange);
            }),
        ).subscribe()

        // set filters that will be excepted
        this.filtersRepo.setFilterExceptions(this.filterExceptions);

        // - - - - - OVERALL - - - - - //

        // retrieve total chargers
        this.totalChargers$ = _dashboardService.getLocationsOfChargingStations(
            this.filterQuery([{id: 'lastOCPPMessageDate', value: [new Date().setDate(new Date().getDate()-90), new Date().getTime()]}])
        ).pipe(
            map((stations: StationLocations[]) => {
                this.totalChargersState.next('ready')
                return stations.length
            }),
            catchError(_ => {
                this._notificationService.showError(
                    this._t('KPI_VIEW.ERRORS.OVERALL_STATIONS'),
                    this._t('COMMON.ERROR.ONE')
                )
                this.totalChargersState.next('error')
                this.chargersInFailureState.next('error')
                return of()
            }),
            shareReplay()
        )

        // retrieve available chargers
        this.chargersAvailable$ = _dashboardService.getLocationsOfChargingStations(
            this.filterQuery([{id: 'lastOCPPMessageDate', value: [new Date().setDate(new Date().getDate()-1), new Date().getTime()]},
                {id: 'lastOverallState', value: ['Ok', 'Potential Failure', 'To Be Monitored']}])
        ).pipe(
            map((stations: StationLocations[]) => {
                let amtStations = stations.filter((station) => !station.lastOverallState.includes('Failure')).length;
                return amtStations
            }),
            catchError(_ => {
                this._notificationService.showError(
                    this._t('KPI_VIEW.ERRORS.AVAILABLE_STATIONS'),
                    this._t('COMMON.ERROR.ONE')
                )
                this.chargersAvailableState.next('error')
                this.chargersInFailureState.next('error')
                return of()
            }),
            shareReplay()
        )

        this.chargersAvailablePct$ = zip(
            this.totalChargers$,
            this.chargersAvailable$
        ).pipe(
            map(([totalChargers, chargersAvailable]) => {
                this.chargersAvailableState.next('ready')
                return chargersAvailable / totalChargers;
            })
        )

        // retrieve chargers in failure
        this.chargersInFailure$ = zip(
            this.totalChargers$,
            this.chargersAvailable$
        ).pipe(
            map(([totalChargers, chargersAvailable]) => {
                this.chargersInFailureState.next('ready');
                return totalChargers - chargersAvailable;
            })
        )

        // - - - - - SELECTED - - - - - //

        // retrieve selected chargers
        this.selectedChargers$ = filterValueUpdate$.pipe(
            // we dont need to fetch data for selected chargers when no filters are set
            filter((filterValues) => filterValues.length !== 0),
            tap((_) => {
                this.selectedChargersState.next('polling')
                this.selectedChargersInFailureState.next('polling')
            }),
            switchMap((activeFilters) => {
                return _dashboardService.getLocationsOfChargingStations(
                    this.filterQuery([
                        ...activeFilters,
                        ...[{id: 'lastOCPPMessageDate', value: [new Date().setDate(new Date().getDate()-90), new Date().getTime()]}]
                    ])
                ).pipe(
                    map((stations) => {
                        this.selectedChargersState.next('ready')
                        return stations.length
                    }),
                    catchError(_ => {
                        this._notificationService.showError(
                            this._t('KPI_VIEW.ERRORS.SELECTED_STATIONS'),
                            this._t('COMMON.ERROR.ONE')
                        )
                        this.selectedChargersState.next('error')
                        this.selectedChargersInFailureState.next('error')
                        return of()
                    }),
                )
            }),
            shareReplay()
        )

        // retrieve selected available chargers
        this.selectedChargersAvailable$ = filterValueUpdate$.pipe(
            // we dont need to fetch data for selected chargers when no filters are set
            filter((filterValues) => filterValues.length !== 0),
            tap((_) => {
                this.selectedChargersAvailableState.next('polling')
                this.selectedChargersInFailureState.next('polling')
            }),
            switchMap((activeFilters) => {
                return _dashboardService.getLocationsOfChargingStations(
                    this.filterQuery([
                        ...activeFilters,
                        ...[{id: 'lastOCPPMessageDate', value: [new Date().setDate(new Date().getDate()-1), new Date().getTime()]},
                            {id: 'lastOverallState', value: ['Ok', 'Potential Failure', 'To Be Monitored']}]
                    ])
                ).pipe(
                    map((stations) => {
                        let amtStations = stations.filter((station) => !station.lastOverallState.includes('Failure')).length;
                        return amtStations
                    }),
                    catchError(_ => {
                        this._notificationService.showError(
                            this._t('KPI_VIEW.ERRORS.SELECTED_AVAILABLE_STATIONS'),
                            this._t('COMMON.ERROR.ONE')
                        )
                        this.selectedChargersAvailableState.next('error')
                        this.selectedChargersInFailureState.next('error')
                        return of()
                    }),
                )
            }),
            shareReplay()
        )

        // set selected chargers info to inactive when no filters are set
        filterValueUpdate$.pipe(
            takeUntil(this._destroying$),
            tap((filters) => {
                const currentState = this.selectedChargersState.getValue();
                if (currentState !== 'inactive' && filters.length == 0) {
                    this.selectedChargersState.next('inactive')
                    this.selectedChargersAvailableState.next('inactive')
                    this.selectedChargersInFailureState.next('inactive')
                }
            })
        ).subscribe()

        this.selectedChargersAvailablePct$ = zip(
            this.selectedChargers$,
            this.selectedChargersAvailable$
        ).pipe(
            map(([selectedChargers, selectedChargersAvailable]) => {
                this.selectedChargersAvailableState.next('ready')
                return selectedChargersAvailable / selectedChargers;
            })
        )

        // retrieve selected chargers in failure
        this.selectedChargersInFailure$ = zip(
            this.selectedChargers$,
            this.selectedChargersAvailable$
        ).pipe(
            map(([selectedChargers, selectedChargersAvailable]) => {
                this.selectedChargersInFailureState.next('ready')
                return selectedChargers - selectedChargersAvailable;
            })
        )

    }

    private _t(path: string, interpolateParams?: Object): string {
        return this._translate.instant(path, interpolateParams)
    }

    filterQuery(activeFilters: any[]) {
        activeFilters = this.pickOCPPMessageDate(activeFilters);
        let request: { [key: string]: any } = {};
        request["filters"] = filterQueryBuilder(activeFilters);
        return request
    }

    pickOCPPMessageDate(activeFilters: any[]) {
        return activeFilters.reduce((result, entry) => {
            if (!entry.value) return result
            if (entry.id === 'lastOCPPMessageDate') {
                const existingEntry = result.find((item: any) => item.id === entry.id);
                if (existingEntry) {
                    if (existingEntry.value[1] - existingEntry.value[0] > entry.value[0] - entry.value[1]) {
                        existingEntry.value = entry.value
                    }
                } else {result.push(entry)}
            } else {result.push(entry)}
            return result
        }, []);
    }

    ngOnDestroy(): void {
        this.filtersRepo.setFilterExceptions([]);
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

}
