import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, catchError, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, take, tap, withLatestFrom, share, ReplaySubject, startWith } from 'rxjs';
import { StationColumn, overviewRepository } from 'src/app/core/stores/overview.repository';
import { animate, AUTO_STYLE, style, transition, trigger } from '@angular/animations';
import { appRepository } from 'src/app/core/stores/app.repository';
import { mapRepository } from 'src/app/core/stores/map.repository';
import { GlobalService } from 'src/app/core/app-services/global.service';
import { AdditionalListInformation, selectFilterOption } from './select-filters/select-filters.component';
import { ExportService, NotificationService } from 'src/app/core/app-services';
import { Filter, stationFiltersRepository } from 'src/app/core/stores/station-filters.repository';
import { TranslateService } from '@ngx-translate/core';
import { OverviewCacheService } from 'src/app/core/app-services/overview-cache.service';
import { DataLayerService } from 'src/app/core/app-services/data-layer.service';
import { PermissionsService } from 'src/app/core/app-services/permissions.service';
import { decodeString } from 'src/app/core/helpers/utils.helper';
import { FilterSet, SharedFilterSet } from 'src/app/core/data-backend/models';
import { FilterSetService, StationService } from 'src/app/core/data-backend/data-services';


@Component({
    selector: 'app-station-filters',
    template: `
        <evc-collapsible
            [bodyClasses]="[view === 'map' ? 'pb-8' : 'p-0']"
            [class.kpi-collapsible]="view === 'kpi'"
            [parentClasses]="view === 'map' ? ['filter-collapsible map-collapsible'] : ['filter-collapsible']"
            [fullWidth]="view === 'map'"
            [showChevron]="(selectedFilterKeys$ | async)!.length > 0"
            [active]="(selectedFilterKeys$ | async)!.length > 0"
            [collapsed]="(dropdownCollapsed$ | async) ?? true"
            (collapsedChange)="repo.setFiltersCollapsed($event)"
        >
            <ng-container body>
                @if (buttonInfo$ | async; as buttonInfo) {
                    <div 
                        class="body-wrapper"
                        [class.minimize-slot-right]="!buttonInfo.hasFilterSetPermissions"
                    >
                        <div class="filters" #container>
                            <list-filters
                                [filters]="filtersRepo.mappedActiveExceptedFilters$ | async"
                                [additionalInformation]="additionalFilterInformation"
                                [loading]="filtersPolling$ | async"
                                (onNewFilterValue)="handleFilterInStore($event)"
                                (onDeleteFilter)="deleteFilter($event)"
                                (onResetFilter)="resetFilter($event)"
                            >
                            </list-filters>
                        </div>
                        <div class="pr-8 slot-right flex-row justify-content-end align-items-start">
                            @if (buttonInfo.hasFilterSetPermissions) {
                                <button 
                                    class="save-btn"
                                    [class.loading]="buttonInfo.isLoading"
                                    [disabled]="buttonInfo.disabled"
                                    (click)="handleFilterButton(buttonInfo.action, buttonInfo.filterset)"
                                >
                                    @if (buttonInfo.isLoading) {
                                        <app-preloader
                                            size="small"
                                            type="squares"
                                            color="#94A3B8" 
                                        ></app-preloader>
                                    } @else {
                                        {{ buttonInfo.title }}
                                    }
                                </button>
                            }

                            <button 
                                class="icon-btn reset ml-16"
                                [disabled]="buttonsDisabled$ | async"
                                [tooltip]="'FILTERS.RESET_ALL_FILTERS' | translate"
                                [size]="'small'"
                                (click)="filtersRepo.resetAllFilters()"
                            >
                                <span class="material-icon">rotate_right</span>
                            </button>
                            <button 
                                class="icon-btn ml-8"
                                [disabled]="buttonsDisabled$ | async"
                                [tooltip]="'FILTERS.DELETE_ALL_FILTERS' | translate"
                                [size]="'small'"
                                [toSide]="'left'"
                                (click)="deleteAllManualFilters()"
                            >
                                <span class="material-icon">delete_outline</span>
                            </button>
                        </div>
                    </div>
                }
            </ng-container>
        </evc-collapsible>
        <div 
            class="position-absolute filter-collapsible-header"
            [class.map-header]="view === 'map'"
            [class.kpi-header]="view === 'kpi'"
        >
            <div class="container">
                <div class="filter-collapsible-header-padding flex-row align-items-start no-wrap">

                    @if (view != 'kpi') {
                        <div class="text-wrapper flex-column flex-shrink-1">    
                            <div class="text-cont">
                                <span class="title">
                                    @if (view == 'overview') {
                                        {{ 'COMMON.STATION.OTHER' | translate }}
                                    } @else if (view == 'map') {
                                        {{ 'MAP_VIEW.TITLE' | translate }}
                                    }
                                </span>
                                @if (additionalTitle) {
                                    <span 
                                        class="subline pointer-events-all" 
                                        [tooltip]="additionalTitle"
                                        toSide="top"
                                        size="small"
                                        [@textInOut]
                                    >- {{ additionalTitle }}</span>
                                }
                            </div>
                            <div *ngIf="updatedAt">
                                <span [tooltip]="'COMMON.TIME_OF_LAST_UPDATE' | translate" size="small" toSide="bottom" class="timestamp-subtitle pointer-events-all">{{ updatedAt | localizedDate: 'dd.MM.yyyy HH:mm:ss' }}</span>
                            </div>
                        </div>

                        <div class="spacer"></div>

                        <div
                            *evcHasPermissions="'global.stationFilters.quickFilters'" 
                            class="pointer-events-all"
                        >
                            <evc-overall-state-filter/>
                        </div>
                    }
                        
                    <div class="filters-cont flex-row pointer-events-all flex-shrink-0">
                        <select-filters
                            size="small"
                            [filterOptions]="exceptedAvailableFilterKeys$ | async"
                            [filterOptionsPolling]="(filtersRepo.filterVariablesPolling$ | async) ?? false"
                            [filterSets]="(filtersRepo.filterSets$ | async)?.data || null"
                            [filterSetsPolling]="(filtersRepo.filterSets$ | async)?.fetchStatus === 'fetching' || false"
                            [activeFilters]="selectedFilterKeys$ | async"
                            [additionalInformation]="additionalFilterInformation"
                            [collapsible]="true"
                            [view]="view"
                            [alignment]="(view == 'overview' || view == 'map') ? 'right' : 'left'"
                            [style.max-width]="'100%'"
                            (isOpenChange)="onOpenChange(false)"
                            (activeFiltersChange)="handleSelectedFilters($event)"
                            (selectedFilterSet)="applyFilterSet($event)"
                        >
                        </select-filters>
                    </div>

                    @if (view == 'overview') {
                        <div class="overview-cont flex-row pointer-events-all flex-shrink-0">
                            <div *evcHasPermissions="'dashboard.table.columnSelector'">
                                <div *ngIf="availableTableColumns && availableTableColumns.length > 0">
                                    <app-select-table-columns
                                        [availableTableColumns]="availableTableColumns"
                                        [selectedTableColumns]="selectedTableColumns"
                                    >
                                    </app-select-table-columns>
                                </div>
                            </div>
                            <div 
                                class="fullWidth" 
                                [class.active]="repo.fullWidth$ | async"
                                (click)="repo.toggleFullWidth()"
                            ></div>
                        </div>
                    }

                    @if (view == 'map') {
                        <div class="map-cont flex-row pointer-events-all">
                            <ng-container *evcHasPermissions="'operationMap.chart'">
                                <div 
                                    class="graphs"
                                    (click)="mapRepository.toggleGraphsShown()"
                                    [class.active]="mapRepository.showGraphs$ | async"
                                >
                                    <span class="material-icon">bar_chart</span>
                                </div>
                            </ng-container>
                            <ng-container *evcHasPermissions="'operationMap.fullScreen'">
                                <div 
                                    class="fullscreen-button"
                                    (click)="globalService.toggleFullscreen()"
                                    [class.active]="globalService.isFullscreen$ | async"
                                >
                                    <span class="material-icon">fullscreen</span>
                                </div>
                            </ng-container>
                        </div>
                    }
                </div>
            </div>
        </div>
        <filterset-modal
            *ngIf="modalOpen"
            [(open)]="modalOpen"
            [mode]="'create'"
            [canSwitchType]="true"
            [availableFilters]="allAvailableFilterKeys$ | async"
            [preselectedFilters]="filtersRepo.mappedActiveFilters$ | async"
            [baseFilters]="filtersRepo.baseFilters$ | async"
            [currentFilterSets]="(filtersRepo.filterSets$ | async)?.data || []"
        >
        </filterset-modal>
    `,
    styleUrls: ['./station-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('textInOut', [
            transition(':enter', [
                style({ opacity: 0, width: '0px' }),
                animate('.15s ease-in', 
                style({ opacity: 1, width: AUTO_STYLE }))
            ]),
            transition(':leave', [
                style({ opacity: 1, width: AUTO_STYLE }),
                animate('.15s ease-out', 
                style({ opacity: 0, width: '0px' }))
            ])
        ])
    ],
    host: {
        '[class.position-relative]': 'true',
        '[class.d-block]': 'true'
    }
})

export class StationFiltersComponent {
    // timestamp of latest update
    @Input() updatedAt: Date | null = null;
    // pass through to select-table-columns
    @Input('availableTableColumns') availableTableColumns:  StationColumn[] | null = [];
    @Input('selectedTableColumns')  selectedTableColumns:   StationColumn[] | null = [];
    // controls options and layout for each view this comp is embedded in
    @Input() view: 'overview' | 'map' | 'kpi' = 'overview';
    // show additional information on filter variable in list and filter selector
    @Input() additionalFilterInformation: AdditionalListInformation[] = [];
    // appends a grey text to the title
    @Input() additionalTitle: string | null = null
    // keys of all available filters
    allAvailableFilterKeys$: Observable<selectFilterOption[]>
    // keys of available filters with exceptions
    exceptedAvailableFilterKeys$: Observable<selectFilterOption[]>
    // keys of active, selected filters
    selectedFilterKeys$: Observable<string[]>;
    // pass polling state to comps
    pollingInProgress: boolean = false;
    // num of stations with current request
    numOfStations$: Observable<number>;
    fsTransitioning: boolean = false;
    isFullscreen: boolean = false;
    
    // slightly delay collapsed state to not interfere with main thread (keep animation smooth)
    // and prevent layout bugs (overlapping DOM elements)
    public dropdownCollapsed$ = this.repo.filtersCollapsed$.pipe(
        debounceTime(5)
    );
    // controls disabled state of buttons (save as filter set, reset, delete)
    public buttonsDisabled$ = this.filtersRepo.mappedActiveFilters$.pipe(
        map((filters) => !filters || filters.length == 0),
        debounceTime(10),
        share({ connector: () => new ReplaySubject(1) })
    )
    // alters button action and description based on activeFilterSet
    public buttonInfo$: Observable<{
        title: string, // button description
        action: 'subscribe' | 'unsubscribe' | 'save', // button action
        isLoading: boolean,
        disabled: boolean,
        hasFilterSetPermissions: boolean,
        filterset?: SharedFilterSet
    }>;
    private _filterButtonLoading$ = new BehaviorSubject<boolean>(false);
    // whether overall state information is loading
    pollingOverallStateData: boolean = false;
    // state of "create filterset modal"
    modalOpen: boolean = false;
    // combines polling state of all initial options and combinable options
    public filtersPolling$ = combineLatest([
        this.filtersRepo.baseFilterOptionsPolling$,
        this.filtersRepo.combineableFiltersPolling$
    ]).pipe(
        map(([baseFiltersPolling, combineableFiltersPolling]) => baseFiltersPolling || combineableFiltersPolling),
        distinctUntilChanged()
    )

    constructor(
        private _exportService: ExportService,
        private _filterSetService: FilterSetService,
        private _stationService: StationService,
        private _overviewCacheService: OverviewCacheService,
        private _notificationService: NotificationService,
        private _translate: TranslateService,
        private _dataLayerService: DataLayerService,
        private _permService: PermissionsService,
        public repo: overviewRepository,
        public filtersRepo: stationFiltersRepository,
        public mapRepository: mapRepository,
        public appRepository: appRepository,
        public globalService: GlobalService
    ) {
        this.allAvailableFilterKeys$ = this.filtersRepo.filterVariables$.pipe(
            map(({data}) => data.map((filterVar) => ({
                label: filterVar.label,
                value: filterVar.key
            })))
        )

        this.exceptedAvailableFilterKeys$ = combineLatest({
            availableFilterKeys: this.allAvailableFilterKeys$,
            exceptedFilterKeys: this.filtersRepo.filterExceptions$
        }).pipe(
            map(({availableFilterKeys, exceptedFilterKeys}) => {
                if (!availableFilterKeys) return [];
                return availableFilterKeys.filter(filter => 
                    !exceptedFilterKeys.includes(filter.value)
                );
            })
        )

        this.selectedFilterKeys$ = combineLatest({
            activeFilters: this.filtersRepo.activeFilterValues$,
            exceptedFilterKeys: this.filtersRepo.filterExceptions$
        }).pipe(
            map(({activeFilters, exceptedFilterKeys}) => 
                activeFilters
                    .filter((filter) => !exceptedFilterKeys.includes(filter.id))
                    .map((filter) => filter.id)
            )
        )

        this.buttonInfo$ = combineLatest({
            activeFilterSetId: this.filtersRepo.activeFilterSetId$,
            loading: this._filterButtonLoading$,
            allFilterSets: this.filtersRepo.filterSets$,
            buttonsDisabled: this.buttonsDisabled$,
            newLang: this._translate.onLangChange.pipe(startWith(null)),
            hasFilterSetPermissions: this._permService.ofPermission('routes.filterSets')
        }).pipe(
            map(({activeFilterSetId, allFilterSets, loading, buttonsDisabled, hasFilterSetPermissions}) => {
                const filterSet = allFilterSets.data.find((filterSet) => filterSet.filterSetId == activeFilterSetId);
                const isLoading = loading || allFilterSets.isLoading;
                const disabled = buttonsDisabled || isLoading;
                // keeps code shorter
                const t = (s: string) => this._translate.instant(s);

                if (filterSet && filterSet.isShared) {
                    const isSubscribed = (filterSet as SharedFilterSet).isSubscribed;
                    return {
                        title: isSubscribed ? t('FILTERS.UNSUBSCRIBE_FILTERSET') : t('FILTERS.SUBSCRIBE_FILTERSET'),
                        action: isSubscribed ? 'unsubscribe' : 'subscribe',
                        filterset: filterSet as SharedFilterSet,
                        disabled,
                        isLoading,
                        hasFilterSetPermissions
                    }
                } else {
                    return {
                        title: t('FILTERS.SAVE_FILTERSET'),
                        action: 'save',
                        disabled,
                        isLoading,
                        hasFilterSetPermissions
                    };
                }
            })
        );

        // get total amt of stations
        this.numOfStations$ = this.repo.stationLocations$.pipe(
            map((res) => res.data.stationLocations.length)
        );
    }

    // handles different action types for filterSet button
    public handleFilterButton(action: 'subscribe' | 'unsubscribe' | 'save', filterSet?: SharedFilterSet) {
        if (action == 'save') {
            this.modalOpen = true
        } else if (filterSet) {
            this._filterButtonLoading$.next(true);
            this._filterSetService.subscribeFilterSet({
                filtersetid: filterSet.filterSetId,
                body: {subscribe: !filterSet.isSubscribed}
              }).pipe(
                take(1),
                tap(_ => {
                    const text = !filterSet.isSubscribed ? 'FILTER_SETS_VIEW.INFO.SUBSCRIBED' : 'FILTER_SETS_VIEW.INFO.UNSUBSCRIBED';
                    this._notificationService.showLocalizedSuccess(text);
                    this._overviewCacheService.updateFilterSetsCache();
                    this._overviewCacheService.updateAlertsCache();
                    this._filterButtonLoading$.next(false);
                }),
                catchError(_ => {
                    const text = !filterSet.isSubscribed ? 'FILTER_SETS_VIEW.INFO.ERROR_SUBSCRIBED' : 'FILTER_SETS_VIEW.INFO.ERROR_UNSUBSCRIBED'; 
                    this._notificationService.showLocalizedError(text);
                    this._filterButtonLoading$.next(false);
                    return of([]);
                })
            ).subscribe();
        }
    }

    // adds / removes filters selected in select-multiple
    handleSelectedFilters(selectedKeys: string[]) {
        this.filtersRepo.activeFilterValues$.pipe(
            take(1),
            withLatestFrom(this.filtersRepo.filterExceptions$),
            tap(([activeFilters, filterExceptions]) => {
                // filters currently in store
                // - do not update previously selected filters
                // - filterExceptions are added to the selectedKeys, as they are set by the app itself
                // - remove set filters not included in current selection from store
                // - add new filters from selection

                // disconnect from active filter set
                this.filtersRepo.updateActiveFilterSetId(null);

                selectedKeys = [...selectedKeys, ...filterExceptions]

                const activeFiltersIds = activeFilters.map(filter => filter.id);
                // get activeFilters that are not set in current selection
                const filtersToDelete = activeFiltersIds.filter((activeFilterId) => {
                    return selectedKeys.indexOf(activeFilterId) === -1
                });
                // get filters from current selection that are not yet set in store
                const filtersToAdd = selectedKeys.filter((selectedKey) => {
                    return activeFiltersIds.indexOf(selectedKey) === -1
                })

                const currView = this.view === 'overview' ? 'dashboard' : this.view;

                filtersToDelete.forEach((filter) => {
                    this.filtersRepo.deleteFilters(filter);
                    this._dataLayerService.logFilterSelection('filter-remove', currView, filter);
                })
                filtersToAdd.forEach((filterId) => {
                    this.filtersRepo.addFilter(filterId, null);
                    this._dataLayerService.logFilterSelection('filter-select', currView, filterId);
                })
                
                // open collapsible if new filter(s) added
                if (filtersToAdd.length > 0) this.repo.setFiltersCollapsed(false)
            })
        ).subscribe()
    }

    onOpenChange(collapsed: boolean) {
        // only open collapsible if at least one filter is selected
        this.selectedFilterKeys$.pipe(take(1)).subscribe(keys => {
            if (collapsed || (!collapsed && keys.length > 0)) {
                this.repo.setFiltersCollapsed(collapsed);
            }
        });
    }

    // adds / updates filters in store
    // accessed by updating filter values in collapsible body
    handleFilterInStore(event: [Filter['id'], any]) {
        const [filterId, value] = event;
        
        // disconnect from last filter set when values are modified
        this.filtersRepo.updateActiveFilterSetId(null);

        if (this.filtersRepo.hasFilter(filterId)) {
            this.filtersRepo.updateFilterValue(filterId, value)
        } else {
            this.filtersRepo.addFilter(filterId, value)
        }
    }

    deleteFilter(filterId: Filter['id']) {
        this.filtersRepo.updateActiveFilterSetId(null);
        this.filtersRepo.deleteFilters(filterId);
        const currView = this.view === 'overview' ? 'dashboard' : this.view;
        this._dataLayerService.logFilterSelection('filter-remove', currView, filterId);
    }

    resetFilter(filter: Filter) {
        this.filtersRepo.updateActiveFilterSetId(null);
        this.filtersRepo.updateFilterValue(filter.id, this._getEmptyFilterValue(filter));
    }

    applyFilterSet(filterSet: FilterSet | SharedFilterSet) {
        this.filtersRepo.applyFilterSet(filterSet);
        const filterName = decodeString(filterSet.name);
        this._notificationService.showSuccess(this._translate.instant('DASHBOARD.ALERTS.FILTER_SET_APPLIED', {content: filterName}));
    }

    private _getEmptyFilterValue(filter: Filter): any {
        switch (filter.type) {
            case 'select-multiple': case 'range':
                return []
            case 'select-single-radio':
                return ''
            case 'date-range': case 'date-time-range':
                return undefined
        }
    }

    public deleteAllManualFilters() {
        this.filtersRepo.activeFilterValues$.pipe(
            take(1),
            withLatestFrom(this.filtersRepo.filterExceptions$),
            tap(([activeFilters, filterExceptions]) => {
                // only delete active filters that were manually set by the user
                // except filterExceptions, as they are managed by the app
                const filtersToDelete = activeFilters
                    .filter((filter) => !filterExceptions.includes(filter.id))
                    .map((filter) => filter.id);

                const currView = this.view === 'overview' ? 'dashboard' : this.view;
                filtersToDelete.forEach((filterId) => this._dataLayerService.logFilterSelection('filter-remove', currView, filterId));

                this.filtersRepo.deleteFilters(filtersToDelete);
            })
        ).subscribe()
    }
}
