import { ChangeDetectionStrategy, Component, computed, input, output, TemplateRef } from '@angular/core';
import { RowContent, TableCellActionEvent, TableColumn, TableHeaderVM } from '../table.component';
import { animate, style, transition, trigger } from '@angular/animations';

// meta info and subrows for rows in matrix, on index 0
export type RowMeta = {
    id: number,
    bulkActionDisabled: boolean,
    subrowsEntries?: any[],
    subrows?: any[], 
    // controls visible state of subrows / subTable
    rowToggled?: boolean,
    groupIdentifier?: string,
    subTable?: {
        entries: any[],
        columns: TableColumn[],
        config: {
            // collection w # of table cells on the left (prepared for iteration in template)
            fromCol: number[],
            colSpan: number,
            defaultSortByKey?: string,
            defaultSortDirection?: 'asc' | 'desc'
        }
    }
};

@Component({
    selector: 'tbody[evc-table-row]',
    template: `
        @if (vm(); as vm) {
            <tr
                [class.synced]="vm.isSynced"
                [class.first-of-group]="vm.isFirstOfGroup"
                [class.last-of-group]="vm.isLastOfGroup && !vm.rowMeta.rowToggled"
            >
                <!-- optional selection for bulk actions -->
                @if (vm.hasBulkActions) {
                    <td class="leading sticky-checkbox">
                        @if (!vm.rowMeta.bulkActionDisabled) {
                            <input
                                type="checkbox"
                                name="row-{{ vm.rowMeta.id }}"
                                id="row-{{ vm.rowMeta.id }}"
                                (change)="onBulkActionToggled.emit(vm.rowMeta.id)"
                                [checked]="vm.isBulkActionChecked"
                            >
                        }
                    </td>
                }
                <!-- matrix rows -->
                @for (cell of vm.row; track i; let i = $index) {
                    @if (vm.tableHeaders[i]; as tableHeader) {
                        <td
                            [class.leading]="tableHeader.column.config?.leading"
                            [class.trailing]="tableHeader.column.config?.trailing"
                            [class.no-padding]="cell.renderRef !== undefined"
                            [class.sync-active]="vm.canSync"
                            (click)="vm.canSync && onSyncRow.emit(vm.rowMeta.id)"
                        >
                            @if (cell.renderRef) {
                                <!-- Render provided template -->
                                <ng-container *ngTemplateOutlet="cell.renderRef; context: {$implicit: cell.value}"></ng-container>
                            }
                            <div class="flex-row align-items-center">
                                <!-- Cell Actions -->
                                @if (tableHeader.column.actions) {
                                    <div class="actions">
                                        @for (action of cell.actions; track action.id) {
                                            @if (action.condition == undefined || action.condition) {
                                                <button
                                                    [tooltip]="action.title"
                                                    [size]="'small'"
                                                    type="button"
                                                    (click)="onCellAction.emit({actionId: action.id, row: vm.rowMeta.id})"
                                                >
                                                    <div
                                                        [innerHTML]="action.label"
                                                    ></div>
                                                </button>
                                            }
                                        }
                                    </div>
                                }
                                <!-- Cell Content -->
                                @if (cell.tooltip && cell.label != '') {
                                    <!-- With Tooltip -->
                                    <div
                                        class="has-tooltip {{ tableHeader.className }}"
                                        [innerHTML]="cell.label"
                                        [tooltip]="cell.tooltip.text ?? null"
                                        [toSide]="cell.tooltip.toSide || 'top'"
                                        [size]="cell.tooltip.size || 'large'"
                                        [textAlign]="cell.tooltip.textAlign || 'center'"
                                        [width]="cell.tooltip.width || undefined"
                                    ></div>
                                } @else if (!cell.renderRef && cell.label != '') {
                                    <!-- Without Tooltip -->
                                    <div
                                        class="cell-content {{ tableHeader.className }}"
                                        [innerHTML]="cell.label"
                                    ></div>
                                }
                            </div>
                        </td>
                    }
                }
                @if (vm.rowMeta.subrowsEntries || vm.rowMeta.subTable) {
                    <td class="trailing">
                        <button
                            class="expand-row"
                            [class.expanded]="vm.toggleSubrowIcons == null && vm.rowMeta.rowToggled"
                            [class.custom-icons]="vm.toggleSubrowIcons !== null"
                            [class.active]="vm.toggleSubrowIcons !== null && vm.rowMeta.rowToggled"
                            (click)="onToggleSubrow.emit(vm.rowMeta.id)"
                        >
                            @if (vm.toggleSubrowIcons !== null) {
                                <!-- use special icons -->
                                <span class="material-icon">{{ vm.toggleSubrowIcons[vm.rowMeta.rowToggled ? 1 : 0] }}</span>
                            } @else {
                                <!-- default chevron -->
                                <span class="material-icon">expand_more</span>
                            }
                        </button>
                    </td>
                }
                <!-- adds a '+' at the end of a row to show a modal with custom content -->
                @if (vm.hasModal) {
                    <td class="trailing">
                        <div class="open-modal">
                            <div class="material-icon">add</div>
                            <ng-content *ngTemplateOutlet="vm.modalTemplateRef; context:{row: vm.rowWithMeta}"></ng-content>
                        </div>
                    </td>
                }
            </tr>

            <!-- S U B R O W S -->
            @if (vm.rowMeta.subrows && vm.rowMeta.rowToggled === true) {
                @for (row of vm.rowMeta.subrows; track i; let i = $index) {
                    <tr [class.last-of-group]="vm.isLastOfGroup && i === vm.rowMeta.subrows.length - 1">
                        @for (cell of row; track i; let i = $index) {
                            @if (vm.tableHeaders[i]; as tableHeader) {
                                <td
                                    [class.leading]="tableHeader.column.config?.leading"
                                    [class.trailing]="tableHeader.column.config?.trailing"
                                >
                                    <div
                                        [class]="tableHeader.className"
                                        [innerHTML]="cell.label"
                                        [class.has-tooltip]="cell.tooltip"
                                        [tooltip]="cell.tooltip?.text || null"
                                        [toSide]="cell.tooltip?.toSide || 'top'"
                                        [size]="cell.tooltip?.size || 'large'"
                                        [textAlign]="cell.tooltip?.textAlign || 'center'"
                                        [width]="cell.tooltip?.width || undefined"
                                    ></div>
                                </td>
                            }
                        }
                        <!-- fill last table cell, where parent has toggle icon -->
                        <td class="trailing"></td>
                    </tr>
                }
            }

            <!-- S U B T A B L E -->
            @if (vm.rowMeta.subTable && vm.rowMeta.rowToggled === true) {
                <tr
                    [class.last-of-group]="vm.isLastOfGroup"
                >
                    @for (i of vm.rowMeta.subTable.config.fromCol; track $index) {
                        <td
                            [class.leading]="vm.tableHeaders[$index].column.config?.leading"
                            [class.trailing]="vm.tableHeaders[$index].column.config?.trailing"
                        ></td>
                    }
                    <td [attr.colspan]="vm.rowMeta.subTable.config.colSpan">
                        <div class="subtable" [@heightAnimation]>
                            <evc-table
                                [columns]="vm.rowMeta.subTable.columns"
                                [rows]="vm.rowMeta.subTable.entries"
                                [resizeable]="false"
                                [scrollable]="false"
                                [defaultSortByKey]="vm.rowMeta.subTable.config.defaultSortByKey ?? null"
                                [defaultSortDirection]="vm.rowMeta.subTable.config.defaultSortDirection ?? null"
                                (onCellAction)="onSubTableAction.emit({event: $event, subTable: vm.rowMeta.subTable})"
                            >
                            </evc-table>
                        </div>
                    </td>
                    <td class="trailing"></td>
                </tr>
            }
        }
    `,
    styleUrl: './table-row.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.synced]': 'isSynced()',
    },
    animations: [
        trigger('heightAnimation', [
            transition(':enter', [
                style({ height: 0 }),
                animate('.2s ease-out', style({ height: '*' }))
            ]),
            transition(':leave', [
                style({ height: '*' }),
                animate('.25s ease-in', style({ height: 0 }))
            ])
        ])
    ]
})
export class TableRowComponent {
    // Inputs
    public row = input<[RowMeta, ...RowContent[]] | null>(null);
    public tableHeaders = input<TableHeaderVM[] | null>(null);
    public groupIndex = input<number | null>(null);
    public groupSize = input<number | null>(null);
    public syncItem = input<number | null>(null);
    public canSync = input<boolean>(false);
    public hasBulkActions = input<boolean>(false);
    public toggledBulkActions = input<(number | string)[] | null>(null);
    public toggleSubrowIcons = input<[string, string] | null>(null);
    public hasModal = input<boolean>(false);
    public modalTemplateRef = input<TemplateRef<any> | null>(null);

    // Outputs
    public onBulkActionToggled = output<number>();
    public onCellAction = output<TableCellActionEvent>();
    public onSubTableAction = output<{ event: TableCellActionEvent, subTable: RowMeta['subTable'] }>();
    public onToggleSubrow = output<number>();
    public onSyncRow = output<number>();

    // derived
    private _rowContent = computed(() => this.row()?.slice(1));
    private _rowMeta = computed(() => this.row()?.[0]);

    public isSynced = computed(() => {
        const syncedId = this.syncItem();
        const rowMeta = this._rowMeta();
        return syncedId !== null && rowMeta !== undefined && rowMeta.id === syncedId;
    });

    public vm = computed(() => {
        const row = this._rowContent();
        const rowMeta = this._rowMeta();
        const tableHeaders = this.tableHeaders();

        if (!row || !rowMeta || !tableHeaders) return null;

        const toggledBulkActions = this.toggledBulkActions();
        const isBulkActionChecked = toggledBulkActions && toggledBulkActions.includes(rowMeta.id);
        const groupIndex = this.groupIndex();
        const groupSize = this.groupSize();
        const isFirstOfGroup = groupIndex === 0;
        const isLastOfGroup = groupSize !== null && groupIndex === groupSize - 1;

        return {
            row: row as RowContent[],
            rowMeta: rowMeta satisfies RowMeta,
            rowWithMeta: [rowMeta, ...row],
            canSync: this.canSync(),
            isSynced: this.isSynced(),
            hasBulkActions: this.hasBulkActions(),
            toggleSubrowIcons: this.toggleSubrowIcons(),
            hasModal: this.hasModal(),
            modalTemplateRef: this.modalTemplateRef(),
            tableHeaders,
            isBulkActionChecked,
            isFirstOfGroup,
            isLastOfGroup
        }
    });
}
