/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addAlert } from '../fn/alert/add-alert';
import { AddAlert$Params } from '../fn/alert/add-alert';
import { Alert } from '../models/alert';
import { deleteAlert } from '../fn/alert/delete-alert';
import { DeleteAlert$Params } from '../fn/alert/delete-alert';
import { getAlert } from '../fn/alert/get-alert';
import { GetAlert$Params } from '../fn/alert/get-alert';
import { getAlerts } from '../fn/alert/get-alerts';
import { GetAlerts$Params } from '../fn/alert/get-alerts';
import { updateAlert } from '../fn/alert/update-alert';
import { UpdateAlert$Params } from '../fn/alert/update-alert';


/**
 * Alerts for Filter Sets
 */
@Injectable({ providedIn: 'root' })
export class AlertService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAlerts()` */
  static readonly GetAlertsPath = '/alerts/';

  /**
   * Return list of alerts.
   *
   * Return list of alerts for user with given uuid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAlerts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlerts$Response(params?: GetAlerts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Alert>>> {
    return getAlerts(this.http, this.rootUrl, params, context);
  }

  /**
   * Return list of alerts.
   *
   * Return list of alerts for user with given uuid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAlerts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlerts(params?: GetAlerts$Params, context?: HttpContext): Observable<Array<Alert>> {
    return this.getAlerts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Alert>>): Array<Alert> => r.body)
    );
  }

  /** Path part for operation `addAlert()` */
  static readonly AddAlertPath = '/alerts/';

  /**
   * Create an alert.
   *
   * Add an alert to the list of alerts for user with given uuid and filterset id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAlert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAlert$Response(params: AddAlert$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return addAlert(this.http, this.rootUrl, params, context);
  }

  /**
   * Create an alert.
   *
   * Add an alert to the list of alerts for user with given uuid and filterset id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAlert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAlert(params: AddAlert$Params, context?: HttpContext): Observable<string> {
    return this.addAlert$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getAlert()` */
  static readonly GetAlertPath = '/alerts/{alertId}/';

  /**
   * Return alert.
   *
   * Return the alert which is registered under the alertId given.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAlert()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlert$Response(params: GetAlert$Params, context?: HttpContext): Observable<StrictHttpResponse<Alert>> {
    return getAlert(this.http, this.rootUrl, params, context);
  }

  /**
   * Return alert.
   *
   * Return the alert which is registered under the alertId given.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAlert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAlert(params: GetAlert$Params, context?: HttpContext): Observable<Alert> {
    return this.getAlert$Response(params, context).pipe(
      map((r: StrictHttpResponse<Alert>): Alert => r.body)
    );
  }

  /** Path part for operation `updateAlert()` */
  static readonly UpdateAlertPath = '/alerts/{alertId}/';

  /**
   * Update alert.
   *
   * Update an existing alert by alert id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAlert()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAlert$Response(params: UpdateAlert$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return updateAlert(this.http, this.rootUrl, params, context);
  }

  /**
   * Update alert.
   *
   * Update an existing alert by alert id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAlert$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAlert(params: UpdateAlert$Params, context?: HttpContext): Observable<string> {
    return this.updateAlert$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `deleteAlert()` */
  static readonly DeleteAlertPath = '/alerts/{alertId}/';

  /**
   * Delete one alert.
   *
   * Delete the alert by the given alert id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAlert()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAlert$Response(params: DeleteAlert$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deleteAlert(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete one alert.
   *
   * Delete the alert by the given alert id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAlert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAlert(params: DeleteAlert$Params, context?: HttpContext): Observable<string> {
    return this.deleteAlert$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
