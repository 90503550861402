/* tslint:disable */
/* eslint-disable */
/* 
 * Code generated by ng-openapi-gen.
 * Based on handlebars/data-backend/configuration.handlebars
 * DO NOT EDIT. 
 */
import { Injectable } from '@angular/core';
import { filter, ReplaySubject, share, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { appRepository } from '../stores/app.repository';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Global configuration
 */
@Injectable({
    providedIn: 'root',
})
export class DataApiConfiguration {
    private _environment = environment;
    private _rootUrl: string = '';

    rootBaseUrl: string = '';
    rootPath: string = '';

    constructor(
        private _appRepo: appRepository
    ) {
        // set host and path from environment
        this.rootBaseUrl = this._environment.dataBaseUrl
        this.rootPath = this._environment.dataPath

        // listen to changes in customer, update path
        this._appRepo.selectedCustomerId$.pipe(
            takeUntilDestroyed(),
            filter((customerId) => customerId !== null && customerId !== ''),
            tap((customerId) => {
                this._rootUrl = this.rootBaseUrl + customerId + this.rootPath
            }),
            share({ connector: () => new ReplaySubject(1) })
        ).subscribe()
    }

    get rootUrl(): string {
        return this._rootUrl
    }
}

/**
 * Parameters for `DataApiModule.forRoot()`
 */
export interface DataApiConfigurationParams {
  rootUrl?: string;
}
