import { ChangeDetectionStrategy, Component, computed, input, model, output } from '@angular/core';

let uniqueId = 0;

export type InputType = 'text' | 'number' | 'password' | 'url' | 'email' | 'tel';

/**
 * Simple input component
 * 
 * TODO: consider ControlValueAccessor for ReactiveForms
 * 
 * Usage:
 * 
 * ```html
 * <evc-input label="Name" placeholder="Enter your name" [(value)]="name" />
 * <evc-input
 *     placeholder="Enter your phone"
 *     type="tel"
 *     [disabled]="(condition$ | async) === true"
 *     [value]="someSignal()"
 *     (valueChange)="someChange($event)"
 * />
 * ```
 */
@Component({
  selector: 'evc-input',
  template: `
    <input
        [id]="inputId"
        [class.labeled]="label() !== undefined"
        type="text"
        [placeholder]="placeholder()"
        [disabled]="disabled()"
        [defaultValue]="defaultValue()"
        [(ngModel)]="value"
        (blur)="blur.emit($event)"
        (focus)="focus.emit($event)"
    />
    @if (label() !== undefined) {
        <label [for]="inputId">{{label()}}</label>
    }
  `,
  styleUrl: './input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent {
    readonly inputId = 'evc-input-' + uniqueId++;
    readonly type = input<InputType>('text');
    readonly label = input<string>();
    readonly placeholder = input<string>('');
    readonly value = model<string>('');
    readonly defaultValue = input<string>('');
    readonly disabled = input<boolean>(false);

    /** Returns true if the value is different from the default value */
    readonly dirty = computed(() => this.value() !== this.defaultValue());

    readonly blur = output<FocusEvent>();
    readonly focus = output<FocusEvent>();

    reset(): void {
        console.log(this.defaultValue(), this.value())
        this.value.set(this.defaultValue());
    }
}
