/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface DeleteOverwrites$Params {

/**
 * Unique identifiers of the ChargingStation
 */
  stationIds: Array<string>;

/**
 * Field names of the ChargingStation whose overwrite values shall be deleted
 */
  fields?: Array<string>;
}

export function deleteOverwrites(http: HttpClient, rootUrl: string, params: DeleteOverwrites$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, deleteOverwrites.PATH, 'delete');
  if (params) {
    rb.query('stationIds', params.stationIds, {});
    rb.query('fields', params.fields, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

deleteOverwrites.PATH = '/overwrites/';
