/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Incident } from '../../models/incident';

export interface GetDetailedInformation$Params {

/**
 * IncidentId
 */
  incidentId?: string;
}

export function getDetailedInformation(http: HttpClient, rootUrl: string, params?: GetDetailedInformation$Params, context?: HttpContext): Observable<StrictHttpResponse<Incident & {

/**
 * Success of the last restart
 */
'isLastRestartSuccessful'?: boolean;

/**
 * Prioirity of the charger
 */
'chargerPriority'?: 'Low' | 'Medium' | 'High';

/**
 * Recommendation on the incident
 */
'incidentRecommendation'?: string;

/**
 * Timestamp of last restart attempt
 */
'lastRestartAttemptDate'?: string;

/**
 * Timestamp of last successful restart
 */
'lastSuccessfulRestartDate'?: string;

/**
 * Date and time of last regular charging session
 */
'lastRegularChargingDate'?: string;

/**
 * The model of the station
 */
'chargerModel'?: string;

/**
 * CPO name
 */
'cpoName'?: string;

/**
 * status of the station's sim card
 */
'simCardStatus'?: 'online' | 'offline' | 'none';

/**
 * Timestamp of last change in the latest ticket of the charging station
 */
'ticketModifiedOn'?: string;

/**
 * The EVSE-ID of the connector
 */
'evseId'?: string;

/**
 * Name of the EVSE
 */
'evseName'?: string;

/**
 * The plug type of the connector
 */
'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';

/**
 * The current type of the connector. AC: Alternating Current DC: Direct Current
 */
'currentType'?: 'AC' | 'DC';

/**
 * Last known calculated overall state of connector
 */
'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';

/**
 * Last known error of connector or None
 */
'lastError'?: string;

/**
 * Date and time of last error
 */
'lastErrorDate'?: string;
} & {
}>> {
  const rb = new RequestBuilder(rootUrl, getDetailedInformation.PATH, 'get');
  if (params) {
    rb.query('incidentId', params.incidentId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Incident & {
      
      /**
       * Success of the last restart
       */
      'isLastRestartSuccessful'?: boolean;
      
      /**
       * Prioirity of the charger
       */
      'chargerPriority'?: 'Low' | 'Medium' | 'High';
      
      /**
       * Recommendation on the incident
       */
      'incidentRecommendation'?: string;
      
      /**
       * Timestamp of last restart attempt
       */
      'lastRestartAttemptDate'?: string;
      
      /**
       * Timestamp of last successful restart
       */
      'lastSuccessfulRestartDate'?: string;
      
      /**
       * Date and time of last regular charging session
       */
      'lastRegularChargingDate'?: string;
      
      /**
       * The model of the station
       */
      'chargerModel'?: string;
      
      /**
       * CPO name
       */
      'cpoName'?: string;
      
      /**
       * status of the station's sim card
       */
      'simCardStatus'?: 'online' | 'offline' | 'none';
      
      /**
       * Timestamp of last change in the latest ticket of the charging station
       */
      'ticketModifiedOn'?: string;
      
      /**
       * The EVSE-ID of the connector
       */
      'evseId'?: string;
      
      /**
       * Name of the EVSE
       */
      'evseName'?: string;
      
      /**
       * The plug type of the connector
       */
      'socketType'?: 'Mennekes' | 'Schuko' | 'CHAdeMO' | 'CCS' | 'Type 3' | 'Pantograph' | 'Type 1';
      
      /**
       * The current type of the connector. AC: Alternating Current DC: Direct Current
       */
      'currentType'?: 'AC' | 'DC';
      
      /**
       * Last known calculated overall state of connector
       */
      'lastOverallState'?: 'Ok' | 'To Be Monitored' | 'Potential Failure' | 'Failure' | 'No Data';
      
      /**
       * Last known error of connector or None
       */
      'lastError'?: string;
      
      /**
       * Date and time of last error
       */
      'lastErrorDate'?: string;
      } & {
      }>;
    })
  );
}

getDetailedInformation.PATH = '/incidents/getDetailedInformation';
