/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataBaseService } from '../data-base-service';
import { DataApiConfiguration } from '../data-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FilterOption } from '../models/filter-option';
import { getFilterOption } from '../fn/filter/get-filter-option';
import { GetFilterOption$Params } from '../fn/filter/get-filter-option';
import { getFilterOptions } from '../fn/filter/get-filter-options';
import { GetFilterOptions$Params } from '../fn/filter/get-filter-options';


/**
 * Filters for Charging Stations
 */
@Injectable({ providedIn: 'root' })
export class FilterService extends DataBaseService {
  constructor(config: DataApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFilterOptions()` */
  static readonly GetFilterOptionsPath = '/filterOptions/';

  /**
   * Gets list of fields for filters.
   *
   * Fetch a list of fields for the filter dropdowns
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions$Response(params?: GetFilterOptions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getFilterOptions(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets list of fields for filters.
   *
   * Fetch a list of fields for the filter dropdowns
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOptions(params?: GetFilterOptions$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getFilterOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `getFilterOption()` */
  static readonly GetFilterOptionPath = '/filterOptions/{filterVariable}/';

  /**
   * Gets full filter option object.
   *
   * Fetch a filter option object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFilterOption()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOption$Response(params: GetFilterOption$Params, context?: HttpContext): Observable<StrictHttpResponse<FilterOption>> {
    return getFilterOption(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets full filter option object.
   *
   * Fetch a filter option object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFilterOption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFilterOption(params: GetFilterOption$Params, context?: HttpContext): Observable<FilterOption> {
    return this.getFilterOption$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilterOption>): FilterOption => r.body)
    );
  }

}
