/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OcppAuthorizationV1 } from '../../models/ocpp-authorization-v-1';
import { OcppAuthorizationV2 } from '../../models/ocpp-authorization-v-2';

export interface GetAuthorizationsOfChargingStation$Params {

/**
 * Unique identifier of the Charging Station
 */
  stationId: string;

/**
 * The time up to which the list of authorizations should be displayed
 */
  date?: string;

/**
 * The period in days before 'date' from which all authorizations should be returned
 */
  interval?: number;
}

export function getAuthorizationsOfChargingStation(http: HttpClient, rootUrl: string, params: GetAuthorizationsOfChargingStation$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(OcppAuthorizationV1 | OcppAuthorizationV2)>>> {
  const rb = new RequestBuilder(rootUrl, getAuthorizationsOfChargingStation.PATH, 'get');
  if (params) {
    rb.path('stationId', params.stationId, {});
    rb.query('date', params.date, {});
    rb.query('interval', params.interval, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<(OcppAuthorizationV1 | OcppAuthorizationV2)>>;
    })
  );
}

getAuthorizationsOfChargingStation.PATH = '/chargingStations/{stationId}/ocppAuthorizations/';
