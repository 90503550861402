import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, Signal, computed, signal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, tap } from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import { Tenant } from 'src/app/core/data-backend/models';
import { SelectMultipleOption } from 'src/app/shared/quick-filters/select-multiple/select-multiple.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { Notification } from 'src/app/core/data-backend/models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationForm } from 'src/app/core/stores/notifications.repository';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-notifications-mask',
    standalone: true,
    imports: [
        CoreModule,
        SharedModule,
        AsyncPipe,
        ReactiveFormsModule
    ],
    template: `
    <form
        name="createFilterSet"
        [formGroup]="notificationForm"
        (ngSubmit)="onSubmit()"
    >
        <div class="headline">
            <span class="material-icon">chat</span> 
            <span>{{ 'COMMON.NOTIFICATION.ONE' | translate }}</span>
        </div>
        <div class="wrapper-notification">
            <div class="form-group">
                <input 
                    type="text" 
                    formControlName="title"
                    id="title"
                    class="title-input"
                    required
                >
                <label for="title">{{ 'NOTIFICATIONS_VIEW.FORM.TITLE' | translate }}</label>
            </div>
            <div class="form-group">
                <input
                    type="text" 
                    formControlName="description"
                    id="description"
                    class="description-input"
                    required
                >
                <label for="description">{{ 'NOTIFICATIONS_VIEW.FORM.DESCRIPTION' | translate }}</label>
            </div>
            <div class="radio-group">
                <!--<label>
                    <input
                        type="radio"
                        formControlName="category"
                        value="update"
                    >
                    <div class="material-icon">new_releases</div>{{ 'NOTIFICATIONS_VIEW.FORM.UPDATE' | translate }}
                </label>-->
                <label>
                    <input
                        type="radio"
                        formControlName="category"
                        value="information"
                    >
                    <div class="material-icon">campaign</div><span>{{ 'NOTIFICATIONS_VIEW.FORM.INFORMATION' | translate }}</span>
                </label>
                <label>
                    <input
                        type="radio"
                        formControlName="category"
                        value="warning"
                    >
                    <div class="material-icon">warning</div><span>{{ 'NOTIFICATIONS_VIEW.FORM.WARNING' | translate }}</span>
                </label>
                <label>
                    <input
                        type="radio"
                        formControlName="category"
                        value="error"
                    >
                    <div class="material-icon">error</div><span>{{ 'NOTIFICATIONS_VIEW.FORM.ERROR' | translate }}</span>
                </label>
            </div>
        </div>
        <div class="headline pt-16">
            <span class="material-icon">alarm</span> 
            <span>{{ 'NOTIFICATIONS_VIEW.FORM.SCHEDULE' | translate }}</span>
        </div>
        <div class="wrapper-schedule flex-row justify-content-between">
            <div class="date-picker-group pb-16">
                <label for="from">{{ 'COMMON.TIMES.FROM' | translate }}:</label>
                <evc-date-input
                    id="from"
                    [title]="null"
                    [size]="'small'"
                    [reset]="now"
                    [value]="notificationForm.get('from')?.value ?? null"
                    [min]="now"
                    [max]="notificationForm.get('to')?.value ?? null"
                    [disabled]="notificationForm.get('now')?.value ?? false"
                    (valueChange)="notificationForm.patchValue({
                        from: $event
                    })"
                />
                <label>
                    <input
                        formControlName="now"
                        type="checkbox"
                        (change)="notificationForm.patchValue({
                            from: now,
                        })"
                    >
                    {{ 'COMMON.TIMES.NOW' | translate }}
                </label>
            </div>
            <div class="date-picker-group pb-16">
                <label for="to">{{ 'COMMON.TIMES.TO' | translate }}:</label>
                <evc-date-input
                    id="to"
                    [title]="null"
                    [size]="'small'"
                    [reset]="'empty'"
                    [value]="notificationForm.get('to')?.value ?? null"
                    [min]="notificationForm.get('from')?.value ?? now"
                    (valueChange)="notificationForm.patchValue({
                        to: $event,
                        unlimited: $event ? false : true
                    }); setUnlimitedState($event ? false : true)"
                />
                <label>
                    <input
                        formControlName="unlimited"
                        type="checkbox"
                        [class.disabled]="notificationForm.get('unlimited')?.disabled"
                        (change)="notificationForm.patchValue({
                            to: undefined,
                        }); setUnlimitedState($event ? true : false)"
                    >
                    {{ 'NOTIFICATIONS_VIEW.FORM.UNLIMITED' | translate }}
                </label>
            </div>
            <div class="date-picker-group">
                <label>
                    <input
                        type="checkbox"
                        formControlName="persistent"
                    >
                    {{ 'NOTIFICATIONS_VIEW.FORM.PERSISTENT' | translate }}
                </label>
            </div>
        </div>
        @if (tenants().length > 0) {
            <div class="headline pt-16">
                <span class="material-icon">person</span>
                <span>{{ 'FILTERS.FORM.TENANT' | translate }}</span>
            </div>
            <div class="wrapper-tenant">
                <div class="tenant-picker">
                    <app-select-multiple
                        [title]="'MANAGE_USERS_VIEW.INVITE_USERS.TENANTS.TITLE' | translate"
                        [emptyStateText]="'COMMON.NONE' | translate"
                        [options]="tenants()"
                        [disabledValues]="otherTenantIdentifiers()"
                        [pseudoDisable]="true"
                        [size]="'small'"
                        [selectedValues]="notificationForm.get('tenants')?.value ?? []"
                        (selectedValuesChange)="notificationForm.patchValue({tenants: $event.current})"
                    ></app-select-multiple>
                    <button 
                        class="reset"
                        type="button"
                        (click)="notificationForm.patchValue({tenants: []})"
                        [tooltip]="'NOTIFICATIONS_VIEW.FORM.RESET_TENANTS' | translate"
                    >
                        <span class="material-icon">rotate_right</span>
                    </button>
                </div>
            </div>
        }
        <div class="footer flex-row align-items-center justify-content-end pb-16">
            @if(mode == 'update') {
                <button type="button" class="mr-16" (click)="onCancel.emit()">{{ 'COMMON.CANCEL' | translate }}</button>
            }
            <button [disabled]="this.notificationForm.invalid || state != 'success'" type="submit">
                <div class="flex-row align-items-center justify-content-center">
                    <span class="material-icon">
                        {{ mode == 'create' ? 'notification_add' : 'save' }}
                    </span>
                    @if (mode == 'create') {
                        {{ 'NOTIFICATIONS_VIEW.CREATE_NOTIFICATION' | translate }}
                    } @else {
                        {{ 'NOTIFICATIONS_VIEW.EDIT_NOTIFICATION' | translate }}
                    }
                </div>
            </button>
        </div>
    </form>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrl: './notifications-mask.component.scss'
})
export class NotificationsMaskComponent {

    @Input('mode') mode: 'create' | 'update' = 'create';

    @Input('form') set form(form: NotificationForm | null) {
        if (!form) return;
        // make sure dates don't fall in the past
        if (new Date(form.from) < this.now) form.from = this.now;
        if (form.to && new Date(form.to) < this.now) form.to = this.now;
        // update form with provided values
        this.notificationForm.patchValue({
            category: form.category,
            title: form.title,
            description: form.description,
            from: new Date(form.from),
            to: form.to ? new Date(form.to) : undefined,
            now: form.now,
            unlimited: form.to ? false : true,
            persistent: form.persistent,
            active: form.active,
            tenants: form.tenants
        });
        // set unlimited checkbox state
        this.setUnlimitedState(!form.to);
    };

    // tenants available to the user
    private _userTenants = signal<SelectMultipleOption[]>([]);
    @Input({alias: 'userTenants'}) set setUserTenants(tenants: Tenant[] | null) {
        this._userTenants.set((tenants ?? []).map((tenant) => ({
            label: tenant.identifier,
            value: tenant.identifier
        } as SelectMultipleOption)))
    };

    // other tenants (will be pseudo-disabled)
    public otherTenantIdentifiers = signal<string[]>([]);
    @Input({alias: 'otherTenants'}) set setOtherTenants(tenants: Tenant[] | null) {
        this.otherTenantIdentifiers.set((tenants ?? []).map((tenant) => tenant.identifier));
    };

    // combines user tenants with other tenants
    public tenants: Signal<SelectMultipleOption[]> = computed(() => {
        const userTenants = this._userTenants();
        const otherTenants = this.otherTenantIdentifiers()
            .sort((a, b) => a.localeCompare(b))
            .map((id) => ({
                label: id,
                value: id
            } as SelectMultipleOption));

        return [...userTenants, ...otherTenants]
    })

    @Input('state') state: 'loading' | 'error' | 'success' | null = null;

    @Output('onCancel') onCancel = new EventEmitter<void>();

    @Output('submitNotification') submitNotification = new EventEmitter<Notification>();

    @Output('formValueChanged') formValueChanged = new EventEmitter<NotificationForm>();

    public formValid = new BehaviorSubject<boolean>(false);

    public now = new Date();

    public notificationForm = new FormGroup({
        title: new FormControl<string>(''),
        description: new FormControl<string>(''),
        category: new FormControl<'information' | 'warning' | 'error'>('information'),
        from: new FormControl<Date>(new Date()),
        to: new FormControl<Date | undefined>(undefined),
        now: new FormControl<boolean>(false),
        unlimited: new FormControl<boolean>(true),
        persistent: new FormControl<boolean>(false),
        active: new FormControl<boolean>(true),
        tenants: new FormControl<(string | number)[]>([]),
    }, {validators: [
        (control: AbstractControl): ValidationErrors | null => {
            const title = control.get('title')?.value;
            return !title || title.length == 0 ? {
                title: this._t('NOTIFICATIONS_VIEW.INFO.TITLE_REQUIRED')
            } : null
        },
        (control: AbstractControl): ValidationErrors | null => {
            const description = control.get('description')?.value;
            return !description || description.length == 0 ? {
                description: this._t('NOTIFICATIONS_VIEW.INFO.DESCRIPTION_REQUIRED')
            } : null
        },
        (control: AbstractControl): ValidationErrors | null => {
            let from = control.get('from')?.value;
            let to = control.get('to')?.value;
        
            if (!from) return { from: this._t('NOTIFICATIONS_VIEW.INFO.FROM_REQUIRED') };
        
            if (to && from > to) return { from: this._t('NOTIFICATIONS_VIEW.INFO.FROM_BEFORE_TO')};
        
            if (from < this.now) return { from: this._t('NOTIFICATIONS_VIEW.INFO.FROM_CANT_BE_PAST')};
            if (to && to < this.now) return { to: this._t('NOTIFICATIONS_VIEW.INFO.TO_CANT_BE_PAST') };
        
            return null;
        },
        (control: AbstractControl): ValidationErrors | null => {
            const tenants = control.get('tenants')?.value;
            return !tenants || tenants.length == 0 ? {
                tenants: this._t('NOTIFICATIONS_VIEW.INFO.TENANT_REQUIRED')
            } : null
        },
    ]})

    constructor(
        private _translate: TranslateService
    ) {
        // listen to changes in the form and notify parents
        this.notificationForm.valueChanges.pipe(
            takeUntilDestroyed(),
            tap((form) => {
                this.formValueChanged.emit(form as NotificationForm);
            })
        ).subscribe();
    }

    private _t(path: string, interpolateParams?: Object): string {
        return this._translate.instant(path, interpolateParams)
    }

    setUnlimitedState(state: boolean) {
        state ? 
            this.notificationForm.get('unlimited')?.disable():
            this.notificationForm.get('unlimited')?.enable();
    }
 
    onSubmit() {
        // get values from form
        let category = this.notificationForm.value.category;
        let title = this.notificationForm.value.title;
        let description = this.notificationForm.value.description;
        let from = this.notificationForm.value.from;
        let to = this.notificationForm.value.to;
        let persistent = this.notificationForm.value.persistent;
        let active = this.notificationForm.value.active;
        let tenants = this.notificationForm.value.tenants;

        // this shouldn't happen but make sure to abort if a required value is not set
        if (
            !category ||
            !title ||
            !description ||
            !from ||
            persistent == null ||
            persistent == undefined ||
            active == null ||
            active == undefined ||
            !tenants
        ) return;
        
        from = new Date(from);
        if (to) to = new Date(to);

        // create notification
        let notification: Notification = {
            notificationId: crypto.randomUUID(),
            category: category,
            title: title,
            description: description,
            from_date: from.toISOString(),
            to_date: to ? to.toISOString() : undefined,
            persistent: persistent,
            active: active,
            tenants: tenants.map(value => value.toString())
        }

        // submit notification to parent
        this.submitNotification.emit(notification);

        // if mode 'create' empty form
        if (this.mode == 'create') {
            this.notificationForm.reset({
                category: 'information',
                from: this.now,
                to: undefined,
                now: false,
                unlimited: true,
                persistent: false,
                active: true,
                tenants: []
            });
            this.setUnlimitedState(true);
        }
    }
}
