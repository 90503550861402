import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, effect, inject, input, model, signal, untracked } from '@angular/core';
import { DateRange } from 'src/app/core/plots/plot.models';
import * as echarts from 'echarts';
import { ChargingStation, Error, OcppAuthorizationV1, OcppAuthorizationV2, OcppIdTokenInfo, OcppMessageAggregation, OcppStatusNotificationV1, OcppStatusNotificationV2, RestartEvent } from 'src/app/core/data-backend/models';
import { TimelineHelperService } from 'src/app/core/plots/timeline-helper.service';
import { EnumeratedState, StateColors, StateHelperService } from 'src/app/core/helpers/state-helper.service';
import { detailsRepository } from 'src/app/core/stores/details.repository';
import { NotificationService } from 'src/app/core/app-services';
import { appRepository } from 'src/app/core/stores/app.repository';
import { EventIconsService } from 'src/app/core/helpers/event-icons.service';
import { BehaviorSubject, Subject, filter, fromEvent, map, merge, takeUntil, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { isOcppAuthV1, isOcppAuthV2, isOcppStatNotiV1 } from 'src/app/core/helpers/utils.helper';
import { setTooltipListeners } from 'src/app/core/helpers/charts.helper';

@Component({
    selector: 'station-plot',
    template: `
        <div 
            class="wrapper"
            [class.reduced-height]="!showConnectionLine"
            #chartWrapper
        >
            <div class="station-plot"></div>
        </div>
    `,
    styleUrls: ['./station-plot.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StationPlotComponent implements OnInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    @ViewChild('chartWrapper') chartWrapper: ElementRef | undefined;
    private _stateHelper: StateHelperService        = new StateHelperService();
    private _stateColors: StateColors               = this._stateHelper.getStateColors();
    private _timelineHelper: TimelineHelperService  = new TimelineHelperService(0, 0);
    private _eventIconsService: EventIconsService   = inject(EventIconsService);
    private _eventDimensions = ['category', 'eventType', 'status', 'timestamp', 'errorCode', 'icon', 'evaluation', 'color', 'info', 'extra_info_1', 'extra_info_2', 'extra_info_3', 'id']
    private _messageRateDimensions = ['timestamp', 'category', 'messageRate'];
    @Input() station: ChargingStation | null = null;
    private _dateRange: DateRange | undefined;
    @Input() set dateRange(value: DateRange | null) {
        this._dateRange = value ?? undefined;
        this._updatePlot()
    };
    // authorization events to plot on "Station" line
    private _ocppAuthorizations: Array<OcppAuthorizationV1 | OcppAuthorizationV2> = [];
    @Input() set ocppAuthorizations(data:  Array<OcppAuthorizationV1 | OcppAuthorizationV2> | null) {
        if (data === null) return;
        this._ocppAuthorizations = data;
        this._addOrUpdateData()
    }
    // get ocpp status notifications, filter for non-connector specific
    private _ocppStatusNotifications: Array<OcppStatusNotificationV1 | OcppStatusNotificationV2> = [];
    @Input() set ocppStatusNotifications(data: Array<OcppStatusNotificationV1 | OcppStatusNotificationV2> | null) {
        if (data === null || !this.station) return;
        const stationConnectors = this.station.connectors.map(x => x.connectorId);
        this._ocppStatusNotifications = data.filter((notification) => stationConnectors.indexOf(notification.connectorId) == -1);
        this._addOrUpdateData()
    }
    // message rate for "Connection" line (formerly "heartbeat")
    private _ocppMessageAggregations: OcppMessageAggregation[] = [];
    @Input() set ocppMessageAggregations(data: OcppMessageAggregation[] | null) {
        if (data === null) return;
        this._ocppMessageAggregations = data.sort((a: OcppMessageAggregation, b: OcppMessageAggregation) => {
            return new Date(a.hour).getTime() - new Date(b.hour).getTime();
        })
        this._addOrUpdateData()
    }
    // restart events of station
    private _restarts: RestartEvent[] = [];
    @Input() set restarts(data: RestartEvent[] | null) {
        if (data === null) return;
        this._restarts = data
        this._addOrUpdateData()
    }
    private _errors: Error[] = [];
    @Input() set errors(data: Error[] | null) {
        if (data === null || !this.station) return
        const stationConnectors = this.station.connectors.map(x => x.connectorId);
        this._errors = data.filter((error) => stationConnectors.indexOf(error.connectorId) == -1)
        this._addOrUpdateData()
    }
    public chart: echarts.EChartsType | undefined;
    @Input() set loading(data: boolean | null) {
        if (!this.chart) return
        if (data === true || data === null) {
            this.chart.showLoading('default', {
                text: '',
                showSpinner: false,
                zlevel: 3
            })
        } else {
            this.chart.hideLoading()
        }
    }
    // Only to be used to handle connectionLost event selection!
    // any other filtering should happen in parent
    private _selectedEvents: string[] = [];
    @Input() set selectedEvents(data: string[] | null) {
        if (data === null) return
        this._selectedEvents = data;
        this._addOrUpdateData()
    }
    // item to sync with table
    private _internalSyncItem = signal<Error | null>(null);
    public parentSyncItem = model<Error | null>(null, {
        alias: 'syncItem'
    });
    // optionally disable connection line
    @Input() showConnectionLine: boolean = true;
    // optionally pass initial zoom range (start and end in percent)
    public initialZoom = input<[number, number] | null>(null);
    // emit synced item to parent to pass to table
    @Output() syncItemChange = new EventEmitter<Error | null>();
    // emit instance of created chart
    @Output() echartsInstance = new EventEmitter<echarts.EChartsType>();
    // emit current instance to handle disconnect of chart in parent
    @Output() disconnect = new EventEmitter<echarts.EChartsType>();
    // data of last clicked event on tooltip of chart
    private _clickedCompoundEvent$ = new BehaviorSubject<any>(null);
    private _tooltipListenerSet = false;

    constructor(
        private _elRef: ElementRef,
        private _appRepo: appRepository,
        private _translate: TranslateService,
        public detailsRepo: detailsRepository,
        public notificationService: NotificationService
    ) {
        /** Manages closing and opening of tooltips for synced items of table */
        effect(() => {
            const parentSync = this.parentSyncItem();
            const internalSync = untracked(this._internalSyncItem);

            if (!parentSync) return;

            // if parent sync is not for the station level, close current tooltip
            if (parentSync.connectorId !== 0) {
                this._closeTooltip();
                return;
            }

            if (internalSync && this._isSameError(parentSync, internalSync)) return;
            this._internalSyncItem.set(null);
            this._openSyncedTooltip(parentSync);
        }, {
            allowSignalWrites: true
        })

        effect(() => {
            const newZoom = this.initialZoom();
            if (!newZoom || !this._timelineHelper) return;
            this._timelineHelper.initialZoom = newZoom;
        })
    }

    private _isSameError(a: Error, b: Error): boolean {
        return a.connectorId === b.connectorId && a.date === b.date && a.errorCode === b.errorCode && a.errorModelInterpretation === b.errorModelInterpretation
    }

    private _closeTooltip() {
        if (!this.chart) return
        this.chart.dispatchAction({type: 'hideTip'});
    }

    private _openSyncedTooltip(error: Error) {
        if (!this.chart || !this._timelineHelper) return
        this._closeTooltip();
        this._timelineHelper.openTooltip(
            error.date,
            error.errorModelInterpretation,
            error.errorCode,
            0
        );
    }

    ngOnInit() {
        this._initPlot()
    }

    private _initPlot() {
        if (this.chart || !this._elRef) return
        this.chart = echarts.init(
            this._elRef.nativeElement.querySelector('.station-plot'),
            undefined,
            {
                renderer: 'canvas',
                useDirtyRect: true,
                height: this.showConnectionLine ? 80 : 50
            }
        );

        // set tooltip options right when chart is created, as it's needed as a reference in the helper
        this.chart.setOption({
            tooltip: {
                trigger: 'item',
                enterable: true,
                hideDelay: 5,
                renderMode: 'html',
                appendToBody: true,
                // @ts-ignore
                className: `echarts-event-tooltip echarts-station echarts-id-${this.chart.id}`,
                triggerOn: 'mousemove|click'
            }
        })

        // get click events on custom series, handle keepTooltip, return data of event
        const chartEventData$ = fromEvent(this.chart!, 'click').pipe(
            filter((event: any) => event.componentType == 'series' && event.componentSubType == 'custom'),
            tap(() => this._timelineHelper.keepTooltip('echarts-station', this.chartWrapper)),
            map((event) => event.data)
        );

        // merge data of chart and data of clickedCompoundEvents from helper
        merge(
            chartEventData$,
            this._clickedCompoundEvent$
        ).pipe(
            takeUntil(this._destroying$),
            tap((eventData) => {
                const [dateIndex, errorCodeIndex, evaluationIndex] = [
                    this._eventDimensions.indexOf('timestamp'),
                    this._eventDimensions.indexOf('errorCode'),
                    this._eventDimensions.indexOf('evaluation')
                ];

                if (!eventData || dateIndex == -1 || errorCodeIndex == -1 || evaluationIndex == -1) return;
                const errorFromEvent = {
                    connectorId: 0,
                    date: eventData[dateIndex],
                    errorCode: eventData[errorCodeIndex],
                    errorModelInterpretation: eventData[evaluationIndex]
                } as Error;

                const error = this._errors.find((error) => this._isSameError(error, errorFromEvent)) ?? null;

                this._internalSyncItem.set(error);
                this.parentSyncItem.set(error);
            })
        ).subscribe();

        const initialZoom = this.initialZoom();
        const timelineOptions = {
            initialZoomStart: (initialZoom && initialZoom[0]) ?? 80,
            initialZoomEnd: (initialZoom && initialZoom[1]) ?? 100
        }

        this._timelineHelper.init(this.chart, timelineOptions);

        this._updatePlot()
        this.echartsInstance.emit(this.chart)
    }

    private get _renderers() {
        const renderEvents = this._timelineHelper.eventsRendererFactory({iconSize: 24, aggregationThreshold: 10});
        const eventTooltips = this._timelineHelper.eventsTooltipsFactory({
            showUseForDefectBtn: this._appRepo.getSelectedCustomer()?.identifier === 'demo'
        });
        const connectionTooltip = this._timelineHelper.singleStateTooltipsFactory({
            categoryDimensionIndex: 1,
            timestampDimensionIndex: 0,
            evaluationDimensionIndex: 2
        }, 'percentage');

        return {
            renderEvents,
            eventTooltips,
            connectionTooltip
        }
    }

    private _initRenderers() {
        if (!this.chart) return

        // only update renderers if series are set
        const series = this.chart.getOption()['series'];
        const hasSeries = (name: string): boolean => !!series && (series as any[]).find((entry) => entry.name == name) !== undefined
        if (!hasSeries('events') || !hasSeries('message-rate')) return;

        const {renderEvents, eventTooltips, connectionTooltip} = this._renderers;
        // @ts-ignore
        this.chart.setOption({
            series: [
                {
                    name: 'message-rate',
                    tooltip: connectionTooltip
                },
                {
                    name: 'events',
                    renderItem: renderEvents,
                    tooltip: eventTooltips,
                }
            ]
        })
    }

    private _updatePlot() {
        if (!this._dateRange) return
        if (!this.chart) {
            this._initPlot();
            return
        }

        this._addOrUpdateData()

        const {renderEvents, eventTooltips, connectionTooltip} = this._renderers;

        const stationTitle = 'COMMON.STATION.ONE';
        const connectionTitle = 'DETAILS_VIEW.TIMELINE.CONNECTION';
        const initialZoom = this.initialZoom();

        const yAxisCategories = this.showConnectionLine 
            // empty categories for better spacing
            ? ['', connectionTitle, '', stationTitle, ''] 
            : [stationTitle];

        // @ts-ignore
        this.chart.setOption({
            textStyle: {
                fontFamily: '"ChesnaGrotesk", Arial, Geneva, Helvetica, sans-serif'
            },
            grid: {
                top: 0,
                bottom: 0,
                left: 8,
                right: 0,
                containLabel: true
            },
            animationDelay: 0,
            hoverLayerThreshold: Infinity, // never render additional hoverlayer, as echarts has issues when using canvas renderer. Let's hope this won't affect performance
            xAxis: {
                min: +this._dateRange.from,
                max: +this._dateRange.until,
                type: 'time',
                axisLabel: {
                    show: true,
                    inside: true,
                    margin: -15,
                    color: this._stateColors.noData,
                    align: 'left',
                    verticalAlign: 'bottom',
                    lineHeight: 40,
                    showMinLabel: true,
                    interval: 'auto',
                    hideOverlap: true,
                    padding: [0, 0, 0, 6],
                    formatter: {
                        year: '{yyyy}',
                        month: '{MM}.{yy}',
                        day: '{dd}.{MM}.{yy}',
                        hour: '{HH}:{mm}',
                        minute: '{HH}:{mm}',
                        second: '{HH}:{mm}:{ss}',
                        millisecond: '{dd}.{MM}.{yy}',
                        none: '{dd}.{MM}.{yy} {HH}:{mm}'
                    }
                },
                splitLine: {
                    show: true,
                    interval: 'auto',
                    lineStyle: {
                        color: this._stateColors.noData,
                        opacity: .5
                    }
                }
            },
            yAxis: {
                type: 'category',
                data: yAxisCategories,
                axisLabel: {
                    color: '#94A3B8',
                    formatter: (value: string, index: number) => {
                        return value.length > 0 ? this._translate.instant(value) : value
                    }
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                    filterMode: 'none',
                    start: (initialZoom && initialZoom[0]) ?? 80,
                    end: (initialZoom && initialZoom[1]) ?? 100,
                    throttle: 100,
                    xAxisIndex: [0, 1]
                }
            ],
            series: [
                // simple grey line as background of Station events
                {
                    name: 'Station',
                    type: 'line',
                    encode: {
                        x: 1,
                        y: 0
                    },
                    data: [
                        [stationTitle, this._dateRange.from.toISOString()],
                        [stationTitle, this._dateRange.until.toISOString()]
                    ],
                    silent: true,
                    showSymbol: false,
                    zlevel: 1,
                    lineStyle: {
                        color: '#C4C4C4',
                        width: 6,
                        cap: 'round'
                    }
                },
                {
                    name: 'connection',
                    type: 'line',
                    show: this.showConnectionLine,
                    encode: {
                        x: 1,
                        y: 0
                    },
                    data: [
                        [connectionTitle, this._dateRange.from.toISOString()],
                        [connectionTitle, this._dateRange.until.toISOString()]
                    ],
                    silent: true,
                    showSymbol: false,
                    zlevel: 1,
                    lineStyle: {
                        color: '#C4C4C4',
                        width: 5,
                        cap: 'round'
                    }
                },
                {
                    name: 'message-rate',
                    type: 'line',
                    show: this.showConnectionLine,
                    showSymbol: true,
                    symbol: 'rect',
                    symbolSize: 6,
                    datasetIndex: 1,
                    encode: {
                        x: 0,
                        y: 1
                    },
                    zlevel: 1,
                    lineStyle: {
                        width: 4,
                        cap: 'round'
                    },
                    tooltip: connectionTooltip
                },
                {
                    name: 'events',
                    type: 'custom',
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    datasetIndex: 0,
                    z: 4,
                    encode: {
                        x: 'timestamp',
                        y: 'category'
                    },
                    zlevel: 2,
                    renderItem: renderEvents,
                    tooltip: eventTooltips,
                    clip: true,
                    progressive: true,
                    progressiveChunkMode: 'mod',
                    progressiveThreshold: 15000
                }
            ],
            visualMap: [
                {
                    id: 'message-rate-visual-map',
                    type: 'piecewise',
                    show: false,
                    seriesIndex: 2,
                    dimension: 0,
                    itemHeight: 3,
                    z: 2,
                    pieces: []
                }
            ]
        });

        this._initRenderers();
        // set listeners once all options are set
        // make sure to only set listeners once
        if (!this._tooltipListenerSet) {
            setTooltipListeners(
                this.chart,
                'eventData',
                (eventData) => {
                    this._clickedCompoundEvent$.next(eventData)
                },
                (connectorIds, date, title) => {
                    this.detailsRepo.setCreateTicketFromTimeline(connectorIds, date, title);
                    this.notificationService.showLocalizedInfo('DETAILS_VIEW.INFORMATION_ADDED_TO_TICKET');
                }
            )
            this._tooltipListenerSet = true;
        }
    }


    // Auth events differ from ocpp status notifications, so we need a different mapping
    private _authorizationMap: [
        // states
        Array<OcppAuthorizationV1['idTagInfo']['status']|Required<OcppIdTokenInfo>['status']>,
        // evaluation
        EnumeratedState[],
        // colors
        string[],
        // icons
        string[]
    ] = [
        ['Accepted', 'Blocked', 'Expired', 'Invalid', 'ConcurrentTx', 'NoCredit', 'NotAllowedTypeEVSE', 'NotAtThisLocation', 'NotAtThisTime', 'Unknown'],
        ['Ok', 'To Be Monitored', 'To Be Monitored', 'To Be Monitored', 'To Be Monitored'],
        [this._stateColors.ok, this._stateColors.toBeMonitored, this._stateColors.toBeMonitored, this._stateColors.toBeMonitored, this._stateColors.toBeMonitored],
        ['credit_score', 'credit_card_off', 'support_agent', 'password', 'credit_card']
    ];

    private _getAuthIcon(state: OcppAuthorizationV1['idTagInfo']['status']|Required<OcppIdTokenInfo>['status']): [
        // evaluation
        EnumeratedState,
        // color
        string,
        // icon
        string
    ] {
        const normalizedGroups = this._authorizationMap[0].map((group) => group.toLowerCase())
        const index = normalizedGroups.indexOf(state.toLowerCase());
        return [
            this._authorizationMap[1][index],
            this._authorizationMap[2][index],
            this._authorizationMap[3][index]
        ]
    }

    private _addOrUpdateData() {
        if (!this.chart) return
        let dataSets = [
            {
                id: 'events',
                // category = 'group' (= 'lane' in connector), eventType = 'source' (auth, statusNotification, restart or errors)
                // "info" and "extra_info_{n}" is used for different specific fields of the different event types
                dimensions: this._eventDimensions,
                source: [
                    this._eventDimensions,
                ] as any[]
            },
            {
                id: 'messageRate',
                dimensions: this._messageRateDimensions,
                source: [
                    this._messageRateDimensions
                ] as any[]
            }
        ];

        const stationTitle = 'COMMON.STATION.ONE';
        const connectionTitle = 'DETAILS_VIEW.TIMELINE.CONNECTION';

        const ocppAuthorizationsV1 = this._ocppAuthorizations.filter(x => isOcppAuthV1(x)) as OcppAuthorizationV1[];
        const ocppAuthorizationsV2 = this._ocppAuthorizations.filter(x => isOcppAuthV2(x) && x.idTokenInfo !== undefined && x.idTokenInfo!.status !== undefined) as Required<OcppAuthorizationV2>[];

        // set all events in first dataSet
        dataSets[0].source.push(
            ...ocppAuthorizationsV1.map((x) => {
                const [evaluation, color, icon] = this._getAuthIcon(x.idTagInfo.status)
                return [stationTitle, 'authorization', '', x.timestamp, '', icon, evaluation, color, x.idTagInfo.status, x.idTagInfo.expiryDate, x.idTag, x.idTagInfo.parentIdTag, 0]
            }),
            ...ocppAuthorizationsV2.map((x) => {
                const [evaluation, color, icon] = this._getAuthIcon(x.idTokenInfo.status!)
                return [stationTitle, 'authorization', '', x.timestamp, '', icon, evaluation, color, x.idTokenInfo.status!, x.idTokenInfo.cacheExpiryDateTime, x.idToken.idToken, undefined, 0]
            }),
            ...this._ocppStatusNotifications.map((x) => {
                // always map status notifications as "No Error" and with mail-icon
                const evaluation = 'No Data';
                const colorIndex = this._eventIconsService.eventCodeEvaluation[0].indexOf(evaluation);
                const color = this._eventIconsService.eventCodeEvaluation[1][colorIndex];
                const data = isOcppStatNotiV1(x)
                    ? { status: x.status, errorCode: x.errorCode, info: x.info || '' }
                    : { status: x.connectorStatus, errorCode: null, info: '' }
                return [stationTitle, 'status', data.status, x.timestamp, data.errorCode, 'mail', evaluation, color, data.info, '', '', '', 0]
            }),
            ...this._restarts.map((x) => {
                const [ color, evaluation ] = x.success.toLowerCase() == 'no' ? [this._stateColors.failure, 'Failure'] : [this._stateColors.ok, 'Ok'];
                const cat = x.category.toLowerCase();
                // icons based on restart type
                let icon = x.restartType.toLowerCase().includes('soft') ? 'restart_alt' : 'sync';
                // special cases for automated restarts caused by issues
                if (cat == 'automatic - no connectivity' || cat == 'automatic - error') icon = 'sync_problem';

                return [stationTitle, 'restart', '', x.time, '', icon, evaluation, color, x.restartType, x.success, x.category, x.noOfBootNotifications, 0]
            }),
            ...this._errors.map((x) => {
                const colorIndex = this._eventIconsService.eventCodeEvaluation[0].indexOf(x.errorModelInterpretation);
                const color = this._eventIconsService.eventCodeEvaluation[1][colorIndex];
                // some description contain a prepended "errorCode:" (e.g.) string, so we need to normalize all error descriptions
                const errorDescArray = x.errorDescription.split(':')
                const normalizedErrorDesc = errorDescArray[errorDescArray.length - 1].trim();
                // combines errorText and errorInfo
                const errorInfo = [x.errorText, x.errorInfo].join('<br>');
                return [stationTitle, 'errors', x.errorModelInterpretation, x.date, x.errorCode, this._eventIconsService.getEventIcon(normalizedErrorDesc), x.errorModelInterpretation, color, errorInfo, '', '', '', 0]
            })
        )

        // set messageRate in second dataSet
        dataSets[1].source.push(
            ...this._ocppMessageAggregations.map(x => [x.hour, connectionTitle, x.percentageReceived])
        );

        // map out values, null each position in non-matching group to find gaps in next steps
        if (this._selectedEvents.indexOf('timeline_connectionLost') > -1) {
            this._ocppMessageAggregations.forEach((aggr, aggrIndex) => {
                // test for drops in connection, only with drops from 100
                let nextValue = this._ocppMessageAggregations[aggrIndex + 1];
                if (aggr.percentageReceived === 100 && nextValue && nextValue.percentageReceived < 100) {
                    dataSets[0].source.push(
                        [connectionTitle, 'connectionLost', '', nextValue.hour, '', 'mobiledata_off', 'To Be Monitored', this._stateColors.toBeMonitored, 'DETAILS_VIEW.TIMELINE.MSG_RATE_DROPPED', aggr.percentageReceived, nextValue.percentageReceived, '', 0]
                    )
                }
            })
        }

        // sort all events for proper aggregation in renderer
        dataSets[0].source.sort((a: any[], b: any[]) => {
            return new Date(a[3]).getTime() - new Date(b[3]).getTime();
        })

        // set index as id
        dataSets[0].source.map((entry, index) => {
            // skip first entry, as this defines dataset dimensions
            if (index > 0) {
                entry[entry.length - 1] = index
                return entry
            }
        })

        // echarts visual map on line series only supports plotted dimensions on x and y
        // so we'll create pieces mapped to aggregate message rates on certain points along the x axis
        const ranges = [
            {
                id: 'ok',
                color: this._stateColors.ok,
                min: 99,
                max: 100
            },
            {
                id: 'to-be-monitored',
                color: this._stateColors.toBeMonitored,
                min: 2,
                max: 98
            },
            {
                id: 'failure',
                color: this._stateColors.failure,
                min: 0,
                max: 1
            }
        ];

        const visualMap = this._timelineHelper.visualMapper(this._ocppMessageAggregations, 'percentageReceived', 'hour', ranges);

        this.chart.setOption({
            dataset: dataSets,
            visualMap: [
                {
                    id: 'message-rate-visual-map',
                    pieces: visualMap
                }
            ]
        })

        this._timelineHelper.prepareEventsRendererData(dataSets)

        this._initRenderers()
    }

    @HostListener('window:resize')
    private _resizePlot() {
        if (!this.chart) return
        this.chart.resize()
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
        this.disconnect.emit(this.chart);
        this.chart?.dispose()
    }
}
